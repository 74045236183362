<Boundary>
  <CardsComponentWrapper style="
    {buildCssPadding(padding)}
    {buildCssMargin(margin)}
    width: {width};
    flex-grow: {flexGrow};
    flex-shrink: {flexShrink};
    {(hidden)? 'display: none;' : ''}
    {(zoom)? `zoom: ${zoom};` : ''}
  " {editorOptions}>
    {#if !hidden}
    <CardsArrays parentSequenceId={sequenceId} arrays={column.content} updater={(arrays) => column.content = arrays} />
    {/if}
  </CardsComponentWrapper>
</Boundary>

<style>
  *, *::before, *::after {
    box-sizing: border-box;
  }</style>

<script lang="ts">var _a, _b, _c;
import "yinzcam-cards";
import { buildCssMargin, buildCssPadding } from "../utilities/index";
import CardsArrays from "./CardsArrays.svelte";
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
import _ from "lodash";
import CardsComponentWrapper from './CardsComponentWrapper.svelte';
import "./CardsEditorInterfaces";
import { CARDS_COLUMN_SCHEMA } from "./CardsEditorComponentSchemata";
import { v4 as uuid4 } from "uuid";
import { windowMetrics as wm } from '../../../js/stores';
export let parentSequenceId = null;
export let sequenceId = "";
export let column;
export let updater;
let hidden, zoom, hasWidth, width, flexGrow, flexShrink, padding, margin;
$: if (column && ($wm === null || $wm === void 0 ? void 0 : $wm.width)) {
    const clone = _.cloneDeep(column);
    // Apply responsive properties
    //console.log('CHECKING RESPONSIVE CONFIGURATION', currentLayout?.responsiveness);
    const responsiveConfiguration = (_c = (_b = (_a = clone.responsiveness) === null || _a === void 0 ? void 0 : _a.filter((i) => i.maxWidth >= 0)) === null || _b === void 0 ? void 0 : _b.sort((a, b) => a.maxWidth - b.maxWidth)) === null || _c === void 0 ? void 0 : _c.find((i) => i.maxWidth >= $wm.width);
    if (responsiveConfiguration) {
        //console.log('COLUMN FOUND RESPONSIVE CONFIGURATION', responsiveConfiguration);
        for (const k in responsiveConfiguration) {
            if (k === 'maxWidth') {
                continue;
            }
            const val = responsiveConfiguration[k];
            if (!_.isNil(val)) {
                clone[k] = val;
            }
        }
    }
    hidden = !_.isNil(clone.hidden) && clone.hidden;
    zoom = clone.zoom;
    hasWidth = _.isNumber(clone.width) && clone.width > 0 && clone.width <= 100;
    width = (hasWidth) ? `${clone.width}%` : '100%';
    flexGrow = (hasWidth) ? ((clone === null || clone === void 0 ? void 0 : clone.grow) ? '1' : '0') : '1';
    flexShrink = (hasWidth) ? ((clone === null || clone === void 0 ? void 0 : clone.shrink) ? '1' : '0') : '1';
    padding = clone.padding;
    margin = clone.margin;
}
else {
    hidden = true, zoom = undefined, hasWidth = false, width = '100%', flexGrow = '1', flexShrink = '1';
}
// child of a column is an array that needs a layout embedded in it
function createChild() {
    return {
        id: uuid4(),
        layouts: [{
                id: uuid4(),
                type: 'GRID',
                elements: []
            }]
    };
}
let editorOptions;
$: editorOptions = {
    parentSequenceId,
    sequenceId,
    componentId: column.id,
    component: column,
    componentTypeName: 'Column',
    childTypeNames: ['Array'],
    primaryColor: '#2390DE',
    reverseTextColor: 'black',
    unselectedBackgroundColor: '#8C959E',
    unselectedTextColor: '#FFFFFF',
    selectedBackgroundColor: '#FFDA53',
    selectedTextColor: '#202020',
    childrenKey: 'content',
    configSpec: CARDS_COLUMN_SCHEMA,
    allowChildren: true,
    allowDeletion: true,
    allowDuplication: true,
    allowMovement: true,
    allowCopyPaste: true,
    updater,
    createChild
};
</script>
