// no-export
import { AbstractReactiveMicrocomponent, ControlBase } from "yinzcam-rma";
import { YinzCamAPIRequestComponent, YinzCamAPIResponse } from "yinzcam-api";
import { YinzCamSegmentationRequestParameterComponent } from "./YinzCamSegmentationRequestParameterComponent";
import { YinzCamSegmentationServiceComponent } from "./YinzCamSegmentationServiceComponent";

interface SegmentationTokenResponseData {
  Token: string;
  IssueTime: number;
  RefreshInterval: number;
}

export class DefaultYinzCamSegmentationServiceComponent
  extends AbstractReactiveMicrocomponent<string, [YinzCamAPIResponse]>
  implements YinzCamSegmentationServiceComponent {

  public constructor(
    private readonly req: YinzCamAPIRequestComponent,
    private readonly rpct: YinzCamSegmentationRequestParameterComponent
  ) {
    super({ name: 'DefaultYinzCamSegmentationService' }, req);
  }

  protected async update($control: ControlBase, $segTokRsp: YinzCamAPIResponse): Promise<string> {
    if (!$segTokRsp) {
      return;
    }
    let data = $segTokRsp.data as SegmentationTokenResponseData;
    // temporarily removing this due to CORS issues
    this.rpct.setValue({ headers: { 'X-YinzCam-Segmentation-Token': data.Token }})
    return data.Token;
  }
}