<div class="cards-editor-edit-bar">
  {#if editorOptions}
    <CardsEditorComponentEditPopup options={editorOptions} />
  {:else}
    <div class='cards-editor-edit-bar-empty-content'>
      Select a component on the right to begin editing.
    </div>
  {/if}
</div>

<style>
  *, *::before, *::after {
    box-sizing: border-box;
  }

  .cards-editor-edit-bar {
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background: #eeeeee;
    color: white;
    /*padding: 5px 0px;*/
    position: sticky;
    position: -webkit-sticky;
    top: 0px;
    z-index: 999;
    height: 100%;
    
    --f7-menu-bg-color: black;
    --f7-menu-text-color: white;
    --f7-menu-font-size: 12px;
    --f7-menu-item-height: 32px;
  }

  .cards-editor-edit-bar-empty-content {
    width: 100%;
    text-align: center;
    padding: 20px 5px 0px 5px;
    font-style: italic;
    color: black;
  }</style>

<script lang="ts">import 'framework7-svelte';
import { CardsPageContextKey } from './context';
import { getContext } from 'svelte';
import CardsEditorComponentEditPopup from './CardsEditorComponentEditPopup.svelte';
const pageContext = getContext(CardsPageContextKey);
const editorOptionsStore = pageContext.editorModeManager.getSelectedComponentEditorOptionsStore();
$: editorOptions = $editorOptionsStore;
</script>