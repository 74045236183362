<script lang="ts">import { writable } from "svelte/store";
import { videoAspectRatioAction } from "components/cards/utilities";
import { BaseParticle } from "components/cards/particles";
import { onMount } from "svelte";
import StreamAMGPlayer from './StreamAMGPlayer.svelte';
import { WebEmbedParticle } from "../WebEmbed";
// props
export let container = undefined;
export let sourceType = undefined;
export let sourceKey = "";
export let sourceUrl = "";
export let name = "";
export let poster = undefined;
export let controls = undefined;
export let autoplay = undefined;
export let muted = undefined;
export let loop = undefined;
export let playsInline = undefined;
export let aspectRatio = undefined;
export let sizing = undefined;
export let fitHorizontalAlign = undefined;
export let fitVerticalAlign = undefined;
// inputs
// outputs
export let _sourceAspectRatio = null;
const _sourceAspectRatioStore = writable();
$: {
    _sourceAspectRatio = $_sourceAspectRatioStore;
}
let videoEl = null;
onMount(() => {
    return () => {
        if (videoEl) {
            videoEl.pause();
            videoEl.currentTime = 0;
        }
    };
});
</script>

<style>
  .outer {
    width: 100%;
  }

  .inner {
    width: 100%;
  }
  
  video {
    display: block;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    object-fit: var(--video-particle-object-fit, unset);
    object-position: var(--video-particle-fit-horizontal-align, unset) var(--video-particle-fit-vertical-align, unset);
    aspect-ratio: var(--video-particle-aspect-ratio, unset);
  }</style>

<BaseParticle _name="VideoParticle" _cssVariablePrefix="video-particle" {...container} {aspectRatio} objectFit={sizing} {fitHorizontalAlign} {fitVerticalAlign}>
  <div class="outer">
  {#if sourceType === 'Direct Link'}
    <div class="inner">
      <video
        bind:this={videoEl}
        src={sourceUrl}
        {poster}
        {controls}
        {autoplay}
        muted={muted || autoplay}
        {loop}
        playsinline={playsInline}
        use:videoAspectRatioAction={_sourceAspectRatioStore}
      >
        <track kind="captions">
      </video>
    </div>
  {:else if sourceType === 'Web Embed'}
    <div class="inner">
      <WebEmbedParticle {name} source={sourceUrl} />
    </div>
  {:else if sourceType === 'StreamAMG'}
    <div class="inner">
      <StreamAMGPlayer entryId={sourceKey} {autoplay} {muted} />
    </div>
  {:else}
    <div class="inner">
      (unknown video source)
    </div>
  {/if}
  </div>
</BaseParticle>
