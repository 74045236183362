import { ContainerProps, RecursiveBooleanified, RecursivePartial, YinzCamCardsComponentProps, YinzCamCardsComponentSchema, applyAnnotationsToSchema, generateContainerSchema } from "components/cards/common/schema";
import { CardsElementDataSourceSpec } from "components/cards/common/CardsElementInterfaces";
import { Narrow } from "json-schema-to-ts/lib/types/type-utils";

export class BaseAtomSpec<T extends YinzCamCardsComponentSchema, U = YinzCamCardsComponentProps<T>, V = RecursivePartial<U>, W = RecursiveBooleanified<V>> {
  
  public constructor(private readonly displayName: string, private readonly schema: Narrow<T>) {}

  public getDisplayName(): string {
    return '[A] ' + this.displayName;
  }

  public getSchema(): Narrow<T> {
    return this.schema;
  }

  /*
  public generateSchema<A extends string, B extends string>(title: Narrow<A>, description: Narrow<B>, options: { defaultValues?: V, uneditableFields?: W } = {}) {
    const { defaultValues, uneditableFields } = options;
    return generateNamedObjectSchema(title, description, applyAnnotationsToSchema(this.schema, defaultValues, uneditableFields));
  }
  */

  public getDataSourceSpec(): CardsElementDataSourceSpec {
    return {
      sources: [
        {
          type: "SOURCE",
          title: "Card Data",
          description: "Arbitrary source of data for this card matching the configuration fields.",
          classes: "*",
        },
      ],
    };
  }
}

export function generateAtomContainerSchema<V = RecursivePartial<ContainerProps>, W = RecursiveBooleanified<V>>(options: { defaultValues?: V, uneditableFields?: W } = {}) {
  const { defaultValues, uneditableFields } = options;
  return applyAnnotationsToSchema(generateContainerSchema("Container", "The container properties for this card."), defaultValues, uneditableFields);
}

export type AtomContainerSchema = ReturnType<typeof generateAtomContainerSchema>;

export type AtomContainerProps = YinzCamCardsComponentProps<AtomContainerSchema>;
