import { get, Readable, Writable, writable } from "svelte/store";
import { Container } from "inversify";
import { getNamed, getToken } from "inversify-token";
import { Logger } from "loglevel";
import { LoggerToken } from "yinzcam-log";
import { setDebugMode } from 'svelte-dnd-action';
import Map from "collections/map";
import Dict from "collections/dict";
import Set from "collections/set";
import SortedArraySet from "collections/sorted-array-set";
import SortedArrayMap from "collections/sorted-array-map";
import _ from "lodash";
import { CardsEditorComponent, CardsEditorComponentLayer, CardsEditorFileUploadCallbacks, CardsEditorComponentEditorOptions } from "./CardsEditorComponentInterfaces";
import { v4 as uuid4 } from "uuid";
import { blobToDataUri } from "../utilities";

export class CardsEditorModeManager {
  private readonly log: Logger;
  private readonly componentIdMap: Dict<string, CardsEditorComponent>;
  private readonly componentSequenceMap: SortedArrayMap<string, CardsEditorComponent>;

  private readonly enabledStore: Writable<boolean>;
  private readonly previewStore: Writable<boolean>;
  private readonly fullscreenStore: Writable<boolean>;
  private selectedComponentId: string;
  private readonly selectedComponentStore: Writable<CardsEditorComponent>;
  private readonly selectedComponentEditorOptionsStore: Writable<CardsEditorComponentEditorOptions>;
  //private readonly selectedComponentConfigSpecStore: Writable<CardsEditorComponentConfigSpec>;

  // TODO: find another way to get the container here directly from inversify?
  public constructor(private readonly container: Container,
    private readonly onPageDataUpdate: () => void,
    private readonly onFileUpload: (id: string, name: string, dataUri: string, cbs: CardsEditorFileUploadCallbacks) => void,
    private readonly onDisableSave: () => void,
    private readonly onEnableSave: () => void,
    private readonly onDisableFullscreen: () => void,
    private readonly onEnableFullscreen: () => void) {
    this.log = getNamed(container, LoggerToken, 'CardsEditorModeManager');
    this.componentIdMap = new Dict();
    this.componentSequenceMap = new SortedArrayMap();
    this.enabledStore = writable(false);
    this.previewStore = writable(true);
    this.fullscreenStore = writable(false);
    this.selectedComponentId = null;
    this.selectedComponentStore = writable(null);
    this.selectedComponentEditorOptionsStore = writable(null);
  }

  public getSelectedComponentEditorOptionsStore(): Readable<CardsEditorComponentEditorOptions> {
    return this.selectedComponentEditorOptionsStore;
  }

  public getSelectedComponentEditorOptions(): CardsEditorComponentEditorOptions {
    return get(this.selectedComponentEditorOptionsStore);
  }

  public setSelectedComponentEditorOptions(options: CardsEditorComponentEditorOptions) {
    this.selectedComponentEditorOptionsStore.set(options);
  }

  public disableSave() {
    this.onDisableSave();
  }

  public enableSave() {
    this.onEnableSave();
  }

  public disableFullscreen() {
    this.fullscreenStore.set(false);
    this.onDisableFullscreen();
  }

  public enableFullscreen() {
    this.fullscreenStore.set(true);
    this.onEnableFullscreen();
  }

  public getFullscreen(): boolean {
    return get(this.fullscreenStore);
  }

  public getFullscreenStore(): Readable<boolean> {
    return this.fullscreenStore;
  }

  public toggleFullscreen() {
    if (this.getFullscreen()) {
      this.disableFullscreen();
    } else {
      this.enableFullscreen();
    }
  }

  public getEnabledStore(): Readable<boolean> {
    return this.enabledStore;
  }

  public getEnabled(): boolean {
    return get(this.enabledStore);
  }

  public setEnabled(enabled: boolean) {
    this.enabledStore.set(enabled);
    if (!enabled) {
      this.setSelectedComponentId(null);
      this.setPreview(false);
    }
  }

  public toggleEnabled() {
    this.setEnabled(!this.getEnabled());
  }

  public getPreviewStore(): Readable<boolean> {
    return this.previewStore;
  }

  public getPreview(): boolean {
    return get(this.previewStore);
  }

  public setPreview(preview: boolean) {
    this.previewStore.set(preview);
  }

  public togglePreview() {
    this.setPreview(!this.getPreview());
  }

  public getSelectedComponentStore(): Readable<CardsEditorComponent> {
    return this.selectedComponentStore;
  }

  public getSelectedComponent(): CardsEditorComponent {
    return get(this.selectedComponentStore);
  }

  public setSelectedComponentId(id: string) {
    if (!id) {
      this.selectedComponentId = null;
      this.selectedComponentStore.set(null);
      return;
    }
    if (!this.componentIdMap.has(id)) {
      this.log.warn(`CardsEditorModeManager: attempt to set selected component ID that was not registered (${id})`);
      return;
    }
    this.selectedComponentId = id;
    let component: CardsEditorComponent = this.componentIdMap.get(id);
    this.selectedComponentStore.set(component);
  }

  public notifyPageDataUpdate() {
    if (_.isFunction(this.onPageDataUpdate)) {
      this.onPageDataUpdate();
    }
  }

  public async beginFileUpload(file: File, cbs: CardsEditorFileUploadCallbacks): Promise<string> {
    const id = uuid4();
    const dataUri = await blobToDataUri(file);
    this.onFileUpload(id, file.name, dataUri, cbs);
    return id;
  }

  public registerComponent(component: CardsEditorComponent) {
    // This function gets called very frequently when dragging and dropping components
    // The code here should be kept as efficient as possible, and only update things outside this class
    // (stores, etc.) when absolutely necessary.

    //console.log("REGISTER", component);
    let oldComponent: CardsEditorComponent = null;
    if (this.componentIdMap.has(component.id)) {
      oldComponent = this.componentIdMap.get(component.id);
      if (oldComponent.sequenceId !== component.sequenceId) {
        this.componentSequenceMap.delete(oldComponent.sequenceId);
      }
    }
    this.componentIdMap.set(component.id, component);
    this.componentSequenceMap.set(component.sequenceId, component);
    if (component.id === this.selectedComponentId && !_.isEqual(oldComponent, component)) {
      //console.log("UPDATE STORE");
      // refresh the store
      this.selectedComponentStore.set(component);
    }
  }

  public unregisterComponent(id: string) {
    //console.log("UNREGISTER", id);
    if (!this.componentIdMap.has(id)) {
      this.log.warn(`CardsEditorModeManager: attempt to unregister component that was not registered (${id})`);
      return;
    }
    let component: CardsEditorComponent = this.componentIdMap.get(id);
    this.componentSequenceMap.delete(component.sequenceId);
    this.componentIdMap.delete(id);
    if (component.id === this.selectedComponentId) {
      // clear the store
      this.setSelectedComponentId(null);
    }
  }

  public getRootComponentLayer(): CardsEditorComponentLayer {
    const stack: CardsEditorComponentLayer[] = [];
    this.componentSequenceMap.forEach((component: CardsEditorComponent, sequenceId: string) => {
      let newLayer = { id: component.id, sequenceId, children: [] };
      if (stack.length === 0) {
        stack.push(newLayer);
        return;
      }
      // find parent
      let currentLayer = stack[stack.length - 1];
      while (sequenceId.length <= currentLayer.sequenceId.length) {
        stack.pop();
        currentLayer = stack[stack.length - 1];
      }
      // add child
      currentLayer.children.push(newLayer);
      stack.push(newLayer);
    });
    return (stack.length > 0)? stack[0] : null;
  }
}

