export function stripContentfulMetadata(obj: any): unknown {
  if (obj && Array.isArray(obj)) {
    obj = obj.map((item) => stripContentfulMetadata(item));
  } else if (obj && typeof obj === 'object' && 'fields' in obj) {
    obj = Object.fromEntries(Object.entries(obj['fields']).map(([ key, val ]) => [ key, stripContentfulMetadata(val) ]));
    /*
    if ('file' in obj) {
      obj = obj.file.url;
    }
    */
  }
  return obj;
}
