<script lang="ts">import { BaseParticle } from "components/cards/particles";
import "../Text";
import { afterUpdate } from "svelte";
import { resolveUrl } from "js/url";
// props
export let container = undefined;
export let textDefaults = undefined;
export let html = "";
// inputs
export let _block = false;
let wrapperSpan;
$: styles = {
    color: textDefaults === null || textDefaults === void 0 ? void 0 : textDefaults.color,
    family: textDefaults === null || textDefaults === void 0 ? void 0 : textDefaults.family,
    size: textDefaults === null || textDefaults === void 0 ? void 0 : textDefaults.size,
    weight: textDefaults === null || textDefaults === void 0 ? void 0 : textDefaults.weight,
};
afterUpdate(() => {
    if (wrapperSpan) {
        const aTags = wrapperSpan.querySelectorAll('a');
        for (const aTag of aTags) {
            const href = aTag.getAttribute('href');
            const resolved = resolveUrl(href);
            if (resolved.error) {
                console.warn('Unable to resolve href', href);
                continue;
            }
            aTag.setAttribute('href', resolved.href);
            if (resolved.external) {
                aTag.classList.add('external');
                aTag.target = '_blank';
            }
            else {
                aTag.classList.remove('external');
                aTag.target = '_self';
            }
        }
    }
});
</script>

<style>
  span {
    color: var(--rich-text-particle-color, inherit);
    font-family: var(--rich-text-particle-family, inherit);
    font-size: var(--rich-text-particle-size, inherit);
    font-weight: var(--rich-text-particle-weight, inherit);
  }</style>

<BaseParticle _name="RichTextParticle" _inline={!_block} _cssVariablePrefix="rich-text-particle" {...container} {...styles} >
  <span bind:this={wrapperSpan}>{@html html}</span>
</BaseParticle>
