import { generateArraySchema, generateBooleanSchema, generateRootObjectSchema, generateStringSchema } from "components/cards/common/schema";
import { BaseParticleSpec, GetParticlePropsType, GetParticleSchemaType, generateParticleContainerSchema } from "components/cards/particles";

export const PageParticleSpec = new BaseParticleSpec("Page", generateRootObjectSchema({
  container: generateParticleContainerSchema(),
  slug: generateStringSchema("Page Slug", "The slug of the page to load."),
  pathParams: generateArraySchema("Path Parameters", "Path parameters for the card list.", 
    generateStringSchema("Path Parameter", "A path parameter.")
  ),
  verticalCenterContentArea: generateBooleanSchema("Vertical Center Content Area", "Centers the content area of the page vertically."),
}));

export const specification = PageParticleSpec;

export type PageParticleSchema = GetParticleSchemaType<typeof PageParticleSpec>;

export type PageParticleProps = GetParticlePropsType<typeof PageParticleSpec>;
