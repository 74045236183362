<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import { BaseAtom } from "components/cards/atoms";
import { TextParticle, } from "components/cards/particles/YinzCam";
import "svelte/store";
export let container;
export let overlayPadding = undefined;
export let txtHeaderTabs;
export let colorActiveTab;
export let colorTabBackground;
export let colorTabBorder;
export let colorTab;
export let colorContainerBackground;
let atomWidthRem;
let tabClicked = {};
/*
$: headerTextMultiplier =
    atomWidthRem <= 30
        ? "0.416"
        : atomWidthRem <= 45 && atomWidthRem > 31
          ? "0.666"
          : "1.00";
$: bodyTextMultiplier = atomWidthRem <= 30 ? "0.60" : "1.00";
$: btnTextMultiplier = atomWidthRem <= 30 ? "0.75" : "1.00";
*/
</script>

<BaseAtom
    _isotope="PlayerDetailHeaderAtom"
    {...container}
    _cssVariablePrefix="player-header"
    {overlayPadding}
    {colorActiveTab}
    {colorTabBackground}
    {colorTabBorder}
    {colorTab}
    {colorContainerBackground}
    bind:_clientWidthRem={atomWidthRem}
>
    <div
        class="main-container"
        class:layout-mobile-width={atomWidthRem <= 30}
        class:layout-tablet-width={atomWidthRem <= 45 && atomWidthRem > 30}
    >
        <div class="header-box">
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            {#each txtHeaderTabs || [] as header, i}
                <div
                    class="header-tab"
                    class:active={tabClicked[i]}
                    on:click={() => (tabClicked = { [i]: !tabClicked[i] })}
                >
                    <TextParticle {...header.tabName} />
                </div>
            {/each}
        </div>
    </div>
</BaseAtom>

<style>
    .main-container {
        padding: var(--player-header-overlay-padding);
        background-color: var(--player-header-color-container-background, var(--theme-mode-background-color, #FFF));
        width: 100%;
    }
    .header-box {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        gap: 0.0625rem;
        padding: 0.25rem;
        border-radius: 2.5rem;
        border: 1px solid var(--theme-mode-foreground-color, #000);
        background-color: var(--player-header-color-tab-background, var(--theme-mode-background-color, #FFF));
    }
    .header-tab {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        padding: 1rem 3rem;
        border-radius: 3rem;
        cursor: pointer;
    }
    .header-tab.active {
        background-color: var(--player-header-color-active-tab, var(--theme-mode-highlight-color , #41e1ae));
    }</style>
