<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import "lodash";
import { BaseAtom } from "components/cards/atoms";
import { TextParticle, ImageParticle, } from "components/cards/particles/YinzCam";
export let container;
export let overlayPadding = undefined;
export let txtListTitle;
export let imgChevron;
export let txtOptions;
export let colorBorder;
let showContent = false;
</script>

<BaseAtom
    _isotope="DropdownAtom"
    {...container}
    _cssVariablePrefix="drop-down"
    {overlayPadding}
    {colorBorder}
>
    <div class="dropdown-box">
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <div
            class="dropdown-button"
            on:click={() => (showContent = !showContent)}
        >
            <TextParticle {...txtListTitle} />
            <ImageParticle {...imgChevron} />
        </div>
        <div class="dropdown-content" class:active-content={showContent}>
            {#each txtOptions || [] as option}
                <TextParticle {...option} />
            {/each}
        </div>
    </div>
</BaseAtom>

<style>
    .dropdown-box {
        min-width: 5rem;
        padding: var(--drop-down-overlay-padding);
    }
    .dropdown-button {
        padding: 1rem;
        font-size: 1rem;
        border: none;
        cursor: pointer;
        display: flex;
        width: 100%;
        justify-content: space-between;
        gap: 1rem;
        align-items: center;
        color: var(--theme-mode-foreground-color, #000);
        border: 1px solid
            var(
                --drop-down-border-color,
                var(--theme-mode-foreground-color, #d9d9d9)
            );
            border-radius: 0.375rem;
    }
    .dropdown-content {
        display: none;
        position: absolute;
        min-width: 5rem;
        z-index: 1;
        padding: 1rem;
        background-color: var(--theme-mode-background-color, #fff);
        color: var(--theme-mode-foreground-color, #000);
    }
    .dropdown-content.active-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }</style>
