import { injectable } from "inversify";
import { Token, injectToken } from "inversify-token";
import { Readable, derived } from "svelte/store";
import type { Logger } from "loglevel";
import { LoggerToken } from "yinzcam-log";
import _ from "lodash";
import { ResolvedUrl, resolveUrl } from "js/url";
import { CardsDynamicConfigurationProviderToken } from "./CardsDynamicConfigurationProvider";
import type { CardsDynamicConfigurationProvider } from "./CardsDynamicConfigurationProvider";

export const CardsPageContentManagerToken = new Token<CardsPageContentManager>(Symbol.for("CardsPageContentManager"));

export interface MediaTypeConfiguration {
  linkUrl?: ResolvedUrl;
  shareLinkUrl?: ResolvedUrl;
  icon?: string;
  [k: string]: unknown;
}

export interface ThemeConfiguration {
  fontFamily?: string;
  primaryForegroundColor?: string;
  primaryAccentColor?: string;
  primaryHighlightColor?: string;
  primaryBackgroundColor?: string;
  invertedForegroundColor?: string;
  invertedAccentColor?: string;
  invertedHighlightColor?: string;
  invertedBackgroundColor?: string;
  overlayForegroundColor?: string;
  overlayAccentColor?: string;
  overlayHighlightColor?: string;
  [k: string]: unknown;
}

const MANUAL_OVERRIDE_LINKS = {
  'fa': {
    'lei': {
      'article': '/pages/en/media-article',
      'video': '/pages/en/media-video',
      'gallery': '/pages/en/media-gallery',
      'audio': '/pages/en/media-audio',
    }
  }
};

@injectable()
export class CardsPageContentManager {
  public constructor(
    @injectToken(LoggerToken) private readonly log: Logger,
    @injectToken(CardsDynamicConfigurationProviderToken) private readonly dcp: CardsDynamicConfigurationProvider
  ) {}

  public getMediaTypeConfigurationStore(typeCode: string, slug: string, overrideLink?: string): Readable<MediaTypeConfiguration> {
    // this.dcp.get().getObjectStore('media-type', typeCode)
    // [obj]
    typeCode = (typeCode || '').toLowerCase();
    const overridePrefix = MANUAL_OVERRIDE_LINKS?.[CONFIG.league]?.[CONFIG.tricode]?.[typeCode];
    overrideLink = overrideLink || ((overridePrefix)? `${overridePrefix}/${slug}` : undefined);
    return derived([], () => {
      switch (typeCode) {
        case 'article':
          return {
            linkUrl: resolveUrl(overrideLink || `/modals/en/media-article-popup/${slug}`),
            shareLinkUrl: resolveUrl(overrideLink || `/pages/en/media-article-popup/${slug}`),
            icon: 'yc_icon_news'
          };
        case 'video':
          return {
            linkUrl: resolveUrl(overrideLink || `/modals/en/media-video-popup/${slug}`),
            shareLinkUrl: resolveUrl(overrideLink || `/pages/en/media-video-popup/${slug}`),
            icon: 'yc_icon_video'
          };
        case 'gallery':
          return {
            linkUrl: resolveUrl(overrideLink || `/modals/en/media-gallery-popup/${slug}`),
            shareLinkUrl: resolveUrl(overrideLink || `/pages/en/media-gallery-popup/${slug}`),
            icon: 'yc_icon_gallery'
          };
        case 'audio':
          return {
            linkUrl: resolveUrl(overrideLink || `/modals/en/media-audio-popup/${slug}`),
            shareLinkUrl: resolveUrl(overrideLink || `/pages/en/media-audio-popup/${slug}`),
            icon: 'yc_icon_headphones'
          };
        }
      return {
        linkUrl: undefined,
        icon: undefined
      };
    });
  }

  public getThemeConfigurationStore(slug: string): Readable<ThemeConfiguration> {
    return derived([this.dcp.get().getObjectStore('theme', slug)], ([obj]) => {
      return obj as ThemeConfiguration;
    });
  }

  public getPageConfigurationStore(slug: string): Readable<any> {
    return derived([this.dcp.get().getObjectStore('page', slug)], ([obj]) => {
      return obj as any;
    });
  }
}
