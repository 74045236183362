import { CardsDataSourceBase } from '../../common/CardsDataSourceBase'
import { injectable } from 'inversify'
import { injectToken } from 'inversify-token'
import { YinzCamAPIResponse } from 'yinzcam-api'
import { YinzCamAppServerToken } from 'yinzcam-appserver'

@injectable()
export class YinzCamAppServerGamePlaysArray extends CardsDataSourceBase {
  public constructor(@injectToken(YinzCamAppServerToken) server) {
    super({ server })
  }

  protected processResponse(response: YinzCamAPIResponse): object | any[] {
    const data = response?.data as any
    let ret = this.getPlays(data)
    if (ret.length) {
      ret.unshift( { heading: "Timeline" } );
    }
    return ret
  }

  getType(s: string): string {
    switch (s) {
      case 'foul':
        return 'FOUL'
      case 'Y':
      case 'yellow':
        return 'YELLOW'
      case 'corner':
        return 'CORNER'
      case 'S':
      case 'sub':
        return 'SUB'
      case 'D':
      case 'secondyellow':
        return 'SECOND_YELLOW'
      case 'offside':
        return 'OFF_SIDE'
      case 'R':
      case 'red':
        return 'RED'
      case 'G':
      case 'goal':
        return 'GOAL'
      case 'startperiod':
        return 'START_PERIOD'
      case 'endperiod':
        return 'END_PERIOD'
      default:
        return 'NONE'
    }
  }

  getPlays(data: any): any[] {
    if (!data?.PlayByPlay?.Plays?.Play) {
      return []
    }

    const HomeTeam = data?.PlayByPlay?.HomeTeam
    const AwayTeam = data?.PlayByPlay?.AwayTeam
    return [].concat(data?.PlayByPlay?.Plays?.Play).filter(Play => Play?._attributes).map((Play: any) => {
      const IsHome = Play?._attributes?.IsHome ? JSON.parse(Play?._attributes?.IsHome) : null
      const IsOwnGoal = Play?._attributes?.IsOwnGoal ? Play?._attributes?.IsOwnGoal.toLowerCase() === "true" : null
      const PlayerTeam = IsHome ? HomeTeam : AwayTeam
      return {
        ...Play?._attributes,
        HomeTeam: HomeTeam?._attributes,
        AwayTeam: AwayTeam?._attributes,
        PlayerTeam: PlayerTeam?._attributes,
        ImageUrl: Play?.ImageUrl?._text,
        HomeLogoId: HomeTeam?._attributes?.LogoId,
        AwayLogoId: AwayTeam?._attributes?.LogoId,
        IsHome,
        IsOwnGoal,
        Type: this.getType(Play?._attributes?.Type),
        Player1: {
          Id: Play?.Player1?.Id?._text,
          Name: Play?.Player1?.Name?._text,
          ImageUrl: Play?.Player1?.ImageUrl?._text,
          TeamId: Play?.Player1?.TeamId?._text,
        },
        Player2: {
          Id: Play?.Player2?.Id?._text,
          Name: Play?.Player2?.Name?._text,
          ImageUrl: Play?.Player2?.ImageUrl?._text,
          TeamId: Play?.Player2?.TeamId?._text,
        }
      }
    })
  }
}
