import {dndzone, Item, Options } from "svelte-dnd-action";
import _ from 'lodash';
import { CardsEditorModeManager } from "./CardsEditorModeManager";
import { CardsPageContext } from "./context";
import { CardsEditorComponent, CardsEditorComponentChild, CardsEditorComponentConfigSpec } from "./CardsEditorComponentInterfaces";
import { v4 as uuid4 } from "uuid";
import CardsEditorComponentControls from "./CardsEditorComponentControls.svelte";

export interface CardsEditorContainerActionOptions<T extends Item> {
  pageContext: CardsPageContext;
  parentSequenceId: string;
  items: T[];
  updater: (newItems: T[]) => void;
}

export function cardsEditorContainerAction<T extends Item>(node: HTMLElement, initialOptions: CardsEditorContainerActionOptions<T>) {
  let currentOptions: Partial<CardsEditorContainerActionOptions<T>> = {};
  let dndAction = null;
  let editorModeEnabled = false;
  let thisContainerSelected = false;
  let editorModeUnsubscribe = null;
  let selectedComponentUnsubscribe = null;

  function onDndConsider(e: CustomEvent) {
    // console.log('ONCONSIDER', e.detail);
    currentOptions.updater(sortItems(e));
  }

  function onDndFinalize(e: CustomEvent) {
    // console.log('ONFINALIZE', e.detail);
    currentOptions.updater(sortItems(e));
  }

  function sortItems(e: CustomEvent) {
    /*
    1. Scan array and create arrays of clones for each source item, in order, and create array of source items in order
    2. For each source item, place the source item, then its clones, then the next source item, and so on
    3. Return sorted array
    */
    const items = e.detail.items;
    //console.log("DND BEFORE", JSON.stringify(items, null, 2));
    const sourceItems = [];
    const cloneItems = {};
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      const er = item.__expandRepeats;
      if (er?.cloned) {
        let cloneArray = cloneItems[er.sourceId];
        if (!cloneArray) {
          cloneItems[er.sourceId] = cloneArray = [];
        }
        cloneArray.push(item);
      } else {
        sourceItems.push(item);
      }
    }
    let i = 0;
    for (let j = 0; j < sourceItems.length; j++) {
      const sourceItem = sourceItems[j];
      items[i++] = sourceItem;
      const originalId = sourceItem.__expandRepeats?.originalId;
      const sourceItemId = (sourceItem.isDndShadowItem && originalId)? originalId : sourceItem.id;
      const cloneArray = cloneItems[sourceItemId] || [];
      cloneArray.sort((a, b) => a.__expandRepeats.order - b.__expandRepeats.order);
      for (let k = 0; k < cloneArray.length; k++) {
        items[i++] = cloneArray[k];
      }
    }
    //console.log("DND AFTER", JSON.stringify(items, null, 2));
    return items;
  }

  function buildDndOptions(): Options {
    return {
      flipDurationMs: 300,
      items: currentOptions.items,
      type: currentOptions.parentSequenceId,
      dragDisabled: !editorModeEnabled || !thisContainerSelected,
    };
  }

  function updateDndAction() {
    // console.log("UPDATEDNDACTION");
    let dndOptions = buildDndOptions();
    // console.log(dndAction);
    if (dndAction) {
      // console.log("EXISTING DND ACTION");
      dndAction.update(dndOptions);
    } else {
      node.addEventListener('consider', onDndConsider);
      node.addEventListener('finalize', onDndFinalize);
      dndAction = dndzone(node, dndOptions);
    }
  }

  function onEditorModeUpdate(enabled: boolean) {
    editorModeEnabled = enabled;
    //console.log("EDITOR MODE UPDATE", enabled);
    //node.classList[(enabled)? 'add' : 'remove']('cards-editor-container');
    updateDndAction();
  }

  function onSelectedComponentUpdate(newSelectedComponent: CardsEditorComponent) {
    thisContainerSelected = newSelectedComponent?.sequenceId === currentOptions?.parentSequenceId;
    //node.classList[(thisContainerSelected)? 'add' : 'remove']('this-selected');
    updateDndAction();
  }

  function update(newOptions: CardsEditorContainerActionOptions<T>) {
    try {
      // console.log('ONUPDATE');
      const lastOptions = currentOptions;
      currentOptions = newOptions;

      if (currentOptions.pageContext && lastOptions.pageContext !== currentOptions.pageContext) {
        let emm = currentOptions.pageContext.editorModeManager;
        if (editorModeUnsubscribe) {
          editorModeUnsubscribe();
        }
        editorModeUnsubscribe = emm.getEnabledStore().subscribe(onEditorModeUpdate);
        if (selectedComponentUnsubscribe) {
          selectedComponentUnsubscribe();
        }
        selectedComponentUnsubscribe = emm.getSelectedComponentStore().subscribe(onSelectedComponentUpdate);
      }

      updateDndAction();
    } catch (e) {
      console.error("cardsEditorContainerAction.update last-chance exception (swallowed)", e);
    }
  }

  function destroy() {
    try {
      // console.log('ONDESTROY');
      node.removeEventListener('finalize', onDndFinalize);
      node.removeEventListener('consider', onDndConsider);
      if (editorModeUnsubscribe) {
        editorModeUnsubscribe();
      }
      if (selectedComponentUnsubscribe) {
        selectedComponentUnsubscribe();
      }
      if (dndAction) {
        dndAction.destroy();
      }
    } catch (e) {
      console.error("cardsEditorContainerAction.destroy last-chance exception (swallowed)", e);
    }
  }

  try {
    update(initialOptions);
    return { update, destroy };
  } catch (e) {
    console.error("cardsEditorContainerAction constructor last-chance exception (rethrown)", e);
    throw e;
  }
}

export interface CardsEditorComponentActionOptions<T extends Item> {
  pageContext: CardsPageContext;
  parentSequenceId: string;
  sequenceId: string;
  component: T;
  componentTypeName: string;
  childTypeNames: string[];
  configSpec: CardsEditorComponentConfigSpec;
  children: Item[];
  updater: (component: T) => void;
  color: string;
}

export function cardsEditorComponentAction<T extends Item>(node: HTMLElement, initialOptions: CardsEditorComponentActionOptions<T>) {
  const controlNode = document.createElement("div");
  const controlComponent = new CardsEditorComponentControls({ target: controlNode, props: {} });

  let currentOptions: Partial<CardsEditorComponentActionOptions<T>> = {};
  let emm: CardsEditorModeManager;
  let editorModeEnabled = false;
  let previewModeEnabled = false;
  let parentComponentSelected = false;
  let thisComponentSelected = false;
  let editorModeUnsubscribe = null;
  let selectedComponentUnsubscribe = null;
  let previewModeUnsubscribe = null;

  function doUpdate() {
    currentOptions.updater(currentOptions.component);
  }

  function onEditorModeUpdate(enabled: boolean) {
    //console.log("EDITOR MODE UPDATE", enabled);
    editorModeEnabled = enabled;
    syncDOM();
  }

  function onPreviewModeUpdate(enabled: boolean) {
    //console.log("PREVIEW MODE UPDATE", enabled);
    previewModeEnabled = enabled;
    syncDOM();
  }

  function onSelectedComponentUpdate(newSelectedComponent?: CardsEditorComponent) {
    //console.log("SELECTED COMPONENT UPDATE", newSelectedComponent);
    parentComponentSelected = newSelectedComponent?.sequenceId === currentOptions.parentSequenceId;
    thisComponentSelected = newSelectedComponent?.sequenceId === currentOptions.sequenceId;
    syncDOM();
  }

  function addChild() {
    currentOptions?.children?.push({ id: uuid4() })
    doUpdate();
  }

  function removeChild(id: string) {
    if (currentOptions && currentOptions.children) {
      let idx = currentOptions.children.findIndex((i) => i.id === id);
      if (idx >= 0) {
        currentOptions.children.splice(idx, 1);
      }
      doUpdate();
    }
  }

  function getChildren(): CardsEditorComponentChild[] {
    return currentOptions?.children?.map((i) => {
      return { id: i.id };
    });
  }

  function getConfigValue(key: string): any {
    return currentOptions?.component?.[key];
  }

  function setConfigValue(key: string, value: any) {
    if (currentOptions?.component) {
      (currentOptions.component as Item)[key] = value;
      doUpdate();
    }
  }

  function syncDOM() {
    node.classList[(editorModeEnabled)? 'add' : 'remove']('cards-editor-component');
    //node[(editorModeEnabled)? 'prepend' : 'removeChild'](controlNode);
    node.classList[(previewModeEnabled)? 'add' : 'remove']('preview-mode');
    node.classList[(parentComponentSelected)? 'add' : 'remove']('parent-selected');
    node.classList[(thisComponentSelected)? 'add' : 'remove']('this-selected');
    node.style.setProperty('--cards-editor-component-color', currentOptions.color);
  }

  function update(newOptions: CardsEditorComponentActionOptions<T>) {
    // WARNING: do not call the updater function from within this method.
    // It will cause an infinite loop.

    // this function gets called a lot so it needs to be fast
    // don't put log statements in here
    try {
      const lastOptions = currentOptions;
      currentOptions = newOptions;

      emm = currentOptions.pageContext.editorModeManager;

      if (currentOptions.pageContext && lastOptions.pageContext !== currentOptions.pageContext) {
        if (editorModeUnsubscribe) {
          editorModeUnsubscribe();
        }
        editorModeUnsubscribe = emm.getEnabledStore().subscribe(onEditorModeUpdate);
        if (selectedComponentUnsubscribe) {
          selectedComponentUnsubscribe();
        }
        selectedComponentUnsubscribe = emm.getSelectedComponentStore().subscribe(onSelectedComponentUpdate);
        if (previewModeUnsubscribe) {
          previewModeUnsubscribe();
        }
        previewModeUnsubscribe = emm.getPreviewStore().subscribe(onPreviewModeUpdate);
      }

      emm.registerComponent({
        id: currentOptions.component.id,
        sequenceId: currentOptions.sequenceId,
        configSpec: currentOptions.configSpec,
        addChild,
        removeChild,
        getChildren,
        getConfigValue,
        setConfigValue
      });

      controlComponent.$set({ name: currentOptions.componentTypeName });

      syncDOM();
      //console.log('UPDATE', node.classList);
    } catch (e) {
      console.error("cardsEditorComponentAction.update last-chance exception (swallowed)", e);
    }
  }

  function destroy() {
    try {
      // console.log('ONDESTROY');
      if (editorModeUnsubscribe) {
        editorModeUnsubscribe();
      }
      if (selectedComponentUnsubscribe) {
        selectedComponentUnsubscribe();
      }
      if (previewModeUnsubscribe) {
        previewModeUnsubscribe();
      }
      let emm = currentOptions.pageContext.editorModeManager;
      emm.unregisterComponent(currentOptions.component.id);
      controlComponent.$destroy();
    } catch (e) {
      console.error("cardsEditorComponentAction.destroy last-chance exception (swallowed)", e);
    }
  }

  try {
    //node.classList.add('cards-editor-component');
    controlNode.classList.add('cards-editor-control-block');
    //controlNode.innerText = "test test test test";
    update(initialOptions);
    return { update, destroy };
  } catch (e) {
    console.error("cardsEditorComponentAction constructor last-chance exception (rethrown)", e);
    throw e;
  }
}