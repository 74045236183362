import { generateBooleanSchema, generateColorSchema, generateRootObjectSchema, generateStringSchema, generateUrlSchema } from "components/cards/common/schema";
import { BaseParticleSpec, GetParticlePropsType, GetParticleSchemaType, generateParticleContainerSchema } from "components/cards/particles";

export const VideoParticleSpec = new BaseParticleSpec("Video", generateRootObjectSchema({
  container: generateParticleContainerSchema(),
  sourceType: generateStringSchema("Video Source Type", "The type of video source.", {
    choices: [ 'Direct Link', 'Web Embed', 'StreamAMG' ],
    choiceTitles: [ "Direct Link", "Web Embed", "StreamAMG" ]
  }),
  sourceKey: generateStringSchema("Video Source Key", "The URL source of the video (for URL-based sources)."),
  sourceUrl: generateUrlSchema("Video Source URL", "The media key of the video (for key-based sources)."),
  poster: generateUrlSchema("Poster Image", "The poster image as a URL."),
  controls: generateBooleanSchema("Controls", "Whether the video should show on-screen controls.", {
    defaultValue: true
  }),
  autoplay: generateBooleanSchema("Autoplay", "Whether the video should autoplay once it loads.", {
    defaultValue: false
  }),
  muted: generateBooleanSchema("Muted", "Whether the video should start playback muted.", {
    defaultValue: false
  }),
  loop: generateBooleanSchema("Loop", "Whether the video should loop back to the start once it reaches the end of playback.", {
    defaultValue: false
  }),
  playsInline: generateBooleanSchema("Plays Inline", "Whether the video play inline.", {
    defaultValue: true
  }),
  sizing: generateStringSchema("Sizing", "The strategy used to fit the video within its container.", {
    choices: [ 'unset', 'cover', 'contain' ],
    choiceTitles: [ "Stretch", "Cover", "Contain" ]
  }),
  fitHorizontalAlign: generateStringSchema("Horizontal Fit Alignment", "The horizontal alignment for the video fit.", {
    choices: [ 'left', 'center', 'right' ],
    choiceTitles: [ "Left", "Center", "Right" ],
    defaultValue: 'center'
  }),
  fitVerticalAlign: generateStringSchema("Vertical Fit Alignment", "The vertical alignment for the video fit.", {
    choices: [ 'top', 'center', 'bottom' ],
    choiceTitles: [ "Top", "Center", "Bottom" ],
    defaultValue: 'center'
  }),
  aspectRatio: generateStringSchema("Fixed Aspect Ratio", "Set a fixed aspect ratio for the video."),
}));

export const specification = VideoParticleSpec;

export type VideoParticleSchema = GetParticleSchemaType<typeof VideoParticleSpec>;

export type VideoParticleProps = GetParticlePropsType<typeof VideoParticleSpec>;
