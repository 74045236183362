<svelte:head>
{#if _rootPage}
  <title>{$seoConfig.title}</title>
  {#if $seoConfig.description}
  <meta name="description" content={$seoConfig.description}>
  {/if}
  {#if $seoConfig.keywords}
  <meta name="keywords" content={$seoConfig.keywords} />
  {/if}

  <!-- Open Graph / Facebook -->
  <meta property="og:type" content="website">
  <meta property="og:url" content={$seoConfig.linkUrl || window.location.href}>
  <meta property="og:title" content={$seoConfig.title}>
  {#if $seoConfig.description}
  <meta property="og:description" content={$seoConfig.description}>
  {/if}
  {#if $seoConfig.imageUrl}
  <meta property="og:image" content={$seoConfig.imageUrl}>
  <meta property="og:image:width" content="300">
  <meta property="og:image:height" content="300">
  {/if}

  <!-- Twitter -->
  <meta property="twitter:card" content="summary_large_image">
  <meta property="twitter:url" content={$seoConfig.linkUrl || window.location.href}>
  <meta property="twitter:title" content={$seoConfig.title}>
  {#if $seoConfig.description}
  <meta property="twitter:description" content={$seoConfig.description}>
  {/if}
  {#if $seoConfig.imageUrl}
  <meta property="twitter:image" content={$seoConfig.imageUrl}>
  {/if}
{/if}
</svelte:head>

<F7Page name={titleText || ''} noToolbar={_noBottomNav}>
  <YCTopBar {titleText} {titleImageUrl} navBar={_navBar} backButton={_backButton} />
  <!-- Uses slots to let pages insert whatever content they want. See https://svelte.dev/tutorial/slots -->
  <slot></slot>
  <div bind:this={_domTestEl} style="display: none;"></div>
</F7Page>

<style></style>

<script lang="ts">// F7Page reference: https://framework7.io/svelte/page.html
import { f7, Page as F7Page } from 'framework7-svelte';
import YCTopBar from './YCTopBar.svelte';
import { windowMetrics as wm } from '../js/stores';
import _ from 'lodash';
import { onMount } from 'svelte';
import ROOT from '../inversify.config';
import { getToken } from 'inversify-token';
import { JanusSEOManagerToken } from 'js/seo';
// Declaring props: https://svelte.dev/tutorial/declaring-props
export let titleText = undefined;
export let titleImageUrl = undefined;
export let navBar = undefined;
export let backButton = undefined;
export let noBottomNav = undefined;
export let query = {};
const seoManager = getToken(ROOT, JanusSEOManagerToken);
const seoConfig = seoManager.getSEOConfigurationStore();
let _navBar;
let _noBottomNav;
let _backButton;
let _domTestEl;
let _rootPage = false;
$: {
    _navBar = true;
    if (!_.isNil(CONFIG.defaultNavBarVisible)) {
        _navBar = CONFIG.defaultNavBarVisible;
    }
    if (!_.isNil(navBar)) {
        _navBar = navBar;
    }
    else if (!_.isNil(query.navBar)) {
        _navBar = query.navBar === 'true';
    }
    else if ($wm.width >= 768) {
        _navBar = false;
    }
}
$: {
    _noBottomNav = false;
    if (!_.isNil(CONFIG.defaultBottomNavHidden)) {
        _noBottomNav = CONFIG.defaultBottomNavHidden;
    }
    if (!_.isNil(noBottomNav)) {
        _noBottomNav = noBottomNav;
    }
    else if (!_.isNil(query.noBottomNav)) {
        _noBottomNav = query.noBottomNav === 'true';
    }
    else if ($wm.width >= 768) {
        _noBottomNav = true;
    }
}
$: {
    _backButton = true;
    if (!_.isNil(backButton)) {
        _backButton = backButton;
    }
    else if (!_.isNil(query.backButton)) {
        _backButton = query.backButton === 'true';
    }
}
// suppress head unless we are the root
$: if (_domTestEl) {
    // TODO: Watch domTestEl for changes
    //const previousPage = !!_domTestEl.closest('.page.page-previous');
    const embeddedPage = !!_domTestEl.closest('.page').parentElement.closest('.page');
    const modalPage = !_domTestEl.closest('.view.view-main');
    _rootPage = !modalPage && !embeddedPage; // && !previousPage;
}
// this allows the previous page to exist in the DOM while the new page loads,
// providing a more seamless user experience
$: if (_rootPage) {
    // TODO: Update this to watch for DOM quiescence and then clear history
    setTimeout(() => {
        f7.view.main.router.clearPreviousHistory();
    }, 5000); // TODO: replace with MutationObserver
}
onMount(async () => {
    setTimeout(() => {
        window.prerenderReady = true;
    }, 10000);
});
</script>
