{#key fullscreenEnabled}
<MenuItem iconF7={(fullscreenEnabled)? "arrow_down_right_arrow_up_left" : "arrow_up_left_arrow_down_right"} iconOnly {iconSize} on:click={handleClick} />
{/key}

<style>
  *, *::before, *::after {
    box-sizing: border-box;
  }</style>

<script lang="ts">import { MenuItem } from 'framework7-svelte';
import { CardsPageContextKey } from './context';
import { getContext } from 'svelte';
export let iconSize = 20;
const pageContext = getContext(CardsPageContextKey);
const fullscreenStore = pageContext.editorModeManager.getFullscreenStore();
$: fullscreenEnabled = $fullscreenStore;
function handleClick() {
    pageContext.editorModeManager.toggleFullscreen();
}
</script>