import { generateBooleanSchema, generateColorSchema, generateRootObjectSchema, generateStringSchema, generateUrlSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { TextParticleSpec, ImageParticleSpec, ButtonParticleSpec } from "components/cards/particles/YinzCam";

export const specification = new BaseAtomSpec("Image Background CTA", generateRootObjectSchema({
    container: generateAtomContainerSchema({
        defaultValues: { themeMode: 'overlay' },
        uneditableFields: { background: false }
    }),
    ctaType: generateStringSchema("CTA Type", "Select type of CTA", {
        choices: ['icon', 'button'],
        choiceTitles: ["Right Arrow Icon", "CTA Button"]
    }),
    overlayPadding: generateStringSchema("Overlay Padding", "The padding (inset) of the overlay text/buttons relative to the background image.", {
        defaultValue: '2rem'
    }),
    link: generateUrlSchema("Link", "The link for this media item. The default link will be generated from the slug if this is not provided (and the slug is)."),
    isBodyTextRequired: generateBooleanSchema("Body Text", "Is body text required"),
    headerObject: generateStringSchema("Header Icon/Bar", "Header icon or bar selection.", {
        choices: ['', 'icon', 'bar'],
        choiceTitles: ['None', 'Icon', 'Bar'],
    }),
    headerIcon: ImageParticleSpec.generateSchema("Header Icon", "Select an icon for header", {
        defaultValues: {
            container: { fillWidth: true, height: '4.25rem', width: '4.25rem', themeMode: 'overlay' }
        }
    }),
    headerBarBackgroundColor: generateColorSchema("Header Bar Background Color", "Background color of header bar."),
    headerText: TextParticleSpec.generateSchema("Heading Text", "Please enter a text for Heading", {
        defaultValues: { size: '2.25rem', style: 'normal', weight: '900' }
    }),
    bodyText: TextParticleSpec.generateSchema("Body Text", "Please enter a text for Body here", {
        defaultValues: { size: '1rem', style: 'normal', weight: '400', }
    }),
    thumbnail: ImageParticleSpec.generateSchema("Thumbnail", "The image thumbnail for the background of the card.", {
        defaultValues: { sizing: 'cover', hoverZoomEnabled: true, role: 'thumbnail' },
    }),
    ctaButton: ButtonParticleSpec.generateSchema("CTA Button", "The CTA button.", {
        defaultValues: {
            showImage: false, container: {
                themeMode: 'primary', background: { visible: true, useHighlightColor: true }, border: { visible: true, radius: '8px' },
                padding: '0.75rem 1.25rem'
            }, text: { size: '1rem', weight: '600' }
        }
    }),
    ctaButton2: ButtonParticleSpec.generateSchema("CTA Button 2", "The second CTA button.", {
        defaultValues: {
            showImage: false, container: {
                themeMode: 'primary', background: { visible: true, useHighlightColor: true }, border: { visible: true, radius: '8px' },
                padding: '0.75rem 1.25rem'
            }, text: { size: '1rem', weight: '600' }
        }
    }),
    ctaIcon: ImageParticleSpec.generateSchema("CTA Icon", "The CTA icon.", {
        defaultValues: { container: { fillWidth: true, height: '2.25rem', width: '2.25rem', themeMode: 'overlay' } }
    }),
    imageGradient: generateBooleanSchema("Background Gradient", "Apply gradient to the background image", {
        defaultValue: true
    }),
}));
