<F7App params={ f7params } >
  <!-- Tailwind import for styling -->
  <!--<Tailwind/>-->

  <!-- NOTE: YCTopBar is added within YCPage -->

  <!-- Right panel -->
  <!--<YCRightPanel/>-->
  <!--<LaLigaPanel/>-->

  <!-- App views, note that static bottom bar has to be below the main view. -->
  <Views>
    <!-- For Framework7 v6 -->
    <!-- <View main class="safe-areas" url="/" browserHistory={!Device.cordova} browserHistoryInitialMatch={!Device.cordova} /> -->
    <View main class="safe-areas" url="/" animate={false} swipeBack={false} pushState={!Device.cordova} pushStateSeparator="" pushStateRoot={new URL(window.location.href).origin} />
    <!-- <YCBottomBar/> -->
  </Views>

  <!-- <SSOPopups /> -->
  <div class="popup-backdrop cards-modal-popup-backdrop" on:click={handleModalBackdropClick} />
  <!--<div class="popup-backdrop cards-editor-popup-backdrop" />-->
</F7App>

<style></style>

<script lang="ts">var _a;
import { onMount } from 'svelte';
import { Device } from 'framework7/framework7-lite.esm.bundle.js';
import { f7, f7ready, App as F7App, Views, View } from 'framework7-svelte';
import cordovaApp from '../js/cordova-app';
import routes from '../js/routes';
import { getToken } from 'inversify-token';
import ROOT from '../inversify.config';
import { JanusPushManagerToken } from '../js/push';
import { JanusInternationalizationManagerToken } from '../js/i18n';
import { resolveUrl } from '../js/url';
import { currentRoute } from '../js/routes';
import { windowMetrics as wm } from '../js/stores.js';
const pushManager = getToken(ROOT, JanusPushManagerToken);
const i18nManager = getToken(ROOT, JanusInternationalizationManagerToken);
$: if ((_a = $wm === null || $wm === void 0 ? void 0 : $wm.breakpoint) === null || _a === void 0 ? void 0 : _a.remScale) {
    document.documentElement.style.fontSize = $wm.breakpoint.remScale;
}
// init internationalization early
i18nManager.init();
// Framework7 Parameters
let f7params = {
    id: 'com.yinzcam', // App bundle ID
    name: 'YinzCam', // App name
    theme: 'auto', // Automatic theme detection
    // App routes
    routes: routes,
    // Register service worker
    serviceWorker: Device.cordova ? {} : {
        path: '/service-worker.js',
    },
    // Input settings
    input: {
        scrollIntoViewOnFocus: Device.cordova && !Device.electron,
        scrollIntoViewCentered: Device.cordova && !Device.electron,
    },
    // Cordova Statusbar settings
    statusbar: {
        iosOverlaysWebView: true,
        androidOverlaysWebView: false,
    },
    panels3d: {
        enabled: true,
    },
    panel: {
        swipe: 'right',
    },
    navbar: {
        iosCenterTitle: true,
        mdCenterTitle: true,
        auroraCenterTitle: true,
    },
    stackPages: true
};
// Login screen demo data
let username = '';
let password = '';
function alertLoginData() {
    f7.dialog.alert('Username: ' + username + '<br>Password: ' + password, () => {
        f7.loginScreen.close();
    });
}
function handleModalBackdropClick() {
    // handles back when clicking outside of routable modals of cards lists.
    const mainViewHasContent = !!f7.view.main.el.querySelector('div.page.page-current');
    //console.log('VIEW MAIN', f7.view.main, mainViewHasContent);
    if (mainViewHasContent) {
        // effectively dismiss modal
        f7.view.main.router.back();
    }
    else {
        resolveUrl('home').func();
    }
}
onMount(() => {
    f7ready(async () => {
        // Init cordova APIs (see cordova-app.js)      
        if (Device.cordova) {
            cordovaApp.init(f7);
        }
        pushManager.handleAppLaunch();
        f7.view.main.router.on('routeChange', (newRoute) => {
            //console.log('ROUTE CHANGE', newRoute);
            currentRoute.set(newRoute);
        });
    });
});
</script>