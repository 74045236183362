import { inject, injectable, LazyServiceIdentifer } from 'inversify';
import { injectToken, Token } from 'inversify-token';
import { YinzCamAPI, YinzCamAPIRequest, YinzCamAPIRequestComponent, YinzCamAPIResponse, YinzCamAPIToken, YinzCamServer } from 'yinzcam-api';

export const YinzCamConfigServerToken = new Token<YinzCamConfigServer>(Symbol.for("YinzCamConfigServerToken"));

@injectable()
export class YinzCamConfigServer {
  private readonly server: YinzCamServer;

  public constructor(@injectToken(YinzCamAPIToken) private readonly api: YinzCamAPI) {
    this.server = api.getServer('config', 'V1');
  }

  public getRequest(request: YinzCamAPIRequest) {
    return this.server.getRequest(request);
  }

  public getConfigLegal(/*dspGuestId: string*/): YinzCamAPIRequestComponent {
    return this.server.getRequest({
      path: 'Config/Legal',
      headers: {
        //'X-YinzCam-DeviceId': dspGuestId
      },
      params: {
        os: 'web' // TEMPORARY
      }
    });
  }
}
