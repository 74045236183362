import { generateDateTimeSchema, generateRootObjectSchema, generateStringSchema, generateUrlSchema, generateColorSchema, generateBooleanSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { TextParticleSpec, ImageParticleSpec, ButtonParticleSpec } from "components/cards/particles/YinzCam";

export const specification = new BaseAtomSpec("Vertical Media Card", generateRootObjectSchema({
  container: generateAtomContainerSchema({
    defaultValues: { themeMode: 'primary' }
  }),
  mediaType: generateStringSchema("Type", "The type of this media item. This controls the media-type indicator icon as well as the default media item deep link.", {
    choices: [ 'article', 'video', 'gallery', 'podcast' ],
    choiceTitles: [ "News Article", "Video", "Photo Gallery", "Podcast" ]
  }),
  slug: generateStringSchema("Slug", "The slug for this media item. This will be used to generate the media item link if an explicit link is not provided."),
  link: generateUrlSchema("Link", "The link for this media item. The default link will be generated from the slug if this is not provided (and the slug is)."),
  thumbnail: ImageParticleSpec.generateSchema("Thumbnail", "The image thumbnail for the background of the card.", {
    defaultValues: { sizing: 'cover', hoverZoomEnabled: true, darkenFilterEnabled: true, fitVerticalAlign: 'top', role: 'thumbnail' },
  }),
  titleText: TextParticleSpec.generateSchema("Heading Body", "Enter Body Text", {
    defaultValues: {
      size: '1.75rem',
      weight: '900',
      lineClamp: 3,
      container: { fillWidth: false }
    }
  }),
  descriptionText: TextParticleSpec.generateSchema("Description Text", "The description text.", {
    defaultValues: { size: '1.25rem', weight: '400', lineClamp: 2 },
  }),
  imageClockIcon: ImageParticleSpec.generateSchema("Clock Icon", "Select an icon to display", {
    defaultValues: {
      container: {
        width: '2rem', centerContentHorizontally: false, padding: '0rem'
      }, icon: 'yc_icon_clock', fillWidth: false
    }
  }),
  publishDateTime: generateDateTimeSchema("Publish Date Time", "Enter Publish Date and Time"),
  textDateTime: TextParticleSpec.generateSchema("Date Time Text", "The text styling for the header text.", {
    defaultValues: { size: '1rem', weight: '600', transform: 'capitalize', wrap: 'nowrap' },
    uneditableFields: { text: true }
  }),
  imageShareIcon: ButtonParticleSpec.generateSchema("Share Icon", "Select an icon to display", {
    defaultValues: { container: { width: '1.5rem', height: '1.5rem' }, image: { icon: 'yc_icon_share'} }
  }),
  bodyPadding: generateStringSchema("Overlay Padding", "The padding (inset) of the overlay text/buttons relative to the background image.", {
    defaultValue: '0rem 0.5rem'
  }),
  mediaIconColor: generateColorSchema("Media Type Icon Color", "Pick a color for media type icon"),
  imgMediaType: ImageParticleSpec.generateSchema("Media Icon", "Specify media icon properties", {
    defaultValues: { container: { width: "4rem", height: "4rem", fillWidth: true } }
  }),
  separateHeaderDate: generateBooleanSchema("Want to push date?", "Select if we should push date below", {
    defaultValue: true
  })
}));
