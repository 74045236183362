import { Token } from "inversify-token";

export const ContentfulAPIConfigToken = new Token<ContentfulAPIConfig>(Symbol.for("ContentfulAPIConfig"));

export interface ContentfulAPIConfig {
  league: string;
  tricode: string;
  environment: string;
  contentful?: {
    //baseUrl?: string;
    accessToken: string;
    space: string;
    environment: string;  
  }
  defaultCacheTimeSeconds?: number;
  exponentialBackoffMultiplier?: number;
}
