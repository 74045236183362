//import { JSONSchema4 } from 'json-schema';
import { de } from 'date-fns/locale';
import { ExtendedJSONSchema, FromExtendedSchema, FromSchema } from 'json-schema-to-ts';
import { JSONSchemaType } from 'json-schema-to-ts/lib/types/definitions';
import { ParseSingleTypeSchema } from 'json-schema-to-ts/lib/types/parse-schema/singleType';
import { Narrow } from 'json-schema-to-ts/lib/types/type-utils';
import _ from 'lodash';

export type YinzCamCardsComponentSchemaExtension = {
  propertyOrder: string[],
  options?: {
    enum_titles?: string[],
    jodit?: {
      disabled?: boolean,
    },
  },
  uneditable: boolean,
};

export type YinzCamCardsComponentSchema = Exclude<ExtendedJSONSchema<YinzCamCardsComponentSchemaExtension>, Boolean>;

// In case this is needed in the future:
// type Writeable<T> = { -readonly [P in keyof T]: T[P] };
// type DeepWriteable<T> = { -readonly [P in keyof T]: DeepWriteable<T[P]> };

export type YinzCamCardsComponentProps<T extends YinzCamCardsComponentSchema> = RecursivePartial<FromExtendedSchema<YinzCamCardsComponentSchemaExtension, T>>;

// Below are useful for testing.
/*
const testSchema = {
  type: 'object',
  properties: {
    foo: generatePrimitiveSchema('string', "Foo", "This is a foo.")
  },
  additionalProperties: false
} as const satisfies YinzCamCardsComponentSchema

export type TestProps = YinzCamCardsComponentProps<typeof testSchema>;

const testSchema2 = generateRootObjectSchema({
  foo: generatePrimitiveSchema('string', "Foo", "This is a foo."),
  bar: generatePrimitiveSchema('boolean', "Bar", "This is a bar."),
  baz: generateObjectSchema("Baz", "This is a baz.", {
    foo: generatePrimitiveSchema('string', "Foo", "This is a foo."),
    bar: generatePrimitiveSchema('boolean', "Bar", "This is a bar."),
  })
}, {
  propertyOrder: [ 'bar', 'foo' ]
});

export type TestProps2 = YinzCamCardsComponentProps<typeof testSchema2>;
*/

export function generatePrimitiveSchema<A extends JSONSchemaType, B extends string, C extends string, D extends string, E extends FromSchema<{ type: Narrow<A> }>[], F extends string[], G extends FromSchema<{ type: Narrow<A> }>, H extends boolean, I extends boolean, J extends string>(
  type: Narrow<A>,
  title: Narrow<B>,
  description: Narrow<C>,
  options: { format?: Narrow<D>; choices?: Narrow<E>; choiceTitles?: Narrow<F>, defaultValue?: Narrow<G>, nullable?: Narrow<H>, allowUpload?: Narrow<I>, href?: Narrow<J>} = {}
) {
  const { format, choices, choiceTitles, defaultValue, nullable, allowUpload, href } = options;
  return {
    type,
    title,
    description,
    ...(!_.isUndefined(format) && { format }),
    ...(!_.isUndefined(choices) && { enum: choices }),
    ...(!_.isUndefined(defaultValue) && { default: defaultValue }),
    ...(!_.isUndefined(nullable) && { nullable }),
    options: {
      ...(!_.isUndefined(choiceTitles) && { enum_titles: choiceTitles }),
      ...(format === 'jodit' && { jodit: { disabled: false } }),
      ...(allowUpload && { upload: {} })
    },
    ...(!_.isUndefined(href) && { links: [ { href } ] }),
    additionalProperties: false,
  } as const;
}

export function generateBooleanSchema<A extends string, B extends string, C extends boolean, D extends boolean>(
  title: Narrow<A>,
  description: Narrow<B>,
  options: { defaultValue?: Narrow<C>, nullable?: Narrow<D> } = {}
) {
  return generatePrimitiveSchema("boolean", title, description, options);
}

export function generateNumberSchema<A extends string, B extends string, C extends number, D extends boolean>(
  title: Narrow<A>,
  description: Narrow<B>,
  options: { defaultValue?: Narrow<C>, nullable?: Narrow<D> } = {}
) {
  return generatePrimitiveSchema("number", title, description, options);
}

export function generateStringSchema<A extends string, B extends string, C extends string, D extends string[], E extends string[], F extends string, G extends boolean, H extends boolean, I extends string>(
  title: Narrow<A>,
  description: Narrow<B>,
  options: { format?: Narrow<C>; choices?: Narrow<D>; choiceTitles?: Narrow<E>, defaultValue?: Narrow<F>, nullable?: Narrow<G>, allowUpload?: Narrow<H>, href?: Narrow<I> } = {}
) {
  return generatePrimitiveSchema("string", title, description, options);
}

export function generateHtmlSchema<A extends string, B extends string, C extends string, D extends boolean>(
  title: Narrow<A>,
  description: Narrow<B>,
  options: { defaultValue?: Narrow<C>, nullable?: Narrow<D> } = {}
) {
  return generateStringSchema(title, description, { ...options, format: "jodit" });
}

export function generateUrlSchema<A extends string, B extends string, C extends string, D extends boolean>(
  title: Narrow<A>,
  description: Narrow<B>,
  options: { defaultValue?: Narrow<C>, nullable?: Narrow<D> } = {}
) {
  return generateStringSchema(title, description, { ...options, format: "url" });
}

export function generateMediaUrlSchema<A extends string, B extends string, C extends string, D extends boolean>(
  title: Narrow<A>,
  description: Narrow<B>,
  options: { defaultValue?: Narrow<C>, nullable?: Narrow<D> } = {}
) {
  return generateStringSchema(title, description, { ...options, format: "url", allowUpload: true, href: "{{self}}" })
}

export function generateColorSchema<A extends string, B extends string, C extends string, D extends boolean>(
  title: Narrow<A>,
  description: Narrow<B>,
  options: { defaultValue?: Narrow<C>, nullable?: Narrow<D> } = {}
) {
  return generateStringSchema(title, description, { ...options, format: "color" });
}

export function generateDateTimeSchema<A extends string, B extends string, C extends string, D extends boolean>(
  title: Narrow<A>,
  description: Narrow<B>,
  options: { defaultValue?: Narrow<C>, nullable?: Narrow<D> } = {}
) {
  return generateStringSchema(title, description, { ...options, format: "date-time" });
}

export function generateDurationSchema<A extends string, B extends string, C extends string, D extends boolean>(
  title: Narrow<A>,
  description: Narrow<B>,
  options: { defaultValue?: Narrow<C>, nullable?: Narrow<D> } = {}
) {
  return generateStringSchema(title, description, { ...options, format: "duration" });
}

export function generateObjectSchema<A extends string, B extends string, C extends { [k: string]: D }, D extends YinzCamCardsComponentSchema>(
  title: Narrow<A>,
  description: Narrow<B>,
  properties: Narrow<C>,
  options: { propertyOrder?: string[], additionalProperties?: boolean } = {}
) {
  return {
    ...generateRootObjectSchema(properties, options),
    title,
    description
  } as const;
}

export function generateNamedObjectSchema<A extends string, B extends string, C extends YinzCamCardsComponentSchema>(
  title: Narrow<A>,
  description: Narrow<B>,
  rootObject: Narrow<C>
) {
  return {
    ...rootObject,
    title,
    description
  } as const satisfies YinzCamCardsComponentSchema;
}

export function generateRootObjectSchema<A extends { [k: string]: B }, B extends YinzCamCardsComponentSchema> (
  properties: Narrow<A>,
  options: { propertyOrder?: string[], additionalProperties?: boolean } = {}
) {
  const { propertyOrder, additionalProperties } = options;
  return {
    type: 'object',
    properties,
    ...(!_.isUndefined(propertyOrder) && { propertyOrder }),
    additionalProperties: additionalProperties || false,
  } as const;
}

export function generateArraySchema<A extends string, B extends string, C extends YinzCamCardsComponentSchema>(
  title: Narrow<A>,
  description: Narrow<B>,
  itemSchema: Narrow<C>
) {
  return {
    title,
    description,
    type: 'array',
    items: itemSchema
  } as const satisfies YinzCamCardsComponentSchema;
}

export function generateBorderSchema<A extends string, B extends string>(
  title: Narrow<A>,
  description: Narrow<B>
) {
  return generateObjectSchema(title, description, {
    visible: generateBooleanSchema("Visible", "The visibility of the border.", {
      defaultValue: false
    }),
    width: generateStringSchema("Width", "The width of the border in standard CSS units."),
    style: generateStringSchema("Style", "The style of the border.", {
      choices: [ 'none', 'solid', 'dotted', 'dashed', 'double', 'groove', 'ridge', 'inset', 'outset', 'hidden' ],
      choiceTitles: [ 'None', 'Solid', 'Dotted', 'Dashed', 'Double', '3D Groove', '3D Ridge', '3D Inset', '3D Outset', 'Hidden' ],
      defaultValue: 'none',
    }),
    color: generateColorSchema("Color", "The color of the border."),
    radius: generateStringSchema("Radius", "The radius of the corners of the border in standard CSS units."),
  })
}

export function generateBackgroundSchema<A extends string, B extends string>(
  title: Narrow<A>,
  description: Narrow<B>
) {
  return generateObjectSchema(title, description, {
    visible: generateBooleanSchema("Visible", "The visibility of the background.", {
      defaultValue: false
    }),
    color: generateColorSchema("Color", "The solid color of the background. Defaults to the current theme mode's background color."),
    colorLightness: generateNumberSchema("Color Lightness Modifier", "The percentage value to modify the lightness of the background color. The percentage is calculated using the CIELAB color space."),
    useHighlightColor: generateBooleanSchema("Use Highlight Color", "Use the current theme mode's highlight color for the background, instead of the theme mode's background color."),
    image: generateMediaUrlSchema("Image", "The image to use as the background. Defaults to no image."),
    size: generateStringSchema("Size", "The sizing mode for the background image.", {
      choices: [ 'auto', 'cover', 'contain' ],
      choiceTitles: [ 'Auto', 'Cover', 'Contain' ],
      defaultValue: 'cover',
    }),
    attachment: generateStringSchema("Attachment", "Whether the background scrolls with content or is fixed (static).", {
      choices: [ 'scroll', 'fixed' ],
      choiceTitles: [ 'Scroll', 'Fixed' ],
      defaultValue: 'scroll',
    }),
    horizontalAlignment: generateStringSchema("Horizontal Alignment", "The horizontal alignment of the background image relative to its container.", {
      choices: [ 'left', 'center', 'right' ],
      choiceTitles: [ 'Left', 'Center', 'Right' ],
      defaultValue: 'center',
    }),
    verticalAlignment: generateStringSchema("Vertical Alignment", "The vertical alignment of the background image relative to its container.", {
      choices: [ 'top', 'center', 'bottom' ],
      choiceTitles: [ 'Top', 'Center', 'Bottom' ],
      defaultValue: 'center',
    }),
    repeat: generateStringSchema("Repeat", "Whether the background repeats if the container is larger than the background image.", {
      choices: [ 'no-repeat', 'repeat', 'repeat-x', 'repeat-y', 'space', 'round' ],
      choiceTitles: [ 'No Repeat', 'Repeat', 'Repeat Horizontally', 'Repeat Vertically', 'Fit Without Clipping', 'Stretch to Cover' ],
      defaultValue: 'no-repeat',
    }),
    clip: generateStringSchema("Clipping", "Whether the background is constrained to the border (entire container), padding (entire container except the border), just the content, or just the text.", {
      choices: [ 'border-box', 'padding-box', 'content-box', 'text' ],
      choiceTitles: [ 'Border', 'Padding', 'Content', 'Text' ],
      defaultValue: 'border-box',
    }),
    filter: generateStringSchema("Filter", "The filter(s) to apply to the background as a CSS filter string."),
  });
}

export function generateContainerSchema<A extends string, B extends string>(
  title: Narrow<A>,
  description: Narrow<B>
) {
  return generateObjectSchema(title, description, {
    background: generateBackgroundSchema("Background", "The container's background color, sizing, positioning, and image if specified in standard CSS units."),
    border: generateBorderSchema("Border", "The container's border width, style, color, and radius in standard CSS units."),
    centerContentHorizontally: generateBooleanSchema("Center Content Horizontally", "Whether this container should center its contents horizontally. This is the default behavior for block containers and does not apply to inline containers. Note that this only has an effect if child containers do not fill the width of their parent.", {
      defaultValue: true
    }),
    centerContentVertically: generateBooleanSchema("Center Content Vertically", "Whether this container should center its contents vertially. This is the default behavior for block containers and does not apply to inline containers. Note that this only has an effect if child containers do not fill the width of their parent.", {
      defaultValue: true
    }),
    fillWidth: generateBooleanSchema("Fill Width", "Whether this container should stretch to fill the width of its parent's container. This is the default behavior for block containers and does not apply to inline containers. If Fixed Width is specified, this property has no effect.", {
      defaultValue: true
    }),
    fillHeight: generateBooleanSchema("Fill Height", "Whether this container should stretch to fill the height of its parent's container. This is the default behavior for block containers and does not apply to inline containers. If Fixed Height is specified, this property has no effect.", {
      defaultValue: true
    }),
    width: generateStringSchema("Fixed Width", "The fixed width of this container in standard CSS units. If this is specified, the Fill Width property has no effect."),
    height: generateStringSchema("Fixed Height", "The fixed height of this container in standard CSS units. If this is specified, the Fill Height property has no effect."),
    hidden: generateBooleanSchema("Hidden", "Whether this container and its contents should be hidden.", {
      defaultValue: false
    }),
    margin: generateStringSchema("Margin", "The dimension(s) of the container margins (outside border) in standard CSS units."),
    padding: generateStringSchema("Padding", "The dimension(s) of the container padding (inside border) in standard CSS units."),
    themeMode: generateStringSchema("Theme Mode", "The theme mode (e.g. inverted) to use for this container. This mode is inherited by nested containers (the default) unless overriden on those containers.", {
      choices: Object.values(CONFIG.themeModes),
      choiceTitles: Object.values(CONFIG.themeModeTitles)
    }),
    filter: generateStringSchema("Filter", "The filter(s) to apply to the container contents as a CSS filter string."),
    overflow: generateStringSchema("Overflow", "Whether content that overflows the size of this container should be hidden or visible on the screen.", {
      choices: [ 'visible', 'hidden', 'scroll', 'auto' ],
      choiceTitles: [ 'Visible', 'Hidden', 'Scroll (Always Show Scrollbars)', 'Scroll (Automatic Scrollbars)' ],
    }),
    boxShadow: generateStringSchema("Box Shadow", "The box shadow to apply to the container using CSS syntax."),
  });
}

export type ContainerSchema = ReturnType<typeof generateContainerSchema>;

export type ContainerProps = YinzCamCardsComponentProps<ContainerSchema>;

export type RecursivePartial<T> = {
  [P in keyof T]?:
    T[P] extends (infer U)[] ? RecursivePartial<U>[] :
    T[P] extends object | undefined ? RecursivePartial<T[P]> :
    T[P];
};

export type RecursiveBooleanified<T> = T extends object ? {
  [K in keyof T]: RecursiveBooleanified<T[K]>
} : boolean;

export function applyAnnotationsToSchema<T extends YinzCamCardsComponentSchema, U = YinzCamCardsComponentProps<T>, V = RecursivePartial<U>, W = RecursiveBooleanified<V>>(schema: Narrow<T>, defaultValues?: V, uneditableFields?: W): Narrow<T> {
  if (schema.type === 'object') {
    const propsWithAnnotations = {};
    for (const k of Object.keys(schema.properties || {})) {
      const propSchema = schema.properties[k];
      propsWithAnnotations[k] = (_.isObject(propSchema))? applyAnnotationsToSchema(propSchema, defaultValues?.[k], uneditableFields?.[k]) : propSchema;
    }
    return {
      ...schema,
      properties: propsWithAnnotations as typeof schema.properties
    } as const;
  } else {
    return {
      ...schema,
      ...(!_.isUndefined(defaultValues) && !_.isObject(defaultValues) && { default: defaultValues }),
      ...(uneditableFields === true && { uneditable: true }),
    } as const;
  }
}

export function applyDefaultsToPropsRecursive<A extends YinzCamCardsComponentSchema, B = YinzCamCardsComponentProps<A>, C = RecursivePartial<B>>(schema?: boolean | A, props?: C): C {
  if (!_.isObject(schema)) {
    return props;
  }
  if (schema.type === 'object') {
    if (_.isUndefined(props)) {
      props = {} as C;
    }
    if (!_.isPlainObject(props)) {
      throw new Error('value is not a plain object');
    }
    for (const key in (schema.properties || {})) {
      try {
        props[key] = applyDefaultsToPropsRecursive(schema.properties?.[key], props[key]);
      } catch (e) {
        console.warn(`propsWithDefaultsHelper unable to map property ${key}`, e)
      }
    }
    return props;
  } else if (schema.type === 'array') {
    if (_.isUndefined(props)) {
      props = [] as C;
    }
    if (!_.isArray(props)) {
      throw new Error('value is not an array');
    }
    if (_.isArrayLike(schema.items)) {
      throw new Error('array items schema is not an object');
    }
    for (let i = 0; i < props.length; i++){
      try {
        props[i] = applyDefaultsToPropsRecursive(schema.items, props[i]);
      } catch (e) {
        console.warn(`propsWithDefaultsHelper unable to map array index ${i}`, e)
      }
    }
    return props;
  } else {
    // assumed primitive type
    return (_.isUndefined(props))? schema.default as C : props;
  }
}
