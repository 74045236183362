<div class="cards-editor-control-bar theme-dark">
  <Menu>
    <!--
    <CardsEditorLayerControlBarItem />
    <CardsEditorConfigControlBarItem />
    <CardsEditorAddChildControlBarItem />
    <CardsEditorRemoveChildControlBarItem />
    -->
    <CardsEditorPreviewControlBarItem />
    <CardsEditorFullscreenControlBarItem />
    <CardsEditorViewportWidthControlBarItem {breakpoints} />
  </Menu>
</div>

<style>
  *, *::before, *::after {
    box-sizing: border-box;
  }

  .cards-editor-control-bar {
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background: #eeeeee;
    color: white;
    padding: 5px 0px;
    /*
    position: sticky;
    position: -webkit-sticky;
    top: 0px;
    z-index: 999;
    */
    
    --f7-menu-bg-color: black;
    --f7-menu-text-color: white;
    --f7-menu-font-size: 12px;
    --f7-menu-item-height: 32px;
  }</style>

<script lang="ts">import { Menu } from 'framework7-svelte';
import './CardsEditorLayerControlBarItem.svelte';
import './CardsEditorConfigControlBarItem.svelte';
import './CardsEditorAddChildControlBarItem.svelte';
import './CardsEditorRemoveChildControlBarItem.svelte';
import CardsEditorPreviewControlBarItem from './CardsEditorPreviewControlBarItem.svelte';
import CardsEditorFullscreenControlBarItem from './CardsEditorFullscreenControlBarItem.svelte';
import CardsEditorViewportWidthControlBarItem from './CardsEditorViewportWidthControlBarItem.svelte';
const breakpoints = [
    { slug: 'phone', minWidth: 0, name: 'Phone', default: false },
    { slug: 'tablet', minWidth: 640, name: 'Tablet', default: false },
    { slug: 'desktop', minWidth: 1024, name: 'Desktop', default: true },
];
</script>