import Cards from '../pages/Cards.svelte';
import WebView from '../pages/WebView.svelte';
import PageBuilder from '../pages/PageBuilder.svelte';
import { JanusInternationalizationManagerToken, JanusInternationalizationManager } from './i18n';
import { getToken } from 'inversify-token';
import ROOT from '../inversify.config';
import { Unsubscriber, writable, Writable } from 'svelte/store';
import { f7, f7ready } from 'framework7-svelte';
import { JanusSignonManagerToken } from './sso';
import BlankPage from 'pages/BlankPage.svelte';

const signonManager = getToken(ROOT, JanusSignonManagerToken)
const signonStatus = signonManager.getStatusComponent().store

const i18nManager = getToken(ROOT, JanusInternationalizationManagerToken);
const currentLanguage = i18nManager.getCurrentLanguage();

let languageUnsubscribe: Unsubscriber = null;
const enableLanguageUrlSwitch = (to, from, resolve, reject) => {
  const initialLang = to.params.lang;
  //console.log('SET CURRENT LANGUAGE', initialLang);
  i18nManager.setCurrentLanguage(initialLang);
  // Listen for changes in language and change route as needed
  // we need to subscribe on the next tick because the setCurrentLanguage value may not have passed through to the store yet
  setTimeout(() => languageUnsubscribe = i18nManager.getCurrentLanguageStore().subscribe((lang) => {
    //console.log('LANGUAGE CHANGE', lang);
    if (lang !== initialLang) {
      f7.view.main.router.navigate({
        name: to.name,
        params: { ...to.params, lang },
        query: (Object.keys(to.query).length)? to.query : undefined
      }, {
        //clearPreviousHistory: true
      });
    }
  }), 0);
  resolve();
};
const disableLanguageUrlSwitch = (to, from, resolve, reject) => {
  if (languageUnsubscribe) {
    languageUnsubscribe();
    languageUnsubscribe = null;
  }
  resolve();
};

export interface Route {
  query: { [key: string]: string };
  params: { [key: string]: string };
  url: string;
  path: string;
  // this is the route's source configuration (from the routes array below)
  route: {
    name: string;
    path: string;
  }
  name: string;
}

// This is set by YCApp.svelte
export const currentRoute: Writable<Route> = writable(null);

const authGuard = (to, from, resolve, reject) => {
  const toListName = to.params.listName;
  const initialLang = to.params.lang;
  if( toListName === 'Profile') {
    return signonStatus.subscribe(signOn => {
      if (!signOn.loggedIn) {
        reject();
        f7.popup.open('.login-popup');
      } else {
        resolve()
      }
    })
  } else {
    resolve();
  }
}

const modalOptions = {
  pushState: true,
  props: {
    isModal: true
  }
};

const popupOptions = {
  backdrop: true,
  backdropEl: '.popup-backdrop.cards-modal-popup-backdrop',
  closeByBackdropClick: true,
  closeOnEscape: true,
};

const modalEvents = {
  close: function(popup) {
    setTimeout(() => {
      const el = popup.el;
      popup.destroy();
      el.remove();
    }, 0);
  }
};

function buildPagesPathFromSlug(slug: string) {
  return `/pages/${currentLanguage}/${slug}`;
}
const homeRedirect = buildPagesPathFromSlug('home');
const fourOhFourRedirect = buildPagesPathFromSlug('404');
//const homeRedirect = `/home`;

// TODO: Configure this per team.
const redirects = {
  "/club-info/club-partners": buildPagesPathFromSlug("club-partners"),
  "/renew": "https://tickets.brightonandhovealbion.com/subscriptions",
  "/cashless": buildPagesPathFromSlug("fans-matchday-at-the-amex"),
  "/seasonticket": "https://tickets.brightonandhovealbion.com/subscriptions",
  "/albion-as-one":buildPagesPathFromSlug( "albion-as-one"),
  "/contact": buildPagesPathFromSlug("contact-us"),
  "/membership": "https://tickets.brightonandhovealbion.com/",
  "/1901": buildPagesPathFromSlug("the-1901-club"),
  "/1901renewal": buildPagesPathFromSlug("1901-club-events"),
  "/privacy-policy": buildPagesPathFromSlug("privacy-policy"),
  "/hospitality/1901-club/existing-member": buildPagesPathFromSlug("1901-club-events"),
  "/seasontickets": "https://tickets.brightonandhovealbion.com/subscriptions",
  "/st": "https://tickets.brightonandhovealbion.com/subscriptions",
  "/soccerschools": "https://bookings.albioninthecommunity.org.uk/soccerschools/courses/courses.htm?coursesSearch=search&typeId=78",
  "/resale": "https://tickets.brightonandhovealbion.com/screenloader.aspx?type=include&page=usercontent/documents/html/resale.html",
  "/ssopentimes": "https://tickets.brightonandhovealbion.com/screenloader.aspx?type=include&page=usercontent/documents/html/openingtimes.html",
  "/resubscribe": "https://tickets.brightonandhovealbion.com/screenloader.aspx?type=include&page=usercontent/documents/html/re-subscribe.html",
  "/1901tix": "https://tickets.brightonandhovealbion.com/screenloader.aspx?type=include&page=usercontent/documents/html/1901tickethelp.html",
  "/hospitality-guide-202122": "https://resources.brightonandhovealbion.com/bhafc/document/2021/10/27/4afc35c4-5ef0-4291-af11-8d6e20811502/MatchdayHospitalityGuide.pdf",
  "/pl-home-tickets": "https://tickets.brightonandhovealbion.com/en-gb/categories/home",
  "/matchdayroles": "https://www.brightonandhovealbion.com/club/club/jobs/matchday-roles",
  "/tunnel": "https://thetunnelclub.brightonandhovealbion.com/",
  "/club/stadiums/the-amex-stadium/fans-guide": buildPagesPathFromSlug("fans-matchday-at-the-amex"),
  "/help": "https://tickets.brightonandhovealbion.com/screenloader.aspx?type=include&page=usercontent/documents/html/askalbion.html",
  "/fab": buildPagesPathFromSlug("fans-fan-advisory-board"),
  "/christmas": "https://www.eventsatbhafc.co.uk/christmas-parties",
  "/myplanet": buildPagesPathFromSlug("environmental-sustainability"),
  "/teams/womens-team/players/45941/vicky-losada%3fselectcompetition=-1": fourOhFourRedirect,
  "/club/club/jobs": buildPagesPathFromSlug("career-opportunities"),
};

// WARNING: Redirects break prerendering for the website. Don't use redirects here unless absolutely necessary.
const routes = [
  {
    path: '/',
    //component: Home
    redirect: homeRedirect
  },
  {
    path: '/index.html',
    //component: Home,
    redirect: homeRedirect
  },
  {
    name: 'page-with-slug',
    path: '/pages/:lang/:listName/:path1',
    component: Cards,
    beforeEnter: [authGuard, enableLanguageUrlSwitch],
    beforeLeave: disableLanguageUrlSwitch
  },
  {
    name: 'pages',
    path: '/pages/:lang/:listName',
    component: Cards,
    beforeEnter: [authGuard, enableLanguageUrlSwitch],
    beforeLeave: disableLanguageUrlSwitch,
  },
  {
    name: 'modal-with-slug',
    path: '/modals/:lang/:listName/:path1',
    options: modalOptions,
    popup: {
      ...popupOptions,
      component: Cards,
      on: modalEvents,
    },
    beforeEnter: [authGuard, enableLanguageUrlSwitch],
    beforeLeave: [disableLanguageUrlSwitch]
  },
  {
    name: 'modals',
    path: '/modals/:lang/:listName',
    options: modalOptions,
    popup: {
      ...popupOptions,
      component: Cards,
      on: modalEvents,
    },
    beforeEnter: [authGuard, enableLanguageUrlSwitch],
    beforeLeave: disableLanguageUrlSwitch
  },
  {
    path: '/webview',
    component: WebView,
  },
  {
    path: '/builder',
    component: PageBuilder,
    beforeLeave: function ({resolve, reject}) {
      // "Relax," said the night man...
      if (typeof reject === 'function') {
        reject();
      }
    }
  },
  {
    path: '/login-redirect',
    component: BlankPage,
  },
  {
    path: '(.*)',
    redirect: function (route, resolve, reject) {
      console.log('catch all redirect', route);
      const redirectPath = redirects[route.path.toLowerCase()];
      if (redirectPath) {
        if (redirectPath.startsWith('http://') || redirectPath.startsWith('https://')) {
          window.location.href = redirectPath;
          reject();
          return;
        } else if (redirectPath.startsWith('/')) {
          resolve(redirectPath);
          return;
        } else {
          reject();
          return;
        }
      }
      resolve(fourOhFourRedirect);
    }
  },
];

export default routes;
