import { AxiosRequestConfig, AxiosResponse, Method } from "axios";
import { YinzCamAPIRequest } from "./YinzCamAPIRequest";
import { YinzCamAPIRequestParameters } from "./YinzCamAPIRequestParameters";
import { YinzCamAPIResponse } from "./YinzCamAPIResponse";
import { YinzCamDataFormat } from "./YinzCamDataFormat";
import { CACHE_CONTROL_HEADER_KEY, CONTENT_TYPE_HEADER_KEY } from './constants';
import { ContentType, ContentTypeInfo } from './ContentType';
import { CacheControl, CacheControlInfo } from './CacheControl';
import convert from 'xml-js';
import { YinzCamAPIConfig } from "./YinzCamAPIConfig";

export function buildAxiosRequest(appConfig: YinzCamAPIConfig, parameters: YinzCamAPIRequestParameters, service: string, request: YinzCamAPIRequest): AxiosRequestConfig {
  // set up request parameters
  // internal defaults followed by external injected params followed by request params
  // keep in mind that params later in the chain override earlier ones
  const defaultHeaders: { [key: string]: string; } = { };
  const defaultParams: { [key: string]: string; } = { };

  if (appConfig.environment && (service === 'pages' || service === 'app')) {
    defaultHeaders['X-YinzCam-Environment'] = appConfig.environment;
    defaultParams['environment'] = appConfig.environment;
    if (service === 'pages') {
      defaultHeaders['X-YinzCam-Static-API-Use-Environment'] = 'true';
    }
  }
  if (service === 'pages') {
    defaultHeaders['X-YinzCam-Static-API-Use-Language'] = 'true';
  }

  const serviceParameters = (service)? parameters?.services?.[service] : null;
  const finalHeaders = {
    ...defaultHeaders,
    ...(parameters?.headers)? parameters.headers : {},
    ...(serviceParameters?.headers) ? serviceParameters.headers : {},
    ...(request.headers)? request.headers : {},
  };
  const finalParams = {
    ...defaultParams,
    ...(parameters?.params)? parameters.params : {},
    ...(serviceParameters?.params) ? serviceParameters.params : {},
    ...(request.params)? request.params : {},
  };

  if (request.requiredHeaders?.length > 0) {
    for (let headerKey of request.requiredHeaders) {
      if (!finalHeaders[headerKey]) {
        throw new Error("request is missing required header " + headerKey);
      }
    }
  }

  if (request.requiredParams?.length > 0) {
    for (let paramKey of request.requiredParams) {
      if (!finalParams[paramKey]) {
        throw new Error("request is missing required parameter " + paramKey);
      }
    }
  }

  let axiosReq: AxiosRequestConfig = {
    url: request.path,
    method: request.method as Method,
    headers: finalHeaders,
    params: finalParams,
    data: request.data,
  };
  if (request.allowAllStatusCodes) {
    axiosReq.validateStatus = () => true;
  }
  return axiosReq;
}

function extractContentType(rsp: AxiosResponse<any>): YinzCamDataFormat {
  let parseType: YinzCamDataFormat = YinzCamDataFormat.TEXT;
  if (rsp?.headers?.[CONTENT_TYPE_HEADER_KEY]) {
    let header = rsp.headers[CONTENT_TYPE_HEADER_KEY] as string;
    let index = header.indexOf(',');
    header = (index !== -1)? header.substr(0, index) : header;
    let ct: ContentTypeInfo = ContentType.parse(header);
    // The switch(true) thing here was tempting: https://stackoverflow.com/questions/2896626/switch-statement-for-string-matching-in-javascript
    // ... leaving this here because the reactions to it on SO were funny
    let ctstr: string = ct.type.toLowerCase();
    if (/json/.test(ctstr)) {
      parseType = YinzCamDataFormat.JSON;
    } else if (/xml/.test(ctstr)) {
      parseType = YinzCamDataFormat.XML;
    } else if (/urlencoded/.test(ctstr)) {
      parseType = YinzCamDataFormat.URLENCODED;
    } else {
      parseType = YinzCamDataFormat.TEXT;
    }
  } else {
    // console.log('missing Content-Type header');
  }
  return parseType;
}

function extractCacheTTL(appConfig: YinzCamAPIConfig, rsp: AxiosResponse<any>): number {
  let ttl: number = appConfig.defaultCacheTimeSeconds * 1000;
  if (rsp?.headers?.[CACHE_CONTROL_HEADER_KEY]) {
    let cc: CacheControlInfo = CacheControl.parse(rsp.headers[CACHE_CONTROL_HEADER_KEY]);
    if (cc && cc.maxAge) {
      ttl = cc.maxAge * 1000;
    }
  } else {
    // console.log('missing Cache-Control header');
  }
  return ttl;
}

function extractData(rsp: AxiosResponse<any>, parseType: YinzCamDataFormat): string | object {
  let data = rsp.data;
  switch (parseType) {
    case YinzCamDataFormat.JSON:
      switch (true) {
        case rsp.data instanceof String:
          rsp.data = rsp.data.toString();
          // fallthrough
        case typeof rsp.data === 'string':
          data = (rsp.data)? JSON.parse(rsp.data) : null;
          break;
        default:
          data = rsp.data;
      }
      break;
    case YinzCamDataFormat.XML:
      // More info on XML-JS conversion: https://www.npmjs.com/package/xml-js
      data = (rsp.data)? convert.xml2js(rsp.data, { compact: true, nativeType: true }) : null;
      break;
    case YinzCamDataFormat.URLENCODED:
    // TODO: I've never seen a server send back data in this format. Implement if needed.
    // Otherwise, assume it's just text for the caller to handle.
    case YinzCamDataFormat.TEXT:
    // TODO: had some ideas here to try to detect the content type (JSON/XML/HTML/...)
    // Don't want to get too cute here though.
    // Detecting JSON (trivial): https://stackoverflow.com/questions/9804777/how-to-test-if-a-string-is-json-or-not
    // Detecting XML: https://stackoverflow.com/questions/8672597/how-should-i-test-if-an-object-is-a-xml-document-in-a-cross-browser-way
    // Detecting HTML (troll): https://stackoverflow.com/questions/15458876/check-if-a-string-is-html-or-not
    // (intentional fall-through)
    default:
      data = rsp.data;
      break;
  }
  return data;
}

export function buildYinzCamAPIResponse(appConfig: YinzCamAPIConfig, rsp: AxiosResponse<any>): YinzCamAPIResponse {
  // NOTE: can't rely on rsp.request to be set here. Sometimes it's not set.
  // FIXME: in YinzCamServer, baseURL is set on the Axios object itself, not in the config
  // either we always set it on the request, or we need to get it from the Axios object
  let url: URL = (rsp.config.baseURL) ? new URL(rsp.config.url, rsp.config.baseURL) : new URL(rsp.config.url);
  if (rsp.config.params) {
    for (let k of Object.getOwnPropertyNames(rsp.config.params)) {
      url.searchParams.set(k, rsp.config.params[k]);
    }
  }

  let parseType: YinzCamDataFormat = extractContentType(rsp);
  let ttl: number = extractCacheTTL(appConfig, rsp);
  let data = extractData(rsp, parseType);

  return {
    status: rsp.status,
    isStatusNot2xx: rsp.status < 200 || rsp.status > 299,
    url: url.toString(),
    ttl,
    data
  };
}

export function buildYinzCamStandardHostname(service: string, tricode: string = CONFIG.tricode, league: string = CONFIG.league) {
  return `${service}-${tricode}-${league}.yinzcam.com`;
}
