<Boundary>
  <Tabs>
    {#each tabs as tab, i (tab.id)}
      <Tab id={tab.id} tabActive={defaultTabIndex === i} on:tabShow={onTabShow.bind(null, tab.id)}>
        <CardsTab {parentSequenceId} sequenceId={generateSequenceId('TAB', parentSequenceId, i)} {tab} updater={(tab) => tabs[i] = tab} />
      </Tab>
    {/each}
  </Tabs>
</Boundary>

<style>
  *, *::before, *::after {
    box-sizing: border-box;
  }</style>

<script lang="ts">import "yinzcam-cards";
import CardsTab from "./CardsTab.svelte";
import { Tabs, Tab } from 'framework7-svelte';
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
import { getContext } from 'svelte';
import { CardsPageContextKey } from './context';
import { generateSequenceId } from "../utilities";
export let parentSequenceId = "";
export let tabs;
const defaultTabIndex = Math.max(tabs === null || tabs === void 0 ? void 0 : tabs.findIndex((val) => val === null || val === void 0 ? void 0 : val.default), 0);
const pageContext = getContext(CardsPageContextKey);
function onTabShow(id) {
    pageContext.getAnalytics().sendInteractionEvent('Tab_Click', id);
}
</script>
