import { CardsDataSourceBase } from "../../common/CardsDataSourceBase";
import { injectable } from "inversify";
import { injectToken } from "inversify-token";
import { ContentfulCDNResponse, ContentfulCDNToken } from "contentful-api";
import type { ContentfulCDN } from "contentful-api";
import _ from "lodash";
import { JSONSchema4 } from "json-schema";
import { stripContentfulMetadata } from "lib/contentful-api/ContentfulUtilities";

@injectable()
export class ContentfulPageData extends CardsDataSourceBase<ContentfulCDNResponse> {
  private readonly cdn: ContentfulCDN;

  public constructor(@injectToken(ContentfulCDNToken) cdn: ContentfulCDN) {
    super({ server: cdn })
    this.cdn = cdn;
  }

  public getDisplayName(): string {
    return 'Contentful Page Data';
  }

  public async getDataSourcePaths(specData?: { [key: string]: any }): Promise<{ name: string; path: string; }[]> {
    return [{ name: 'Page', path: 'page' }];
  }

  // easy way
  public async getDataSourceConfigSpec(path?: string, specData?: { [key: string]: any }): Promise<JSONSchema4> {
    return {
      type: "object",
      properties: {
        "fieldset": {
          type: "string",
          title: "Field Set",
          description: "The page data field set to expose from this data source.",
          "enum": [
            "metadata",
            "accordions",
            "articles",
            "images",
            "callsToAction"
          ],
          "options": {
            "enum_titles": [
              "Metadata",
              "Accordions",
              "Articles",
              "Images",
              "Calls to Action"
            ]
          }
        },
        query: {
          type: "object",
          title: "Query",
          description: "The search query to use for the data source.",
          properties: {
            "fields.slug": {
              type: "string",
              title: "Slug",
              description: "The slug of the page.",
              // TODO: Use Contentful API to look up valid options for slug?
            },
          },
          required: [
          ]
        }
      },
      required: [
        "fieldset",
        "query"
      ]
    };
  }

  protected getExtraParams(specData?: { [key: string]: any; }): { [key: string]: string; } {
    return specData?.query || {};
  }

  protected processResponse(response: ContentfulCDNResponse, specData?: { [key: string]: any; }): object | any[] {
    console.log('CONTENTFUL PAGE DATA RESPONSE', specData, response);
    const strippedObjects = stripContentfulMetadata(response.items) as any[];
    console.log('CONTENTFUL PAGE DATA STRIPPED', strippedObjects);
    if (!strippedObjects?.length) {
      return null;
    }
    const stripped = strippedObjects[0];
    if (!stripped?.pageData?.slug) {
      return null;
    }
    const pageData = stripped.pageData;
    const fieldset = specData?.['fieldset'] || 'metadata';
    let ret = null;
    switch (fieldset) {
      case "metadata":
        ret = Object.fromEntries(Object.entries(pageData).filter(([k, v]) => !k.startsWith('embedded')));
        break;
      default:
        ret = pageData[_.camelCase(`embedded ${fieldset}`)] || null;
        break;
    }
    console.log('CONTENTFUL PAGE DATA RETURNING', specData, ret);
    return ret;
  }
}
