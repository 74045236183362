import { generateArraySchema, generateBooleanSchema, generateColorSchema, generateDateTimeSchema, generateDurationSchema, generateNumberSchema, generateObjectSchema, generateRootObjectSchema, generateStringSchema, generateUrlSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { ButtonParticleSpec, ImageParticleSpec, TextParticleSpec } from "components/cards/particles/YinzCam";

export const specification = new BaseAtomSpec("Navigation Menu (Style C)", generateRootObjectSchema({
  container: generateAtomContainerSchema({
    defaultValues: { themeMode: 'inverted', background: { visible: true } }
  }),
  menuTitleShowChevron: generateBooleanSchema("Show Chevron", "For menu titles with submenu items, show a chevron to the right of the title."),
  menuTitleShowActiveBackground: generateBooleanSchema("Show Active Background on Hover", "For menu titles, show the active background color on hover."),
  menuTitleActiveBackgroundColor: generateColorSchema("Background Color on Hover", "The hover background color for menu titles."),
  menuTitleShowActiveUnderline: generateBooleanSchema("Show Underline on Hover", "For menu titles, show an underline on hover."),
  menuTitleActiveUnderlineColor: generateColorSchema("Underline Color on Hover", "The hover underline color for menu titles."),
  logoButton: ButtonParticleSpec.generateSchema("Logo Button", "The configuration for the logo button on the left side, spanning both rows.", {
    defaultValues: {
      showText: false,
      showImage: true,
      link: 'home',
      image: { sizing: 'contain', container: { height: '5rem', fillWidth: false }, fillWidth: false }
    }
  }),
  promptText: TextParticleSpec.generateSchema("Prompt Text", "The main prompt text.", {
    defaultValues: { size: '0.75rem', weight: '400', wrap: 'nowrap', text: "What are you looking for?" }
  }),
  browseDropdownLabel: TextParticleSpec.generateSchema("Browse Dropdown Label", "The browse dropdown label text.", {
    defaultValues: { size: '0.75rem', weight: '800', wrap: 'nowrap', text: "Browse", useAccentColor: true }
  }),
  browseButtons: generateArraySchema("Browse Buttons", "The browse buttons.",
    ButtonParticleSpec.generateSchema("Browse Button", "A browse button.", {
      defaultValues: { 
        showImage: false,
        showText: true,
        text: { size: '1rem', weight: '600', wrap: 'nowrap', useAccentColor: true }
      },
    })
  ),
  languageFlagTemplate: ImageParticleSpec.generateSchema("Language Flags", "The language flag images.", {
    defaultValues: { sizing: 'contain', container: { height: '2rem', width: '2rem' }, fillHeight: false, fillWidth: false },
    uneditableFields: { source: true, alt: true, icon: true },
  }),
  languageLabelTemplate: TextParticleSpec.generateSchema("Language Labels", "The language label texts.", {
    defaultValues: { size: '0.75rem', weight: '800', wrap: 'nowrap', text: "Browse", useAccentColor: true },
    uneditableFields: { text: true }
  }),
  sponsorButtons: generateArraySchema("Sponsor Buttons", "The top-row sponsor buttons.", 
    ButtonParticleSpec.generateSchema("Sponsor Button", "The configuration for the sponsor button on the right side of the top row.", {
      defaultValues: {
        container: { padding: '1rem 0'},
        image: { sizing: 'contain', container: { height: '1.5rem', fillWidth: false }, fillWidth: false },
        showText: false,
        showImage: true,
        gap: '0.5rem'
      },
    })
  ),
  items: generateArraySchema("Items", "The array of top-level navigation-menu items.", 
    generateObjectSchema("Item", "A top-level navigation-menu item.", {}, { additionalProperties: true })
  ),
}));
