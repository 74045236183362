<script lang="ts">import { BaseParticle } from "components/cards/particles";
// props
export let container = undefined;
export let source = "";
export let controls = undefined;
export let autoplay = undefined;
export let muted = undefined;
export let loop = undefined;
// inputs
// outputs
</script>

<style>
  audio {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }</style>

<BaseParticle _name="AudioParticle" _cssVariablePrefix="audio-particle" {...container}>
  <audio
    src={source}
    {controls}
    {autoplay}
    {muted}
    {loop}
  />
</BaseParticle>
