<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import { merge } from "lodash";
import { BaseAtom } from "components/cards/atoms";
import { TextParticle, ButtonParticle, } from "components/cards/particles/YinzCam";
export let container;
export let playerStats = undefined;
export let statsList;
export let borderRadius;
export let colorContainerBG;
export let colorStatBackground;
export let colorTabBorder;
let atomWidthRem;
$: statsDataSource = playerStats === null || playerStats === void 0 ? void 0 : playerStats.filter((playerStat) => { var _a; return statsList === null || statsList === void 0 ? void 0 : statsList.includes((_a = playerStat === null || playerStat === void 0 ? void 0 : playerStat.name) === null || _a === void 0 ? void 0 : _a.text); });
$: statsDisplay =
    (statsDataSource === null || statsDataSource === void 0 ? void 0 : statsDataSource.length) > 0 ? statsDataSource : playerStats;
</script>

<BaseAtom
    bind:_clientWidthRem={atomWidthRem}
    _isotope="StatsNumber"
    _cssVariablePrefix="stat-number"
    {...container}
    {borderRadius}
    {colorContainerBG}
    {colorStatBackground}
    {colorTabBorder}
>
    <div class="cards-container">
        {#each statsDisplay || [] as stat, id}
            {#if stat.name !== undefined}
                <div class="stat-card">
                    <div class="highlight-shape">
                        <ButtonParticle
                            {...merge({}, stat.highlightBox, {
                                container: {
                                    background: {
                                        color: `${stat.highlightColor}`,
                                    },
                                },
                            })}
                        />
                    </div>
                    <TextParticle _block={true} {...stat.homeValue} />
                    <TextParticle _block={true}
                        {...merge({}, stat.name, {})}
                    />
                </div>
            {/if}
        {/each}
    </div>
</BaseAtom>

<style>
    .cards-container {
        width: 100%;
        height: 100%;
        display: flex;
        gap: 0.125rem;
        flex-wrap: wrap;
        background-color: var(--stat-number-color-container-bg, #fff);
    }
    .stat-card {
        display: flex;
        flex-direction: column;
        flex: 1 1;
        justify-content: space-evenly;
        padding: 0.75rem 0.8125rem;
        border: 1px solid var(--stat-number-color-tab-border, unset);
        border-radius: var(--stat-number-border-radius, 0.75rem);
        align-items: center;
        position: relative;
        background-color: var(
            --stat-number-color-stat-background,
            var(--theme-mode-background-color, unset)
        );
    }
    .highlight-shape {
        width: 1.625rem;
        height: 2.25rem;
        position: absolute;
        top: 0.75rem;
        left: 1.06rem;
        border-radius: var(--stat-number-border-radius, 0.75rem);
    }</style>
