<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import "lodash";
import { BaseAtom } from "components/cards/atoms";
import { TextParticle, ImageParticle, ButtonParticle, } from "components/cards/particles/YinzCam";
export let container;
export let containerPadding;
export let txtHeading;
export let txtHeaderData;
export let txtRowData;
export let imgTeamLogo;
export let btnFullTable;
export let colorHeadingBackground;
export let tableHeaderColor;
const tableHeaders = [
    "Pos",
    "Club",
    "P",
    "W",
    "D",
    "L",
    "GF",
    "GA",
    "GD",
    "PTS",
];
const rowData = [
    {
        Pos: "1",
        Club: "Brighton",
        P: "10",
        W: "10",
        D: "10",
        L: "10",
        GF: "10",
        GA: "10",
        GD: "10",
        PTS: "10",
    },
    {
        Pos: "2",
        Club: "Wolverine",
        P: "10",
        W: "10",
        D: "10",
        L: "10",
        GF: "10",
        GA: "10",
        GD: "10",
        PTS: "10",
    },
    {
        Pos: "3",
        Club: "Albion & Hove",
        P: "999",
        W: "999",
        D: "999",
        L: "999",
        GF: "999",
        GA: "999",
        GD: "999",
        PTS: "999",
    },
    {
        Pos: "4",
        Club: "Manchester United",
        P: "5",
        W: "5",
        D: "5",
        L: "5",
        GF: "5",
        GA: "5",
        GD: "5",
        PTS: "5",
    },
];
let atomWidthRem;
</script>

<BaseAtom
    bind:_clientWidthRem={atomWidthRem}
    _isotope="LeagueTableTwoAtom"
    _cssVariablePrefix="league-table-2"
    {...container}
    {containerPadding}
    {colorHeadingBackground}
    {tableHeaderColor}
>
    <div class="main-container">
        <div class="heading-text">
            <TextParticle {...txtHeading} />
        </div>
        <div class="table-container">
            <table>
                <thead class="table-header">
                    <tr>
                        {#each tableHeaders || [] as header}
                            <th class={header}>{header}</th>
                        {/each}
                    </tr>
                </thead>
                <tbody>
                    {#each rowData || [] as data}
                        <tr>
                            {#each Object.entries(data) || [] as [key, value]}
                                {#if key === "Club"}
                                    <td class={key}>
                                        <ImageParticle {...imgTeamLogo} />
                                        <TextParticle
                                            {...txtRowData}
                                            text={value}
                                        />
                                    </td>
                                {:else}
                                    <td class={key}>
                                        <TextParticle
                                            {...txtRowData}
                                            text={value}
                                        />
                                    </td>
                                {/if}
                            {/each}
                        </tr>
                    {/each}
                </tbody>
            </table>
        </div>
        <div class="full-table-button">
            <ButtonParticle {...btnFullTable} />
        </div>
    </div>
</BaseAtom>

<style>
    .main-container {
        padding: var(--mc-commentary-container-padding, unset);
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .heading-text {
        width: 100%;
        text-align: center;
        background-color: var(--league-table-2-color-heading-background, unset);
        padding: 0.5rem;
    }
    .table-container {
        width: 100%;
    }
    .table-container table {
            text-align: left;
            width: 100%;
            border-spacing: none;
            border-collapse: collapse;
        }
    .table-container table thead {
                background-color: var(
                    --league-table-2-table-header-color,
                    #d0d0d0
                );
            }
    .table-container table thead tr th {
                        width: 3%;
                        text-align: center;
                    }
    .table-container table tbody tr td {
                        text-align: center;
                    }
    .table-container .Club {
            width: 70%;
            display: flex;
            align-items: center;
        }</style>
