<Boundary>
  <div bind:this={wrapper}>
    <CardsComponentWrapper style="
      {buildCssPadding(tab.padding)}
      {buildCssMargin(tab.margin)}
      {buildCssBackground(tab.background)}
      {buildCssFont(tab.font)}
    " {editorOptions}>
      {#if contentStore && $contentStore}
        <CardsSections parentSequenceId={sequenceId} sections={$contentStore.sections} updater={(sections) => tab.sections = sections} />      
      {/if}
    </CardsComponentWrapper>
  </div>
</Boundary>

<style>
  *, *::before, *::after {
    box-sizing: border-box;
  }</style>

<script lang="ts">import { YinzCamCardsServiceToken } from "yinzcam-cards";
import CardsSections from "./CardsSections.svelte";
import { buildCssPadding, buildCssBackground, buildCssMargin, buildCssFont } from '../utilities/index';
import { getContext, onMount, setContext } from "svelte";
import { CardsPageContextKey, CardsTabContextKey } from "./context";
import { CardsDataSourceManager } from "./CardsDataSourceManager";
import ROOT from "../../../inversify.config";
import { writable } from "svelte/store";
import { getToken } from "inversify-token";
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
import { JanusModeContextManagerToken } from "../../../js/mode";
import { MergedYinzCamAPIRequestParameterComponent } from "yinzcam-api";
import { AbstractReactiveMicrocomponent, ManualPassthrough } from "yinzcam-rma";
import _ from "lodash";
import CardsComponentWrapper from './CardsComponentWrapper.svelte';
import "./CardsEditorInterfaces";
import { CARDS_TAB_SCHEMA, generateTabDataSourcesSchema } from "./CardsEditorComponentSchemata";
export let parentSequenceId = null;
export let sequenceId = "";
export let tab;
export let updater;
const cardsService = getToken(ROOT, YinzCamCardsServiceToken);
const dataSourceManager = new CardsDataSourceManager(ROOT);
const pageContext = getContext(CardsPageContextKey);
const defaultParamsComp = pageContext.defaultParamsComp;
const modeParamsComp = getToken(ROOT, JanusModeContextManagerToken).getMergedRequestParameterComponent();
const pageParamsComp = new ManualPassthrough({ name: `${pageContext.id}_CardsTab_${tab.id}_PageParams`, saveToLocalStorage: false }, { params: pageContext.query });
const internalParamsComp = new ManualPassthrough({ name: `${pageContext.id}_CardsTab_${tab.id}_InternalParams`, saveToLocalStorage: false }, { params: {} });
const mergedApiParamsComp = new MergedYinzCamAPIRequestParameterComponent({ name: `${pageContext.id}_CardsTab_${tab.id}_MergedApiParams`, saveToLocalStorage: false }, defaultParamsComp, modeParamsComp, pageParamsComp, internalParamsComp);
const pathParamsMappingComp = pageContext.pathParamsMappingComp;
const mergedParamsComp = new class extends AbstractReactiveMicrocomponent {
    constructor() {
        super(...arguments);
        this.lastParams = undefined;
    }
    async update($control, $apiParams, $pathParamsMap) {
        const newParams = $apiParams === null || $apiParams === void 0 ? void 0 : $apiParams.params;
        //console.log('TAB API PARAMS', newParams);
        for (let [i, param] of $pathParamsMap.entries()) {
            if (!param)
                continue;
            const subst = newParams[`path${i}`];
            if (!subst)
                continue;
            newParams[param] = subst;
        }
        if (this.lastParams === newParams || _.isEqual(this.lastParams, newParams)) {
            throw new Error("params have not changed");
        }
        this.lastParams = newParams;
        return newParams;
    }
}({ name: `${pageContext.id}_CardsTab_${tab.id}_MergedParams`, saveToLocalStorage: false, expectThrowFromUpdate: true }, mergedApiParamsComp, pathParamsMappingComp);
const mergedParamsStore = mergedParamsComp.store;
const setParam = function (key, value) {
    if (!key) {
        return;
    }
    let apiParams = internalParamsComp.getValue();
    if (!(apiParams === null || apiParams === void 0 ? void 0 : apiParams.params)) {
        apiParams = { params: {} };
    }
    if (!value) {
        delete apiParams.params[key];
    }
    else {
        apiParams.params[key] = value;
    }
    internalParamsComp.setValue(apiParams);
};
const scrollContainer = writable(document.body);
setContext(CardsTabContextKey, { dataSourceManager, mergedParamsComp, setParam, scrollContainer });
let contentStore;
let wrapper;
let loadedFromFragment = false;
//$: fragmentId = tab?.fragmentId;
//$: sections = tab?.sections;
$: if (tab && (tab.sections || tab.fragmentUri)) {
    let setContent = false;
    if (tab.fragmentUri) {
        try {
            contentStore = cardsService.getCardsFragment(tab.fragmentUri).store;
            setContent = true;
            loadedFromFragment = true;
        }
        catch (e) {
            console.error(`unable to load tab from fragment '${tab.fragmentUri}, falling back to tab content`, e);
        }
    }
    if (!setContent && tab.sections) {
        contentStore = writable(tab);
        setContent = true;
        loadedFromFragment = false;
    }
    if (!setContent) {
        contentStore = writable({ sections: [], sources: [] });
        loadedFromFragment = false;
    }
}
/*
$: if (sections) {
  try {
    if (fragmentId) {
      console.error('cannot define both sections and fragment ID for a tab');
    } else {
      
    }
  } finally {}
}

$: if (fragmentId) {
  try {
    if (sections) {
      console.error('cannot define both sections and fragment ID for a tab');
    } else {
      contentStore = cardsService.getCardsFragment(fragmentId).store;
    }
  } finally {}
}
*/
$: if (contentStore && !_.isNil($contentStore) && mergedParamsStore && !_.isNil($mergedParamsStore)) {
    try {
        dataSourceManager.setDataSources($contentStore.sources, $mergedParamsStore);
    }
    finally { }
}
async function generateSchema(component) {
    //console.log('GENERATE SCHEMA', JSON.stringify(component, null, 2));
    const schema = _.cloneDeep(CARDS_TAB_SCHEMA);
    schema.properties.sources = await generateTabDataSourcesSchema(component.sources);
    //await new Promise(resolve => setTimeout(resolve, 1000));
    //console.log('RESULT SCHEMA', schema); 
    return schema;
}
let editorOptions;
$: editorOptions = {
    parentSequenceId,
    sequenceId,
    componentId: tab.id,
    component: tab,
    componentTypeName: 'View',
    childTypeNames: ['Section'],
    primaryColor: '#F3BD1C',
    reverseTextColor: 'black',
    unselectedBackgroundColor: '#8C959E',
    unselectedTextColor: '#FFFFFF',
    selectedBackgroundColor: '#FFDA53',
    selectedTextColor: '#202020',
    childrenKey: 'sections',
    /*configSpec: CARDS_TAB_SCHEMA,*/
    configSpecGenerator: generateSchema,
    allowChildren: true,
    allowDeletion: false,
    allowDuplication: false,
    allowMovement: false,
    cloned: loadedFromFragment,
    allowEditsToSelfIfCloned: true,
    updater
};
function findScrollContainer() {
    let el = wrapper;
    while (el = el.parentElement) {
        let style = getComputedStyle(el);
        if (String(style === null || style === void 0 ? void 0 : style.overflow).toLowerCase() === 'auto') {
            scrollContainer.set(el);
            break;
        }
    }
}
onMount(() => {
    findScrollContainer();
});
</script>
