import { generateArraySchema, generateBooleanSchema, generateColorSchema, generateObjectSchema, generateRootObjectSchema, generateStringSchema, generateUrlSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { TextParticleSpec, ImageParticleSpec, ButtonParticleSpec } from "components/cards/particles/YinzCam";

export const specification = new BaseAtomSpec("Player Header Card", generateRootObjectSchema({
    container: generateAtomContainerSchema({
        defaultValues: { themeMode: 'primary' },
    }),
    overlayPadding: generateStringSchema("Overlay Padding", "The padding (inset) of the overlay text/buttons relative to the background image.", {
        defaultValue: '4.31rem 2.25rem'
    }),
    txtHeaderTabs: generateArraySchema("Header Tabs", "Header Tabs",
        generateObjectSchema("Header Tab", "Header tab info", {
            tabName: TextParticleSpec.generateSchema("Tab Name", "Enter a tab name", {
                defaultValues: { size: "1.25rem", weight: "600", color: "var(--player-header-color-tab, var(--theme-mode-foreground-color, #FFF))" }
            })
        })),
    colorActiveTab: generateColorSchema("Active Tab Color", "Pick a color for active tab"),
    colorTabBackground: generateColorSchema("Tab Background Color", "Pick a color for tab background"),
    colorTabBorder: generateColorSchema("Tab Border Color", "Pick a color for tab border"),
    colorTab: generateColorSchema("Tab font color", "Pick a color for tab font"),
    colorContainerBackground: generateColorSchema("Container Color", "Pick a color for container"),
}));
