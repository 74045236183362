<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import "lodash";
import { BaseAtom, getMergedParams, getViewContext } from "components/cards/atoms";
import { ButtonParticle } from "components/cards/particles/YinzCam";
export let container = undefined;
export let elementId = undefined;
export let repeatCount = undefined;
export let button = undefined;
const viewContext = getViewContext();
const mergedParams = getMergedParams();
//let totalCount = -1
let actionObj;
$: {
    const paramObj = $mergedParams;
    actionObj = JSON.parse((paramObj === null || paramObj === void 0 ? void 0 : paramObj['localEvent']) || '{}');
}
const handleClick = (e) => {
    viewContext.setParam('localEvent', JSON.stringify({
        type: 'INCREASE_REPEAT_COUNT',
        id: elementId,
        value: ((actionObj['value']) ? actionObj['value'] : 0) + repeatCount,
        actionId: (actionObj['actionId']) ? actionObj['actionId'] + 1 : 0,
    }));
};
</script>

<style></style>

<BaseAtom _isotope="ButtonAtom" {...container}>
  <ButtonParticle {...button} on:click={handleClick} />
</BaseAtom>
