import { generateBooleanSchema, generateColorSchema, generateRootObjectSchema, generateStringSchema, generateUrlSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { TextParticleSpec, ImageParticleSpec, ButtonParticleSpec } from "components/cards/particles/YinzCam";

export const specification = new BaseAtomSpec("CTA Horizontal Card", generateRootObjectSchema({
    container: generateAtomContainerSchema({
        defaultValues: { themeMode: 'overlay' },
        uneditableFields: { background: false }
    }),
    overlayPadding: generateStringSchema("Overlay Padding", "The padding (inset) of the overlay text/buttons relative to the background image.", {
        defaultValue: '4.31rem 2.25rem'
    }),
    textBackgroundColor: generateColorSchema("Background Color for Text", "Pick a color for Text area background"),
    link: generateUrlSchema("Link", "The link for this media item. The default link will be generated from the slug if this is not provided (and the slug is)."),
    isBodyTextRequired: generateBooleanSchema("Body Text", "Is body text required"),
    headerText: TextParticleSpec.generateSchema("Heading Text", "Please enter a text for Heading", {
        defaultValues: { size: '3rem', style: 'normal', weight: '900', container: { themeMode: 'overlay' } }
    }),
    bodyText: TextParticleSpec.generateSchema("Body Text", "Please enter a text for Body here", {
        defaultValues: { size: '1.25rem', style: 'normal', weight: '400', }
    }),
    thumbnail: ImageParticleSpec.generateSchema("Thumbnail", "The image thumbnail for the background of the card.", {
        defaultValues: { sizing: 'cover', hoverZoomEnabled: true, role: 'thumbnail' },
    }),
    ctaButton: ButtonParticleSpec.generateSchema("CTA Button", "The CTA button.", {
        defaultValues: {
            showImage: false, container: {
                themeMode: 'primary', background: { visible: true, useHighlightColor: true }, border: { visible: true, radius: '8px' },
                padding: '0.75rem 1.25rem', width: 'max-content',
            }, text: { size: '1rem', weight: '600' }
        }
    }),
    reverseOrientation: generateStringSchema("Reverse Orientation", "Reverse Text and Image orientations", {
        choices: ['image', 'text'],
        choiceTitles: ["Image First", "Text First"]
    }),
    scaleToThumbnailAspectRatio: generateBooleanSchema("Scale to Thumbnail Aspect Ratio", "Scale the width and height of the card to the thumbnail's aspect ratio.", {
        defaultValue: true,
    }),
}));
