<script lang="ts">import { BaseParticle } from "components/cards/particles";
import { TextInputParticle } from "../TextInput";
import "../Button";
import ButtonParticle from "../Button/ButtonParticle.svelte";
import { resolveUrl } from "js/url";
// props
export let container = undefined;
export let inputBox = undefined;
export let clearButton = undefined;
// outputs
export let _value = '';
function handleKeypress(e) {
    if (e.key === 'Enter') {
        // enter pressed, submit search
        const value = _value;
        _value = '';
        resolveUrl(`yc://action/OPEN_PAGE?intent=SEARCH&contentSlug=${encodeURIComponent(value)}`).func();
        e.preventDefault();
    }
}
function handleClear(e) {
    _value = '';
    e.preventDefault();
}
</script>

<style>
  .search-particle-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 1rem;
  }

  .search-box {
    flex: 1 0 auto;
  }

  .clear-box {
    flex: 0 0 auto;
  }</style>

<BaseParticle _name="SearchParticle" _cssVariablePrefix="rich-text-particle" {...container} >
  <div class="search-particle-container">
    <div class="search-box">
      <TextInputParticle bind:_value on:keydown={handleKeypress} _block={true} {...inputBox} />
    </div>
    <div class="clear-box">
      <ButtonParticle on:click={handleClear} {...clearButton} />
    </div>
  </div>
</BaseParticle>
