<Boundary>
  <div style="
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: {flexDirection};
    flex-wrap: {(flexWrap)? 'wrap':'nowrap'};
  ">
    {#each regions as region, i (region.id)}
      <div style="
        {flowDimension}: {(region.size)? region.size + '%' : 'auto'};
        flex-shrink: {(region.fill)? 1 : 0};
        flex-grow: {(region.fill)? 1 : 0};
        overflow: {(region.scrollable)? 'auto' : 'visible'};
        {region.wrapperStyle || ""}
      ">
        <CardsRegion {parentSequenceId} sequenceId={generateSequenceId('RGN', parentSequenceId, i)} {region} />
      </div>
    {/each}
  </div>
</Boundary>

<style>
  *, *::before, *::after {
    box-sizing: border-box;
  }</style>

<script lang="ts">import "yinzcam-cards";
import CardsRegion from "./CardsRegion.svelte";
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
import { generateSequenceId } from "../utilities";
import { getContext } from 'svelte';
import { CardsPageContextKey } from './context';
import "./CardsEditorActions";
//import { readable, writable } from "svelte/store";
//import CardsComponentContainer from "./CardsComponentContainer.svelte";
export let parentSequenceId = "";
export let regions;
//export let updater: (regions: YinzCamCardsServiceRegion[]) => void;
export let direction;
export let flexWrap;
const pageContext = getContext(CardsPageContextKey);
//{flowDimension}: {(region.size)? region.size : 100}%;
let flexDirection, flowDimension, spanDimension;
$: if ((direction === null || direction === void 0 ? void 0 : direction.toUpperCase()) === 'HORIZONTAL') {
    flexDirection = 'row';
    flowDimension = 'width';
    spanDimension = 'height';
}
else {
    flexDirection = 'column';
    flowDimension = 'height';
    spanDimension = 'width';
}
</script>
