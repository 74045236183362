import { generateArraySchema, generateBooleanSchema, generateDateTimeSchema, generateDurationSchema, generateNumberSchema, generateRootObjectSchema, generateStringSchema, generateUrlSchema, generateColorSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { ButtonParticleSpec, ImageParticleSpec, TextParticleSpec } from "components/cards/particles/YinzCam";

export const specification = new BaseAtomSpec("Image Background Media", generateRootObjectSchema({
  container: generateAtomContainerSchema({
    defaultValues: { themeMode: 'overlay' },
    uneditableFields: { background: false }
  }),
  overlayPadding: generateStringSchema("Overlay Padding", "The padding (inset) of the overlay text/buttons relative to the background image.", {
    defaultValue: '1rem'
  }),
  mediaType: generateStringSchema("Type", "The type of this media item. This controls the media-type indicator icon as well as the default media item deep link.", {
    choices: ['article', 'video', 'gallery', 'audio'],
    choiceTitles: ["News Article", "Video", "Photo Gallery", "Audio"]
  }),
  slug: generateStringSchema("Slug", "The slug for this media item. This will be used to generate the media item link if an explicit link is not provided."),
  link: generateUrlSchema("Link", "The link for this media item. The default link will be generated from the slug if this is not provided (and the slug is)."),
  publishDateTime: generateDateTimeSchema("Publish Date and Time", "The date and time that this content was published."),
  viewTimeDuration: generateDurationSchema("View Time Duration", "The duration that an average user would take to completely consume this content."),
  views: generateNumberSchema("Views", "The number of views of this content."),
  thumbnail: ImageParticleSpec.generateSchema("Thumbnail", "The image thumbnail for the background of the card.", {
    defaultValues: { sizing: 'cover', hoverZoomEnabled: true, darkenFilterEnabled: true, fitVerticalAlign: 'top', role: 'thumbnail' },
  }),
  categories: generateArraySchema("Categories", "The categories to list for the media item.",
    ButtonParticleSpec.generateSchema("Category", "A category for the media item.", {
      defaultValues: { showImage: false, container: { themeMode: 'inverted', background: { visible: true }, border: { visible: true, radius: '8px' }, padding: '0.5rem 0.75rem' }, text: { size: '1rem', weight: '600' } }
    })
  ),
  header: TextParticleSpec.generateSchema("Header Text", "The text styling for the header text.", {
    defaultValues: { size: '1rem', weight: '600', transform: 'capitalize' },
    uneditableFields: { text: true }
  }),
  titleText: TextParticleSpec.generateSchema("Title Text", "The title text.", {
    defaultValues: { size: '2rem', weight: '900' },
  }),
  descriptionText: TextParticleSpec.generateSchema("Description Text", "The description text.", {
    defaultValues: { size: '1rem', weight: '600' },
  }),
  cta: ButtonParticleSpec.generateSchema("CTA", "The CTA for the media item.", {
    defaultValues: { showImage: false, container: { themeMode: 'primary', background: { visible: true, useHighlightColor: true }, border: { visible: true, radius: '8px' }, padding: '0.75rem 1.25rem' }, text: { size: '1.25rem', weight: '600' } }
  }),
  imageGradient: generateBooleanSchema("Apply gradient to BG?", "Select if a gradient should be applied for BG Image"),
  gradientColor: generateColorSchema("Color for BG Gradient", "Pick a gradient color for BG"),
  mediaIconColor: generateColorSchema("Media Type Icon Color", "Pick a color for media type icon"),
  shouldDisplayCategories: generateBooleanSchema("Should display categories?", "Specify if categories should be displayed"),
  dateAndIconOrientation: generateStringSchema("Change Date and Icon position", "Change the position of Date and Media Icon", {
    choices: ["primary", "secondary"],
    choiceTitles: ["Primary", "Secondary"],
    defaultValue: "primary",
  }),
  invertDatePosition: generateStringSchema("Invert date position", "Toggle date position to top or bottom of the card", {
    choices: ["top", "bottom"],
    choiceTitles: ["Top", "Bottom"],
    defaultValue: "top",
  })
}));

/*
const imageSchema: JSONSchema4 = {
  type: "object",
  properties: {
    source: {
      type: "string",
      format: "url",
      title: "Thumbnail",
      description: "The thumbnail image.",
    },
    aspectRatio: {
      type: "string",
      title: "Aspect Ratio",
      description:
        "The aspect ratio of the background image. Defaults to 21/4.",
    },
  }
};
const textSchema: JSONSchema4 = {
  type: "object",
  properties: {
    text: {
      type: "string",
      title: "Text",
      description: "The text to display.",
    },
    fontColor: {
      type: "string",
      title: "Font Color",
      description: "The font color for the text.",
    },
    fontSize: {
      type: "string",
      title: "Font Size",
      description: "The font size for the text.",
    },
    fontWeight: {
      type: "string",
      title: "Font Weight",
      description: "The font weight for the text.",
    },
  }
};
const buttonSchema: JSONSchema4 = {
  type: "object",
  properties: {
    backgroundColor: {
      type: "string",
      title: "Background Color",
      description: "The background color for the button.",
    },
    link: {
      type: "string",
      format: "url",
      title: "CTA Link",
      description: "The link for the call-to-action button.",
    },
    // TODO: border width, border color, border radius
  }
};
const textButtonSchema: JSONSchema4 = {
  type: "object",
  properties: {
    ...buttonSchema.properties,
    label: textSchema
  }
};
const imageTextButtonSchema: JSONSchema4 = {
  type: "object",
  properties: {
    ...buttonSchema.properties,
    textLabel: textSchema,
    imageLabel: imageSchema
  }
};
const authorWidgetSchema: JSONSchema4 = {
  type: "object",
  properties: {
    headshot: imageSchema,
    name: textSchema,
    title: textSchema,
  }
};
return {
  type: "object",
  properties: {
    slug: {
      type: "string",
      title: "Slug",
      description:
        "The slug for this media item. This will be used to generate the media item link if an explicit link is not provided.",
    },
    link: {
      type: "string",
      title: "Link",
      description:
        "The link for this media item. The default link will be generated from the slug if this is not provided (and the slug is).",
    },
    publishDateTime: {
      type: "string",
      format: "date-time",
      title: "Publish Date and Time",
      description: "The publish date and time in ISO format.",
    },
    readingTimeDuration: {
      type: "string",
      title: "Reading Time Duration",
      description:
        "The duration of reading time in ISO 8601 duration format.",
    },
    categories: {
      type: "array",
      items: textButtonSchema,
      title: "Categories List",
      description: "Category buttons for the media item.",
    },
    thumbnail: imageSchema,
    header: textSchema,
    competition: imageTextButtonSchema,
    title: textSchema,
    description: textSchema,
    cta: textButtonSchema,
    author: authorWidgetSchema,
  },
};
*/
