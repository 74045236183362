import { injectable } from 'inversify';
import { injectToken, Token } from 'inversify-token';
import { YinzCamAPI, YinzCamAPIRequest, YinzCamAPIRequestComponent, YinzCamAPIResponse, YinzCamAPIToken, YinzCamServer } from 'yinzcam-api';

export const YinzCamSignonServerToken = new Token<YinzCamSignonServer>(Symbol.for("YinzCamSignonServerToken"));

@injectable()
export class YinzCamSignonServer {
  private readonly server: YinzCamServer;

  public constructor(@injectToken(YinzCamAPIToken) private readonly api: YinzCamAPI) {
    this.server = api.getServer('signon');
  }

  public getRequest(request: YinzCamAPIRequest) {
    return this.server.getRequest(request);
  }

  public async postTicket(data: object): Promise<YinzCamAPIResponse> {
    return this.server.singleRequest({ allowAllStatusCodes: true, method: 'POST', path: 'ticket', data });
  }

  public async postRegister(data: object): Promise<YinzCamAPIResponse> {
    return this.server.singleRequest({ allowAllStatusCodes: true, method: 'POST', path: 'register', data });
  }

  public async postPasswordReset(ticket: string, data: object): Promise<YinzCamAPIResponse> {
    return this.server.singleRequest({ allowAllStatusCodes: true, method: 'post', path: `password/reset`, data, headers: { 'X-YinzCam-Ticket': ticket } });
  }

  /* TODO: update api to allow you to either wait for a response (on-shot) or subscribe to updates */
  public async getProfile2UserSegment(segment: string): Promise<YinzCamAPIResponse> {
    return this.server.singleRequest({ method: 'get', path: `profile2/user/segments/${segment}` });
  }

  public getProfile2UserSegmentComponent(segment: string): YinzCamAPIRequestComponent {
    return this.server.getRequest({ path: `profile2/user/segments/${segment}` });
  }

  public async postProfile2UserSegment(segment: string, data: object): Promise<YinzCamAPIResponse> {
    return this.server.singleRequest({ method: 'post', path: `profile2/user/segments/${segment}`, data });
  }

  public async postUploadImageSegment(segment: string, data: FormData): Promise<YinzCamAPIResponse> {
    return this.server.singleRequest({ method: 'post', path: `upload/image/${segment}`, data });
  }

  public getMember(): YinzCamAPIRequestComponent {
    return this.server.getRequest({ path: 'member' });
  }

  public async postUser(ticket: string, data: object): Promise<YinzCamAPIResponse> {
    return this.server.singleRequest({ method: 'post', path: `user`, data, requiredHeaders: [ 'X-YinzCam-Ticket' ] });
  }
}
