import { YinzCamAPIRequestComponent, YinzCamAPIResponse } from 'yinzcam-api';
import { AbstractReactiveMicrocomponent } from 'yinzcam-rma';
import { YinzCamCardsServiceFragment } from './YinzCamCardsServiceTypes';
import * as jp from 'jsonpath';

export class YinzCamCardsServiceFragmentComponent extends AbstractReactiveMicrocomponent<YinzCamCardsServiceFragment, [YinzCamAPIResponse]> {
  private resource: string;

  public constructor(name: string, resource: string, srvInput: YinzCamAPIRequestComponent) {
    super({ name }, srvInput);
    this.resource = resource;
  }

  protected async update($control: unknown, $srvrsp: YinzCamAPIResponse): Promise<YinzCamCardsServiceFragment> {
    if (!$srvrsp) {
      throw new Error('got null data for page response');
    }
    //console.log('FRAGMENT PAGE RESPONSE', $srvrsp.data);
    const result = jp.query($srvrsp.data, this.resource);
    //console.log('FRAGMENT QUERY RESULT', result);
    if (result?.length !== 1) {
      throw new Error(`expected unique result from fragment resource query, got ${result?.length} results`);
    }
    return result[0] as YinzCamCardsServiceFragment;
  }
}
