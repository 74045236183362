import { Token, injectToken } from "inversify-token";
import { ContentfulAPIConfigToken } from "./ContentfulAPIConfig";
import type { ContentfulAPIConfig } from "./ContentfulAPIConfig";
import { injectable } from "inversify";
import type { ContentfulClientApi } from 'contentful';
import { buildYinzCamStandardHostname } from "lib/yinzcam-api/utilities";

export type ContentfulClient = ContentfulClientApi<undefined>;

export const ContentfulClientFactoryToken = new Token<ContentfulClientFactory>(Symbol.for("ContentfulClientFactory"));

export const CONTENT_TYPE_CONTENT_TYPE = 'contentType';

@injectable()
export class ContentfulClientFactory {
  private readonly config: ContentfulAPIConfig;

  private defaultContentfulClient: ContentfulClient = null;

  public constructor(@injectToken(ContentfulAPIConfigToken) config: ContentfulAPIConfig) {
    this.config = config;
  }

  public getDefaultContentfulClient(): ContentfulClient {
    if (this.defaultContentfulClient == null) {
      this.defaultContentfulClient = contentful.createClient({
        accessToken: '__TOKEN__',
        //environment: contentfulConfig.environment,
        space: '__SPACE__',
        application: `${CONFIG.league.toUpperCase()}_${CONFIG.tricode.toUpperCase()}/${CONFIG.version}`,
        integration: `JANUS/1.0.0`, // TODO: put in proper number here
        host: buildYinzCamStandardHostname('contentful'),
        headers: {
          'X-YinzCam-Environment': 'prod', //CONFIG.environment
        }
      });
    }
    return this.defaultContentfulClient;
  }
}
