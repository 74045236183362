import { generateBooleanSchema, generateColorSchema, generateNumberSchema, generateRootObjectSchema, generateStringSchema } from "components/cards/common/schema";
import { BaseParticleSpec, GetParticlePropsType, GetParticleSchemaType, generateParticleContainerSchema } from "components/cards/particles";

export let showHighlight: boolean = false;
export let useThemeAccentColor: boolean = false;
export let useInvertedTheme: boolean = false;
export let useOverlayTheme: boolean = false;

export const TextParticleSpec = new BaseParticleSpec("Text", generateRootObjectSchema({
  container: generateParticleContainerSchema(),
  text: generateStringSchema("Text Contents", "The text contents."),
  family: generateStringSchema("Font Family", "The font family for this text."),
  size: generateStringSchema("Font Size", "The font size for this text."),
  color: generateColorSchema("Text Color", "The color for this text."),
  highlightColor: generateColorSchema("Highlight Color", "The highlight color for this text."),
  weight: generateStringSchema("Font Weight", "The font weight for this text."),
  style: generateStringSchema("Font Style", "The font style for this text.", {
    choices: [ 'normal', 'italic' ],
    choiceTitles: [ "Normal", "Italic" ]
  }),
  decoration: generateStringSchema("Text Decoration", "The decoration to add to this text.", {
    choices: [ 'underline', 'line-through', 'overline' ],
    choiceTitles: [ "Underline", "Strikethrough", "Overline" ]
  }),
  subSuper: generateStringSchema("Superscript/Subscript", "Enable superscript or subscript for this text.", {
    choices: [ 'normal', 'super', 'sub' ],
    choiceTitles: [ "Normal", "Superscript", "Subscript" ]
  }),
  transform: generateStringSchema("Text Transform", "The transformation to apply to this text.", {
    choices: [ 'uppercase', 'lowercase', 'capitalize', 'full-width' ],
    choiceTitles: [ "Uppercase", "Lowercase", "Capitalize", "Fixed Width" ]
  }),
  wrap: generateStringSchema("Text Wrapping", "The wrapping mode for this text.", {
    choices: [ 'wrap', 'balance', 'pretty', 'nowrap' ],
    choiceTitles: [ "Standard", "Balance", "Pretty",  "No Wrapping" ]
  }),
  showHighlight: generateBooleanSchema("Show Highlight", "Show the highlight color underneath the text."),
  useAccentColor: generateBooleanSchema("Use Theme Accent Color", "If the text color is not specified, use the current theme's accent color instead."),
  lineClamp: generateNumberSchema("Maximum Lines", "The maximum number of lines of text to display before truncating with ellipsis."),
  lineHeight: generateStringSchema("Line Height", "Specify line height for the text in standard CSS units.", {
    defaultValue: '1.15'
  }),
  align: generateStringSchema("Text Alignment", "The alignment to apply to this text.", {
    choices: [ 'left', 'center', 'right' ],
    choiceTitles: [ "Left", "Center", "Right" ]
  }),
  autoFit: generateBooleanSchema("Auto-Fit Text", "Automatically adjust the font size to fit the space allocated to the text container.", {
    defaultValue: false
  }),
  
  // TODO:
  // shadow: (text-shadow)
  // emphasis: (text-emphasis)
  // stretch: (font-stretch)
  // spacing: (letter-spacing)
}));

export const specification = TextParticleSpec;

export type TextParticleSchema = GetParticleSchemaType<typeof TextParticleSpec>;

export type TextParticleProps = GetParticlePropsType<typeof TextParticleSpec>;
