import { generateBooleanSchema, generateRootObjectSchema, generateStringSchema, generateUrlSchema, generateDateTimeSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { TextParticleSpec, ImageParticleSpec, ButtonParticleSpec } from "components/cards/particles/YinzCam";

export const specification = new BaseAtomSpec("Tickets Item", generateRootObjectSchema({
    container: generateAtomContainerSchema({ defaultValues: { themeMode: 'overlay' } }),
    overlayPadding: generateStringSchema("Overlay Padding", "The padding (inset) of the overlay text/buttons relative to the background image.", {
        defaultValue: '3rem'
    }),
    publishDateTime: generateDateTimeSchema("Publish Date and Time", "Publish date and time goes here",),
    link: generateUrlSchema("Link", "The link for this media item. The default link will be generated from the slug if this is not provided (and the slug is)."),
    thumbnail: ImageParticleSpec.generateSchema("Thumbnail", "The image thumbnail for the background of the card.", {
        defaultValues: { sizing: 'cover', hoverZoomEnabled: true, role: 'thumbnail' },
    }),
    imageLeague: ImageParticleSpec.generateSchema("League Icon", "The image thumbnail for the background of the card.", {
        defaultValues: { sizing: 'cover' },
    }),
    imageTeamOne: ImageParticleSpec.generateSchema("Team 1-Icon", "The image thumbnail for the background of the card.", {
        defaultValues: { sizing: 'cover', hoverZoomEnabled: true, container: { fillWidth: false } },
    }),
    imageTeamTwo: ImageParticleSpec.generateSchema("Team 2-Icon", "The image thumbnail for the background of the card.", {
        defaultValues: { sizing: 'cover', hoverZoomEnabled: true, container: { fillWidth: false } },
    }),
    imageGradient: generateBooleanSchema("Background Gradient", "Apply gradient to the background image", {
        defaultValue: true
    }),
    shapeBackgroundColor: generateStringSchema("Background color", "Background color of shape"),
    textDateTime: TextParticleSpec.generateSchema("Date & Time", "The text styling for the date and time text.", {
        defaultValues: { size: '1rem', weight: '600', transform: 'capitalize' },
        uneditableFields: { text: true }
    }),
    textTeamOne: TextParticleSpec.generateSchema("Team 1-Details", "Details of team one", {
        defaultValues: {
            color: 'var(--Primary- Tint - Text - Color, #FFF)',
            size: '1.25rem',
            style: 'normal',
            weight: '900',
            transform: 'capitalize',
        }
    }),
    textTeamTwo: TextParticleSpec.generateSchema("Team 2-Details", "Details of team two", {
        defaultValues: {
            color: 'var(--Primary- Tint - Text - Color, #FFF)',
            size: '1.25rem',
            style: 'normal',
            weight: '900',
            transform: 'capitalize',
        }
    }),
    ctaButtonOne: ButtonParticleSpec.generateSchema("CTA Button One", "The CTA button.", {
        defaultValues: {
            showImage: false, container: {
                themeMode: 'primary', background: { visible: true, useHighlightColor: true }, border: { visible: true, radius: '0.125rem' },
                padding: '0.75rem 2.25rem',
                fillWidth: false,
            }, text: { size: '1rem', weight: '600' }, gap: '0.5rem'
        }
    }),
    ctaButtonTwo: ButtonParticleSpec.generateSchema("CTA Button Two", "The CTA button.", {
        defaultValues: {
            showImage: false, container: {
                themeMode: 'primary', border: { visible: true, size: '1px', style: 'solid', radius: '0.125rem', color: 'var(--theme-mode-highlight-color)' },
                padding: '0.75rem 2.25rem',
                fillWidth: false,
            }, text: { size: '1rem', weight: '600', color: 'var(--theme-mode-highlight-color)' }, gap: '0.5rem'
        }
    }),
    shouldDisplayBar: generateBooleanSchema("Should display bar over buttons? ", "Select if bar should be displayed over CTA buttons", {
        defaultValue: false
    })
}));

