<script context="module" lang="ts">export { specification } from "./spec";
</script>
  
<script lang="ts">import { onMount } from 'svelte';
import BaseAtom from 'components/cards/atoms/BaseAtom.svelte';
import { getContentManager } from 'components/cards/atoms';
import { VideoParticle } from 'components/cards/particles/YinzCam';
import TextParticle from 'components/cards/particles/YinzCam/Core/Text/TextParticle.svelte';
import { formatDateTimeLocal } from "components/cards/utilities";
import { DateTime } from "luxon";
export let container = undefined;
export let slug = undefined;
export let link = undefined;
export let publishDateTime = undefined;
export let titleText = undefined;
//export let descriptionText: TextParticleProps = undefined;
export let dateTimeText = undefined;
export let video = undefined;
export let showInfoBox = undefined;
$: mtc = getContentManager().getMediaTypeConfigurationStore('gallery', slug, link);
//$: shareLink = $mtc?.shareLinkUrl?.href;
$: publishDateTimeDisplay = formatDateTimeLocal(publishDateTime, DateTime.DATE_HUGE);
let videoEl;
onMount(() => {
    return () => {
        if (videoEl) {
            videoEl.pause();
            videoEl.currentTime = 0;
        }
    };
});
</script>

<style>
  div.outer {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  div.player {
    max-width: 100%;
  }

  div.info-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1.75rem 2.5rem;
    gap: 0.5rem;
  }

  /*
  div.info-box-title {
  }

  div.info-box-date {
  }
  */</style>
  
<BaseAtom _isotope="VideoPlayerAtom" {...container}>
  <div class="outer">
    <div class="player">
      <VideoParticle {...video} />
    </div>
    {#if showInfoBox}
    <div class="info-box">
      <div class="info-box-title">
        <TextParticle {...titleText} />
      </div>
      <div class="info-box-date">
        <TextParticle {...dateTimeText} text={publishDateTimeDisplay} />
      </div>
    </div>
    {/if}
  </div>
</BaseAtom>
