<script lang="ts">import { BaseParticle } from "components/cards/particles";
import { View } from "framework7-svelte";
import { resolveUrl } from "js/url";
// props
export let container = undefined;
export let slug = undefined;
export let pathParams = [];
$: path = resolveUrl(slug).href + (((pathParams === null || pathParams === void 0 ? void 0 : pathParams.length) > 0) ? '/' + pathParams.join('/') : '');
//$: console.log('PAGE PARTICLE PATH', slug, pathParams, resolveUrl(slug).href, path);
</script>

<style>
  .page-particle-view-container {
    width: 100%;
    height: 100%;
  }

  .page-particle-view-container :global(.view) {
    width: 100% !important;
    /*position: static !important;*/
    overflow: visible !important;
  }

  .page-particle-view-container :global(.view) {
    height: auto !important;
  }

  .page-particle-view-container :global(.page) {
    height: auto !important;
  }

  .page-particle-view-container :global(.page-content) {
    height: auto !important;
  }

  .page-particle-view-container :global(.cards-page-top-flex) {
    height: auto !important;
  }

  .page-particle-view-container :global(.cards-page-left-flex) {
    height: auto !important;
  }

  .page-particle-view-container :global(.cards-page-content-area) {
    height: auto !important;
  }

  .page-particle-view-container :global(.cards-page-left-flex) {
    flex: unset !important;
  }</style>

<BaseParticle _name="PageParticle" _cssVariablePrefix="page-particle" {...container}>
  <div class="page-particle-view-container">
    <View url={path} linksView=".view.view-main" />
  </div>
</BaseParticle>
