<script context="module" lang="ts">var _a;
export { specification } from "./spec";
</script>

<script lang="ts">var _a;
import { Swiper, SwiperSlide } from 'swiper/svelte';
import 'swiper/swiper.scss';
import BaseAtom from 'components/cards/atoms/BaseAtom.svelte';
import { getContentManager } from 'components/cards/atoms';
import { ShareStripParticle } from 'components/cards/particles/YinzCam';
import ImageParticle from 'components/cards/particles/YinzCam/Core/Image/ImageParticle.svelte';
import TextParticle from 'components/cards/particles/YinzCam/Core/Text/TextParticle.svelte';
import ButtonParticle from "components/cards/particles/YinzCam/Core/Button/ButtonParticle.svelte";
export let container = undefined;
export let slug = undefined;
export let link = undefined;
export let carouselPositionText = undefined;
export let photoTitleText = undefined;
export let photoDescriptionText = undefined;
export let socialLinks = undefined;
export let currentPhoto = undefined;
export let carouselPhoto = undefined;
export let photos = undefined;
$: mtc = getContentManager().getMediaTypeConfigurationStore('gallery', slug, link);
$: shareLink = (_a = $mtc === null || $mtc === void 0 ? void 0 : $mtc.shareLinkUrl) === null || _a === void 0 ? void 0 : _a.href;
let curPhotoUrl = '';
let curPhotoTitle = '';
let curPhotoDesc = '';
let curPhotoStatusText = '';
let bottomSwiper;
$: if ((bottomSwiper === null || bottomSwiper === void 0 ? void 0 : bottomSwiper.swiper()) && (photos === null || photos === void 0 ? void 0 : photos.length) > 0) {
    const url = new URL(location.href);
    const currentPhotoUrl = url.searchParams.get('currentPhotoUrl');
    const currentPhotoIndex = Math.max((photos === null || photos === void 0 ? void 0 : photos.findIndex(e => e.source === currentPhotoUrl)) || 0, 0);
    console.log("swiper no: ", currentPhotoIndex);
    bottomSwiper === null || bottomSwiper === void 0 ? void 0 : bottomSwiper.swiper().slideTo(currentPhotoIndex);
    updatePhotoInfo(currentPhotoIndex);
}
function onSlideChange(e) {
    const index = e.detail[0][0].activeIndex;
    updatePhotoInfo(index);
    console.log("slide change: ", index);
}
function updatePhotoInfo(index) {
    const photo = photos === null || photos === void 0 ? void 0 : photos[index];
    curPhotoUrl = (photo === null || photo === void 0 ? void 0 : photo.source) || '';
    curPhotoTitle = (photo === null || photo === void 0 ? void 0 : photo.title) || '';
    curPhotoDesc = (photo === null || photo === void 0 ? void 0 : photo.description) || '';
    curPhotoStatusText = `Photo ${index + 1}/${photos === null || photos === void 0 ? void 0 : photos.length}`;
}
function onLeftClick() {
    if (bottomSwiper === null || bottomSwiper === void 0 ? void 0 : bottomSwiper.swiper()) {
        bottomSwiper === null || bottomSwiper === void 0 ? void 0 : bottomSwiper.swiper().slidePrev();
    }
}
function onRightClick() {
    if (bottomSwiper === null || bottomSwiper === void 0 ? void 0 : bottomSwiper.swiper()) {
        bottomSwiper === null || bottomSwiper === void 0 ? void 0 : bottomSwiper.swiper().slideNext();
    }
}
</script>

<BaseAtom _isotope="PhotoViewerAtom" {...container}>
  <div class="photo-viewer">
    <div class="selected-image-row">
      <div class="left-arrow">
        <ButtonParticle on:click={onLeftClick} container={{ background: { visible: true, color: '#B3B3B3' }, border: { visible: true, radius: '50%' }}} image={{ icon: "yc_icon_chevron_left", container: { width: '2.5rem', height: '2.5rem' }, fillHeight: true, fillWidth: true }} />
      </div>
      <div class="selected-image">
        <ImageParticle _block={true} {...currentPhoto} source={curPhotoUrl} />
      </div>  
      <div class="right-arrow">
        <ButtonParticle on:click={onRightClick} container={{ background: { visible: true, color: '#B3B3B3' }, border: { visible: true, radius: '50%' }}} image={{ icon: "yc_icon_chevron_right", container: { width: '2.5rem', height: '2.5rem' }, fillHeight: true, fillWidth: true }} />
      </div>
    </div>
    <div class="photo-info-row">
      <div class="photo-status">
        <TextParticle {...carouselPositionText} text={curPhotoStatusText} />
      </div>
      <div class="photo-title-description">
        <div class="photo-title">
          <TextParticle {...photoTitleText} text={curPhotoTitle} />
        </div>
        <div class="photo-description">
          <TextParticle {...photoDescriptionText} text={curPhotoDesc} />
        </div>
      </div>
      <div class="photo-share-links">
        <ShareStripParticle {...socialLinks} shareLink={shareLink || ''} shareText={curPhotoTitle || ''} />
      </div>
    </div>
    <div class="bottom-swiper-wrapper">
      <Swiper
        class="bottom-slider"
        bind:this={bottomSwiper}
        on:slideChange={onSlideChange}
        pagination={false}
        navigation={false}
        speed={400}
        slidesPerView={'auto'}
        spaceBetween={12}
        observer={true}
        observeSlideChildren={false}
        slideToClickedSlide={true}
        centeredSlides={true}
        centerInsufficientSlides={true}
        grabCursor={true}
      >
        {#each (photos || []) as photo, i}
          <SwiperSlide>
            <ImageParticle _block={true} {...carouselPhoto} source={photo.source} alt={`Photo ${i + 1}`} />
          </SwiperSlide>
        {/each}
      </Swiper>
    </div>
  </div>
</BaseAtom>

<style>
  .photo-viewer {
    width: 100%;
  }

  .photo-viewer > .selected-image-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3rem;
  }

  .photo-viewer > .selected-image-row > .left-arrow, .photo-viewer > .selected-image-row > .right-arrow {
    flex: 0;
  }

  .photo-viewer > .selected-image-row > .selected-image {
    flex: 1;
    height: 100%;
  }

  .photo-viewer > .photo-info-row {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    padding: 1.75rem 5.5rem;
  }

  .photo-viewer > .photo-info-row > .photo-status {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
  }

  .photo-viewer > .photo-info-row > .photo-title-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
  }

  .photo-viewer > .photo-info-row > .photo-share-links {
    margin-left: auto;
  }

  .photo-viewer > .bottom-swiper-wrapper {
    padding: 0 5.5rem;
  }

  .photo-viewer > .bottom-swiper-wrapper :global(.swiper-slide) {
    flex-shrink: 1;
  }</style>