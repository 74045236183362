<Boundary>
  <CardsComponentWrapper style="
    {buildCssPadding(padding)}
    {buildCssMargin(margin)}
    {buildCssBackground(section.background)}
    {buildCssBorder(section.border)}
    {buildCssFont(section.font)}
    {buildCssScrollContainer(height)}
    {(hidden)? 'display: none;' : ''}
    {(zoom)? `zoom: ${zoom};` : ''}
  " {editorOptions}>
    {#if !hidden}
    <CardsColumns parentSequenceId={sequenceId} columns={section.columns} updater={(columns) => section.columns = columns} />
    {/if}
  </CardsComponentWrapper>
</Boundary>

<style>
  *, *::before, *::after {
    box-sizing: border-box;
  }</style>

<script lang="ts">var _a, _b, _c;
import "yinzcam-cards";
import { buildCssBackground, buildCssBorder, buildCssFont, buildCssMargin, buildCssPadding, buildCssScrollContainer } from "../utilities/index";
import CardsColumns from "./CardsColumns.svelte";
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
import _ from "lodash";
import CardsComponentWrapper from './CardsComponentWrapper.svelte';
import "./CardsEditorInterfaces";
import { CARDS_SECTION_SCHEMA } from "./CardsEditorComponentSchemata";
import { windowMetrics as wm } from '../../../js/stores';
export let parentSequenceId = null;
export let sequenceId = null;
export let section;
export let updater;
let hidden, height, zoom, padding, margin;
$: if (section && ($wm === null || $wm === void 0 ? void 0 : $wm.width)) {
    const clone = _.cloneDeep(section);
    // Apply responsive properties
    //console.log('CHECKING RESPONSIVE CONFIGURATION', currentLayout?.responsiveness);
    const responsiveConfiguration = (_c = (_b = (_a = clone.responsiveness) === null || _a === void 0 ? void 0 : _a.filter((i) => i.maxWidth >= 0)) === null || _b === void 0 ? void 0 : _b.sort((a, b) => a.maxWidth - b.maxWidth)) === null || _c === void 0 ? void 0 : _c.find((i) => i.maxWidth >= $wm.width);
    if (responsiveConfiguration) {
        //console.log('COLUMN FOUND RESPONSIVE CONFIGURATION', responsiveConfiguration);
        for (const k in responsiveConfiguration) {
            if (k === 'maxWidth') {
                continue;
            }
            const val = responsiveConfiguration[k];
            if (!_.isNil(val)) {
                clone[k] = val;
            }
        }
    }
    hidden = !_.isNil(clone.hidden) && clone.hidden;
    height = clone.height;
    zoom = clone.zoom;
    padding = clone.padding;
    margin = clone.margin;
}
else {
    hidden = true, height = undefined, zoom = undefined, padding = undefined, margin = undefined;
}
let editorOptions;
$: editorOptions = {
    parentSequenceId,
    sequenceId,
    componentId: section.id,
    component: section,
    componentTypeName: 'Section',
    childTypeNames: ['Column'],
    primaryColor: '#19DEC6',
    reverseTextColor: 'black',
    unselectedBackgroundColor: '#8C959E',
    unselectedTextColor: '#FFFFFF',
    selectedBackgroundColor: '#FFDA53',
    selectedTextColor: '#202020',
    childrenKey: 'columns',
    configSpec: CARDS_SECTION_SCHEMA,
    allowChildren: true,
    allowDeletion: true,
    allowDuplication: true,
    allowMovement: true,
    allowCopyPaste: true,
    updater
};
</script>
