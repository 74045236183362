<script context="module" lang="ts">var _a;
export { specification } from "./spec";
</script>

<script lang="ts">var _a;
import { get } from "lodash";
import { formatDateTimeLocal, formatDateTimeRelative, } from "components/cards/utilities";
import { BaseAtom, getContentManager, } from "components/cards/atoms";
import { TextParticle, ImageParticle, } from "components/cards/particles/YinzCam";
import { writable } from "svelte/store";
import "js/url";
import { DateTime } from "luxon";
const hovering = writable(false);
export let container;
export let overlayPadding;
export let mediaType;
export let slug;
export let link;
export let publishDateTime;
export let viewTimeDuration;
export let thumbnail;
export let titleText;
export let descriptionText;
export let txtDateTime;
export let imgClockIcon;
export let imgShareIcon;
export let shouldDisplayBodyText;
let atomWidthRem;
$: mtc = getContentManager().getMediaTypeConfigurationStore(mediaType, slug, link);
$: resolvedLink = (_a = $mtc === null || $mtc === void 0 ? void 0 : $mtc.linkUrl) === null || _a === void 0 ? void 0 : _a.href;
$: mediaTypeIcon = $mtc === null || $mtc === void 0 ? void 0 : $mtc.icon;
$: isTablet = atomWidthRem <= 53 && atomWidthRem > 20;
$: isMobile = atomWidthRem <= 20;
$: txtDurationValue = formatDateTimeRelative(viewTimeDuration);
$: publishDateTimeDisplay = formatDateTimeLocal(publishDateTime, DateTime.DATE_HUGE);
$: titleTextMultiplier = isMobile ? "0.6" : isTablet ? "0.7" : "1.00";
</script>

<BaseAtom
    bind:_clientWidthRem={atomWidthRem}
    _isotope="RegularHorizontalAtom"
    _cssVariablePrefix="regular-horizontal"
    _link={resolvedLink}
    {...container}
    {overlayPadding}
>
    <div
        class="main-container"
        class:layout-mobile-width={isMobile}
        class:layout-tablet-width={isTablet}
    >
        <div class="image-container">
            <ImageParticle {...thumbnail} _block={true}/>
        </div>
        <div class="text-container">
            <div class="title-description">
                <TextParticle
                    {...titleText}
                    size={`calc(${get(titleText, "size")}*${titleTextMultiplier})`}
                />
                {#if shouldDisplayBodyText}
                    <TextParticle {...descriptionText} />
                {/if}
            </div>
            <div class="description-footer">
                <div class="footer-content">
                    <div class="clock-duration">
                        <ImageParticle {...imgClockIcon} />
                        <TextParticle
                            {...txtDateTime}
                            text={publishDateTimeDisplay}
                        />
                    </div>
                    <ImageParticle {...imgShareIcon} />
                </div>
            </div>
        </div>
    </div>
</BaseAtom>

<style>
    .main-container {
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        padding: var(--regular-horizontal-overlay-padding, unset);
    }

    .image-container {
        flex: 1 1 max-content;
    }

    .text-container {
        display: flex;
        flex: 1 1 50%;
        flex-direction: column;
        justify-content: space-between;
        gap: 1rem;
    }
    .title-description {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .footer-content {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        flex-basis: max-content;
    }

    .clock-duration {
        display: flex;
        justify-content: end;
        align-items: center;
    }</style>
