import { generateArraySchema, generateBooleanSchema, generateColorSchema, generateObjectSchema, generateRootObjectSchema, generateStringSchema, generateUrlSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { TextParticleSpec, ImageParticleSpec, ButtonParticleSpec } from "components/cards/particles/YinzCam";

export const specification = new BaseAtomSpec("Dropdown Llist", generateRootObjectSchema({
    container: generateAtomContainerSchema({
        defaultValues: { themeMode: 'primary' },
        uneditableFields: { background: false }
    }),
    overlayPadding: generateStringSchema("Overlay Padding", "The padding (inset) of the overlay text/buttons relative to the background image.", {
        defaultValue: '4.31rem 2.25rem'
    }),
    txtListTitle: TextParticleSpec.generateSchema("List Title", "Enter a title for the list", {
        defaultValues: { size: "1rem", weight: "600" }
    }),
    imgChevron: ImageParticleSpec.generateSchema("Click Image", "Upload image/icon for click button", {
        defaultValues: {
            container: {
                width: "2.25rem", height: "2.25rem",
                border: { visible: true, style: "solid", width: "1px", radius: "0.375rem" }
            }
        }
    }),
    txtOptions: generateArraySchema("List Options", "Enter the list options",
        TextParticleSpec.generateSchema("List Option", "Enter the list option", {
            defaultValues: { size: "1rem", weight: "600" }
        })
    ),
    colorBorder: generateColorSchema("Border Color", "Pick a color for border")
}));
