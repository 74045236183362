import { generateArraySchema, generateBooleanSchema, generateColorSchema, generateDateTimeSchema, generateDurationSchema, generateNumberSchema, generateObjectSchema, generateRootObjectSchema, generateStringSchema, generateUrlSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { ButtonParticleSpec, ImageParticleSpec, TextParticleSpec } from "components/cards/particles/YinzCam";

export const specification = new BaseAtomSpec("Navigation Menu (Style A)", generateRootObjectSchema({
  container: generateAtomContainerSchema({
    defaultValues: { themeMode: 'inverted', background: { visible: true } }
  }),
  menuTitleShowChevron: generateBooleanSchema("Show Chevron", "For menu titles with submenu items, show a chevron to the right of the title."),
  menuTitleShowActiveBackground: generateBooleanSchema("Show Active Background on Hover", "For menu titles, show the active background color on hover."),
  menuTitleActiveBackgroundColor: generateColorSchema("Background Color on Hover", "The hover background color for menu titles."),
  menuTitleShowActiveUnderline: generateBooleanSchema("Show Underline on Hover", "For menu titles, show an underline on hover."),
  menuTitleActiveUnderlineColor: generateColorSchema("Underline Color on Hover", "The hover underline color for menu titles."),
  logoButton: ButtonParticleSpec.generateSchema("Logo Button", "The configuration for the logo button on the left side, spanning both rows.", {
    defaultValues: {
      showText: false,
      showImage: true,
      link: 'home',
      image: { sizing: 'contain', container: { height: '10rem', fillWidth: false }, fillWidth: false }
    }
  }),
  topButtons: generateArraySchema("Top Buttons", "The top-row buttons.",
    ButtonParticleSpec.generateSchema("Top Button", "A top-row button.", {
      defaultValues: { 
        showImage: false,
        image: { container: { height: '2.25rem', width: '2.25rem' } },
        text: { size: '1rem', weight: '600', wrap: 'nowrap' }
      },
      uneditableFields: { showImage: true, showText: true }
    })
  ),
  sponsorButton: ButtonParticleSpec.generateSchema("Sponsor Button", "The configuration for the sponsor button on the right side of the top row.", {
    defaultValues: {
      container: { padding: '1rem 0'},
      image: { sizing: 'contain', container: { height: '2rem', fillWidth: false }, fillWidth: false },
      text: { text: "Main Club Sponsor", size: '0.875rem', weight: '500', wrap: 'nowrap' },
      orientation: 'column',
      imagePosition: 'after',
      alignment: 'end',
      showText: true,
      showImage: true,
      gap: '0.5rem'
    }
  }),
  items: generateArraySchema("Items", "The array of top-level navigation-menu items.", 
    generateObjectSchema("Item", "A top-level navigation-menu item.", {}, { additionalProperties: true })
  ),
}));
