<Boundary>
  <div class="yinzcam-ad-slot" class:display-none={!loadedAd}>
    {#if loadedAd}
      <YCLink resolved={clickthroughUrl}>
        <img src={imageUrl} alt="">
      </YCLink>
    {/if}
  </div>
</Boundary>

<style>
  *, *::after, *::before {
    box-sizing: border-box;
  }
  .yinzcam-ad-slot {
    width: 100%;
    text-align: center;
  }</style>

<script lang="ts">var _a;
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
import "yinzcam-cards";
import { writable } from "svelte/store";
//import { CardsDataSourceRegistration } from "../../common/CardsDataSourceRegistration";
//import { getFirstSourceStore } from '../../utilities';
import ROOT from '../../../../../../inversify.config';
import { getToken } from 'inversify-token';
import { YinzCamAdsServerToken } from 'yinzcam-ads';
import 'yinzcam-api';
import _ from 'lodash';
import YCLink from '../../../../utilities/YCLink.svelte';
import { resolveUrl } from '../../../../../../js/url';
export let element;
//export let sources: Readable<CardsDataSourceRegistration[]>;
//export const defaultSourceClasses = [ 'YinzCamAdsServerBannerAds' ]
//export let sourceStore: Readable<any>
const adsResponseStore = ((_a = element === null || element === void 0 ? void 0 : element.data) === null || _a === void 0 ? void 0 : _a.slotId) ?
    getToken(ROOT, YinzCamAdsServerToken).getAds(element.data.slotId, null, element.data.formFactor || null).store :
    writable(null);
let loadedAd = false;
let imageUrl = "#";
let clickthroughUrl = undefined;
function loadAds(rsp) {
    const elData = element.data;
    if (rsp.isStatusNot2xx) {
        console.error(`YinzCamAdSlot: unable to load ads data for major=${elData.slotId} ff=${elData.formFactor} -- status code ${rsp.status}`);
        return;
    }
    const rspData = rsp === null || rsp === void 0 ? void 0 : rsp.data;
    const ads = rspData === null || rspData === void 0 ? void 0 : rspData.ads;
    if (!_.isArray(ads)) {
        console.error(`YinzCamAdSlot: unable to load ads data for major=${elData.slotId} ff=${elData.formFactor} -- ads array not found`);
        return;
    }
    // pick a random ad and show it!
    const ad = _.sample(ads);
    imageUrl = ad.image_url;
    clickthroughUrl = resolveUrl(ad.clickthrough_url);
    loadedAd = true;
}
$: if ($adsResponseStore && !loadedAd) {
    //console.log('ADS DATA!!!', $adsData);
    loadAds($adsResponseStore);
}
// TODO: Support data sources
//$: { sourceStore = getFirstSourceStore($sources) }
//$: data = sourceStore ? $sourceStore : {}
// $: console.log('YinzCamAdSlot Data', data);
</script>
