import { generateRootObjectSchema, generateArraySchema, generateObjectSchema, generateColorSchema, generateStringSchema } from "components/cards/common/schema";
import { BaseAtomSpec, generateAtomContainerSchema } from "components/cards/atoms/BaseAtomSpec";
import { ButtonParticleSpec, TextParticleSpec } from "components/cards/particles/YinzCam";

import { statsNameList } from "components/cards/utilities/constants";

export const specification = new BaseAtomSpec("Stat Number Cards", generateRootObjectSchema({
    container: generateAtomContainerSchema(),
    statsList: generateArraySchema("List of Stats", "List of stats", generateStringSchema("Select Stats to display", "Select Stats to display", {
        choices: statsNameList,
        choiceTitles: statsNameList,
    })),
    borderRadius: generateStringSchema("Border Radius", "Enter a border radius"),
    colorTabBorder: generateColorSchema("Tab border color", "Pick a color for tab background"),
    colorContainerBG: generateColorSchema("Background color", "Container background color"),
    colorStatBackground: generateColorSchema("Tab Background", "Tab background color"),
    playerStats: generateArraySchema("-N/A-", "Specify the number of cards to be created",
        generateObjectSchema("Stat Number Card", "Enter Stat Name and Value", {
            name: TextParticleSpec.generateSchema("Stat Name", "Enter stat name", {
                defaultValues: { size: "1.25rem", weight: "600", align: 'center' }
            }),
            homeValue: TextParticleSpec.generateSchema("Stat Value", "Enter stat value", {
                defaultValues: { size: "2.5rem", weight: "900", align: 'center' }
            }),
            highlightBox: ButtonParticleSpec.generateSchema("Highlight Card", "Highlight the card", {
                defaultValues: {
                    container: {
                        background: { visible: true }, width: "1.625rem", height: "2.25rem",
                        border: { visible: true, radius: "0.375rem" }
                    }
                }
            }),
            highlightColor: generateColorSchema("Highlight Box Color", "Pick a color for highlighting stat"),
        }))
}));
