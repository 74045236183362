import { injectable } from 'inversify';
import { injectToken, Token } from 'inversify-token';
import { YinzCamAPI, YinzCamAPIRequestComponent, YinzCamAPIToken, YinzCamServer } from 'yinzcam-api';

export const YinzCamSegmentationServerToken = new Token<YinzCamSegmentationServer>(Symbol.for("YinzCamSegmentationServerToken"));

@injectable()
export class YinzCamSegmentationServer {
  private readonly server: YinzCamServer;

  public constructor(@injectToken(YinzCamAPIToken) private readonly api: YinzCamAPI) {
    this.server = api.getServer('segmentation');
  }

  public getSegmentationToken(): YinzCamAPIRequestComponent {
    return this.server.getRequest({ path: 'segmentation/token' });
  }
}
