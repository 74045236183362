import { YinzCamAPIRequestComponent, YinzCamAPIResponse } from 'yinzcam-api';
import { AbstractReactiveMicrocomponent } from 'yinzcam-rma';

export class YinzCamCardsServiceMessagesComponent extends AbstractReactiveMicrocomponent<{[key: string]: string}, [YinzCamAPIResponse]> {
  public constructor(name: string, srvInput: YinzCamAPIRequestComponent) {
    super({ name }, srvInput);
  }

  protected async update($control: unknown, $srvrsp: YinzCamAPIResponse): Promise<{[key: string]: string}> {
    if (!$srvrsp?.data) {
      throw Error('YinzCamCardsServiceMessagesComponent: messages data not available');
    }
    return $srvrsp.data as {[key: string]: string};
  }
}
