import _ from 'lodash';
import ROOT from '../../../inversify.config';
import { CardsLibraryToken } from "./CardsLibraryToken";
import { getAllToken, getNamed } from 'inversify-token';
import { CardsElementDataSourceSpec } from './CardsElementInterfaces';
import { YinzCamCardsServiceSource } from 'yinzcam-cards';
import { CardsDataSourceToken } from './CardsDataSourceToken';
import { CardsDataSource } from './CardsDataSource';
import { it } from 'date-fns/locale';
import { BaseAtomSpec } from '../atoms';

export function generateElementDataSourcesSchema(spec: CardsElementDataSourceSpec, sources: YinzCamCardsServiceSource[]) {
  if (!spec?.sources?.length) {
    return undefined;
  }
  return {
    "title": "Data Sources",
    "type": "array",
    "description": "Configure the data sources used by this element, if required. Data sources can be defined on the view containing this element.",
    "minItems": spec.sources.length,
    "maxItems": spec.sources.length,
    "items": spec.sources.map(s => {
      const allowedSources = (s.classes === '*')? sources : sources.filter(t => s.classes.includes(t.class));
      return {
        "type": "string",
        "title": s.title,
        "headerTemplate": s.title,
        "description": s.description,
        "enum": allowedSources.map(t => t.id),
        "options": {
          "enum_titles": allowedSources.map(t => t.name)
        }
      };
    })
  };
}

const repeat = {
  "title": "Repeat",
  "description": "The number of times this component is repeated in order.",
  "type": "integer",
  "minimum": 1
};

const padding = {
  "title": "Padding",
  "type": "object",
  "format": "normal",
  "properties": {
    "top": {
      "type": "string",
      "title": "Top",
      "description": "Top padding in standard CSS units."
    },
    "right": {
      "type": "string",
      "title": "Right",
      "description": "Right padding in standard CSS units."
    },
    "bottom": {
      "type": "string",
      "title": "Bottom",
      "description": "Bottom padding in standard CSS units."
    },
    "left": {
      "type": "string",
      "title": "Left",
      "description": "Left padding in standard CSS units."
    }
  }
};

const margin = {
  "title": "Margin",
  "type": "object",
  "format": "normal",
  "properties": {
    "top": {
      "type": "string",
      "title": "Top",
      "description": "Top margin in standard CSS units. Negative values are allowed."
    },
    "right": {
      "type": "string",
      "title": "Right",
      "description": "Right margin in standard CSS units. Negative values are allowed."
    },
    "bottom": {
      "type": "string",
      "title": "Bottom",
      "description": "Bottom margin in standard CSS units. Negative values are allowed."
    },
    "left": {
      "type": "string",
      "title": "Left",
      "description": "Left margin in standard CSS units. Negative values are allowed."
    }
  }
};

const border = {
  "title": "Border",
  "type": "object",
  "format": "normal",
  "properties": {
    "top": {
      "type": "string",
      "title": "Top",
      "description": "Top border in standard CSS units."
    },
    "right": {
      "type": "string",
      "title": "Right",
      "description": "Right border in standard CSS units."
    },
    "bottom": {
      "type": "string",
      "title": "Bottom",
      "description": "Bottom border in standard CSS units."
    },
    "left": {
      "type": "string",
      "title": "Left",
      "description": "Left border in standard CSS units."
    },
    "radius": {
      "type": "string",
      "title": "Radius",
      "description": "Border radius in standard CSS units."
    }
  }
};

const background = {
  "title": "Background",
  "type": "object",
  "format": "normal",
  "properties": {
    "color": {
      "title": "Color",
      "description": "A solid background color. Will be overridden if an image is specified.",
      "type": "string",
      "format": "color"
    },
    "url": {
      "type": "string",
      "format": "url",
      "title": "Image",
      "description": "The image for the background. This takes precedence over color.",
      "options": { "upload": {} },
      "links": [ { "href": "{{self}}" } ]
    },
    "size": {
      "type": "string",
      "title": "Size",
      "description": "Cover: The image will be resized to fill the area and cropped to maintain aspect ratio. Contain: The image will be scaled up or down so that the entire image fits within the area, maintaining aspect ratio.",
      "enum": [
        "COVER",
        "CONTAIN"
      ],
      "options": {
        "enum_titles": [
          "Cover",
          "Contain"
        ]
      }
    },
    "attachment": {
      "type": "string",
      "title": "Attachment",
      "description": "Fixed: The image will remain always in the same position on the screen. Local: The background image will scroll with the content. Scroll: The background image will scroll with the content's container.",
      "enum": [
        "FIXED",
        "LOCAL",
        "SCROLL"
      ],
      "options": {
        "enum_titles": [
          "Fixed",
          "Local",
          "Scroll"
        ]
      }
    },
    "position": {
      "type": "string",
      "title": "Position",
      "description": "The anchor point of the background for cropping. A combination of (top, center, bottom) and (left, center, right). For example, use \"center center\" to center crop.",
    },
    "repeat": {
      "type": "string",
      "title": "Repeat",
      "description": "Repeat: Enables repeating the background to cover the target area. No Repeat: Disables repeating the background (default).",
      "enum": [
        "REPEAT",
        "REPEAT-X",
        "REPEAT-Y",
        "NO-REPEAT",
        "SPACE",
        "ROUND"
      ],
      "options": {
        "enum_titles": [
          "Repeat",
          "Repeat left-to-right only",
          "Repeat top-to-bottom only",
          "No Repeat",
          "Space",
          "Round"
        ]
      }
    }
  }
};

const conditions = {
  "title": "Conditions",
  "type": "object",
  "format": "normal",
  "properties": {
    "isNative": {
      "type": "boolean",
      "title": "App Only?",
      "description": "Only show this component when displayed within a native mobile app."
    },
    "isDesktop": {
      "type": "boolean",
      "title": "Web Only?",
      "description": "Only show this component when displayed on a website inside a browser."
    },
    "not": {
      "type": "boolean",
      "title": "Invert Logic",
      "description": "Show this component when these conditions are NOT met."
    }
  }
};

export const CARDS_ELEMENT_SCHEMA = {
  "title": " ",
  "description": " ",
  "type": "object",
  "properties": {
    "class": {
      "type": "string",
      "title": "Element Type",
      "description": "The type of element to place in this position.",
      "enum": [
      ],
      "options": {
        "enum_titles": [
        ]
      }
    },
    repeat,
    "height": {
      "type": "string",
      "title": "Fixed Height",
      "description": "Specifies a fixed height for the element in standard CSS units."
    },
    "width": {
      "type": "string",
      "title": "Fixed Width",
      "description": "Specifies a fixed width for the element in standard CSS units."
    },
    "transform": {
      "type": "string",
      "title": "Data Source Transform",
      "description": "Specifies data source transformation function to apply.",
      "enum": [
        "",
        "contentful-article-header",
        "contentful-article-body",
        "contentful-gallery-viewer",
        "contentful-media",
        "contentful-media-header",
        "contentful-navigation",
        "contentful-page-accordion",
        "contentful-page-article",
        "contentful-page-image",
        "contentful-page-metadata",
        "contentful-page-cta",
        "contentful-pages-as-buttons",
        "contentful-search-metadata",
        "contentful-video-viewer",
        "contentful-site-navigation",
        "yinzcam-game-box",
        "yinzcam-game-list",
        "yinzcam-game-scores",
        "yinzcam-stats-standings",
        "yinzcam-stats-team",
        "yinzcam-stats-player",
        "yinzcam-stats-player-action-shot",
        "yinzcam-team-roster"
      ],
      "options": {
        "enum_titles": [
          "None",
          "Contentful Article Header",
          "Contentful Article Body",
          "Contentful Gallery Viewer",
          "Contentful Media",
          "Contentful Media Header",
          "Contentful Navigation",
          "Contentful Page Accordion",
          "Contentful Page Article",
          "Contentful Page Image",
          "Contentful Page Metadata",
          "Contentful Page Call to Action",
          "Contentful Pages as Buttons",
          "Contentful Search Metadata",
          "Contentful Video Viewer",
          "Contentful Site Navigation",
          "YinzCam Game Box",
          "YinzCam Game List",
          "YinzCam Game Scores",
          "YinzCam Stats Standings",
          "YinzCam Stats Team",
          "Yinzcam Stats Player",
          "Yinzcam Stats Player Action Shot",
          "Yinzcam Team Roster",
        ]
      }
    },
    "data": {
      "title": "Type-Specific Data",
      "type": "object",
      "description": "Additional data specific to the element type.",
      "properties": {
      },
      "additionalProperties": true
    },
    "span": {
      "title": "Grid",
      "type": "object",
      "format": "normal",
      "description": "When placed in a Grid array, specifies how many rows or columns this element should span. Defaults to 1 for both rows and columns.",
      "properties": {
        "rows": {
          "type": "integer",
          "title": "Rows",
          "description": "The number of rows this element should span. Defaults to 1.",
          "minimum": 1
        },
        "columns": {
          "type": "integer",
          "title": "Columns",
          "description": "The number of columns this element should span. Defaults to 1.",
          "minimum": 1
        }
      }
    },
    "flex": {
      "title": "Flex",
      "type": "object",
      "format": "normal",
      "description": "When placed in a Flex array, specifies the flex layout behavior for this element.",
      "properties": {
        "grow": {
          "type": "boolean",
          "title": "Grow To Container",
          "description": "Whether the width of this element will be allowed to grow beyond the width specified to fit the container."
        },
        "shrink": {
          "type": "boolean",
          "title": "Shrink To Content",
          "description": "Whether the width of this element will be allowed to shrink below the width specified to fit the content."
        },
        "basis": {
          "type": "string",
          "title": "Flex Basis",
          "description": "The desired width of this element in the flex container, if there is space for it. Specified in standard CSS units."
        },
      },
    },
    padding,
    margin,
    border,
    background,
    /*conditions*/
    "sourceIds": {
      "title": "Data Sources",
      "type": "array",
      "description": "The IDs of data sources that this card uses.",
      "items": {
        "type": "string"
      }
    },
  },
  "required": [
    "class"
  ]
};

const cardLibraryRecords = getAllToken(ROOT, CardsLibraryToken);
const cardLibraryDisplayNamePairs: [string, string][] = [];
const cardLibraryClassNamePairs: [string, string][] = [];
for (let rec of cardLibraryRecords) {
  const clazz = rec.clazz;
  const spec = rec["specification"] as BaseAtomSpec<unknown>;
  if (spec) {
    cardLibraryDisplayNamePairs.push([clazz, spec.getDisplayName()]);
  } else if (_.isFunction(rec.getDisplayName)) {
    cardLibraryDisplayNamePairs.push([clazz, rec.getDisplayName()]);
  } else {
    cardLibraryClassNamePairs.push([clazz, clazz]);
  }
}
cardLibraryDisplayNamePairs.sort((a, b) => a[1].localeCompare(b[1]));
cardLibraryClassNamePairs.sort((a, b) => a[1].localeCompare(b[1]));
for (let pair of cardLibraryDisplayNamePairs) {
  CARDS_ELEMENT_SCHEMA.properties.class.enum.push(pair[0]);
  CARDS_ELEMENT_SCHEMA.properties.class.options.enum_titles.push(pair[1]);
}
CARDS_ELEMENT_SCHEMA.properties.class.enum.push('');
CARDS_ELEMENT_SCHEMA.properties.class.options.enum_titles.push("-----");
for (let pair of cardLibraryClassNamePairs) {
  CARDS_ELEMENT_SCHEMA.properties.class.enum.push(pair[0]);
  CARDS_ELEMENT_SCHEMA.properties.class.options.enum_titles.push(pair[1]);
}

const CARDS_ARRAY_LAYOUT_RESPONSIVE_PROPS = {
  "type": {
    "type": "string",
    "title": "Grouping Style",
    "description": "How the cards elements will be grouped on the page. Single: A single full-wdith element. Grid: A grid of elements. Carousel: A horizontally scrolling carousel.",
    "enum": [
      "GRID",
      "SINGLE",
      "SWIPER",
      "FLEX",
      "NONE"
    ],
    "options": {
      "enum_titles": [
        "Grid",
        "Single",
        "Carousel",
        "Flex"
      ]
    }
  },
  "hidden": {
    "type": "boolean",
    "title": "Hidden",
    "description": "Hide this array's contents on the page."
  },
  "maxColumns": {
    "type": "integer",
    "minimum": 1,
    "title": "Maximum Columns",
    "description": "The maximum number of columns visible on screen in a carousel or grid layout."
  },
  "gapPixels": {
    "type": "number",
    "title": "Element Gap (px)",
    "description": "Gap between elements in pixels."
  },
  "splitEqually": {
    "type": "boolean",
    "format": "checkbox",
    "title": "Split Equally",
    "description": "In a grid layout, this forces the number of columns to be equal to the number of elements. If this is true, maxColumns is ignored."
  },
  "slideWidth": {
    "type": "string",
    "title": "Slide Width",
    "description": "The width of each slide in a carousel in standard CSS units."
  },
  "swiperPagination": {
    "type": "boolean",
    "format": "checkbox",
    "title": "Carousel Pagination Indicators",
    "description": "Whether pagination indicators are enabled on carousels.",
  },
  "swiperNavigation": {
    "type": "boolean",
    "format": "checkbox",
    "title": "Carousel Navigation Buttons",
    "description": "Whether navigation buttons are enabled on carousels.",
  },
  "zoom": {
    "type": "string",
    "title": "Zoom",
    "description": "Scales this array's content according to the specified zoom factor in pixels or percentages. The value 1.0 = 100% = normal zoom. Values larger than 1.0 (100%) zoom in, values smaller than 1.0 (100%) zoom out. This should be a last resort for fixing responsiveness issues (try other scaling methods first).",
  },
  "zIndex": {
    "type": "number",
    "title": "Stacking Order",
    "description": "Manually override the stacking order (Z-Index) for this array relative to others. The default stacking order is 0.",
  },
  "paginationOffset": {
    "type": "number",
    "title": "Pagination Offset",
    "description": "For carousels, the offset of the pagination indicators from the bottom of the array, in standard CSS units.",
  },
  "swiperNavigationOffset": {
    "type": "number",
    "title": "Navigation Button Offset",
    "description": "For carousels, the offset of the navigation buttons from the left and right sides of the array, in standard CSS units.",
  },
  "swiperNavigationOnHover": {
    "type": "boolean",
    "title": "Swiper Navigation on Hover",
    "description": "For carousels, whether navigation buttons only appear when the user hovers over the carousel.",
  },
  padding,
  margin,
};

export const CARDS_ARRAY_LAYOUT_SCHEMA = {
  "title": " ",
  "type": "object",
  "properties": {
    "responsiveness": {
      "type": "array",
      "title": "Responsiveness",
      "description": "Configure how this Array responds at different screen sizes. The values set here override values set directly on the Array if the screen-size condition is met.",
      "items": {
        "type": "object",
        "title": "Responsive Configuration",
        "properties": {
          "maxWidth": {
            "type": "number",
            "title": "Screen Size",
            "description": "The screen size at which these settings will be applied.",
            "enum": Object.values(CONFIG.breakpoints),
            "options": {
              "enum_titles": Object.values(CONFIG.breakpointTitles),
            },
          },
          ...CARDS_ARRAY_LAYOUT_RESPONSIVE_PROPS,
        },
        "required": [
          "maxWidth"
        ]
      }
    },
    ...CARDS_ARRAY_LAYOUT_RESPONSIVE_PROPS,
    "scrollSpeed": {
      "type": "number",
      "title": "Scroll Speed",
      "description": "The scrolling speed of a carousel in pixels per second. Default is 300."
    },
    "slideSnap": {
      "type": "boolean",
      "format": "checkbox",
      "title": "Slide Snap",
      "description": "Should slides in a carousel snap into position (like a pager)?",
    },
    "swipeEffect": {
      "type": "string",
      "title": "Carousel Animation",
      "description": "The animation used when swiping between items in a carousel. The default sliding animiation is \"Slide\".",
      "enum": [
        "SLIDE",
        "MAGNIFY",
        "FADE",
        "CUBE",
        "COVERFLOW",
        "FLIP"
      ],
      "options": {
        "enum_titles": [
          "Slide",
          "Magnify",
          "Fade",
          "Cube",
          "Coverflow",
          "Flip"
        ]
      }
    },
    "overflow": {
      "type": "string",
      "title": "Overflow",
      "description": "The overflow mode for the array. The default value differs based on the type of the array. For example, this allows Carousels to overflow their container on the left as the user scrolls.",
      "enum": [
        "hidden",
        "visible",
        "scroll",
      ],
      "options": {
        "enum_titles": [
          "Hidden",
          "Visible",
          "Scroll",
        ]
      }
    },
    "scrollToActive": {
      "type": "boolean",
      "format": "checkbox",
      "title": "Scroll to Active",
      "description": "Whether a carousel should automatically scroll to the first active element (for example, an upcoming or live match). This requires the elements within the carousel to support that functionality.",
    },
    "uniformHeight": {
      "type": "boolean",
      "format": "checkbox",
      "title": "Uniform Height",
      "description": "In a grid layout, require all rows to be the same height. The height of all rows (and thus all cells) will be the height of the tallest cell contents.",
      "default": true
    },
    "wrap": {
      "type": "string",
      "title": "Flex Wrap",
      "description": "The wrap property of the items inside a flex container. Specify wrap or nowrap.",
      "enum": [
        "wrap",
        "nowrap",
        "wrap-reverse",
      ],
      "options": {
        "enum_titles": [
          "Wrap",
          "No Wrap",
          "Wrap Reverse",
        ]
      }
    },
    background
  },
  "required": [
    "type"
  ]
};

const CARDS_COLUMN_RESPONSIVE_PROPS = {
  "hidden": {
    "type": "boolean",
    "title": "Hidden",
    "description": "Hide this column's contents on the page."
  },
  "width": {
    "type": "integer",
    "minimum": 0,
    "maximum": 100,
    "multipleOf": 1,
    "title": "Width (percent)",
    "description": "The percentage of the width of the parent section that this column will occupy. This can be left unspecified or set to 0, in which case this column will be automatically sized based on the size of its content."
  },
  "grow": {
    "type": "boolean",
    "format": "checkbox",
    "title": "Grow To Fit Container",
    "description": "Whether the width of this column will be allowed to grow beyond the width specified to fit the content. Ignored if width is unspecified or zero."
  },
  "shrink": {
    "type": "boolean",
    "format": "checkbox",
    "title": "Shrink To Fit Content",
    "description": "Whether the width of this column will be allowed to shrink below the width specified to tightly fit the content. Ignored if width is unspecified or zero."
  },
  "zoom": {
    "type": "string",
    "title": "Zoom",
    "description": "Scales this column's content according to the specified zoom factor in pixels or percentages. The value 1.0 = 100% = normal zoom. Values larger than 1.0 (100%) zoom in, values smaller than 1.0 (100%) zoom out. This should be a last resort for fixing responsiveness issues (try other scaling methods first)."
  },
  padding,
  margin,
};

export const CARDS_COLUMN_SCHEMA = {
  "title": " ",
  "type": "object",
  "properties": {
    "responsiveness": {
      "type": "array",
      "title": "Responsiveness",
      "description": "Configure how this Column responds at different screen sizes. The values set here override values set directly on the Column if the screen-size condition is met.",
      "items": {
        "type": "object",
        "title": "Responsive Configuration",
        "properties": {
          "maxWidth": {
            "type": "number",
            "title": "Screen Size",
            "description": "The screen size at which these settings will be applied.",
            "enum": Object.values(CONFIG.breakpoints),
            "options": {
              "enum_titles": Object.values(CONFIG.breakpointTitles),
            },
          },
          ...CARDS_COLUMN_RESPONSIVE_PROPS,
        },
        "required": [
          "maxWidth"
        ]
      }
    },
    ...CARDS_COLUMN_RESPONSIVE_PROPS,
  },
  "required": [
  ]
};

const CARDS_SECTION_RESPONSIVE_PROPS = {
  "hidden": {
    "type": "boolean",
    "title": "Hidden",
    "description": "Hide this column's contents on the page."
  },
  "height": {
    "type": "string",
    "title": "Fixed Height",
    "description": "Specifies a fixed height for the section in standard CSS units. If a fixed height is specified and the section's content is larger than the height specified (vertically), the section will scroll."
  },
  "zoom": {
    "type": "string",
    "title": "Zoom",
    "description": "Scales this section's content according to the specified zoom factor in pixels or percentages. The value 1.0 = 100% = normal zoom. Values larger than 1.0 (100%) zoom in, values smaller than 1.0 (100%) zoom out. This should be a last resort for fixing responsiveness issues (try other scaling methods first)."
  },
  padding,
  margin,
};

export const CARDS_SECTION_SCHEMA = {
  "title": " ",
  "type": "object",
  "properties": {
    "responsiveness": {
      "type": "array",
      "title": "Responsiveness",
      "description": "Configure how this Section responds at different screen sizes. The values set here override values set directly on the Section if the screen-size condition is met.",
      "items": {
        "type": "object",
        "title": "Responsive Configuration",
        "properties": {
          "maxWidth": {
            "type": "number",
            "title": "Screen Size",
            "description": "The screen size at which these settings will be applied.",
            "enum": Object.values(CONFIG.breakpoints),
            "options": {
              "enum_titles": Object.values(CONFIG.breakpointTitles),
            },
          },
          ...CARDS_COLUMN_RESPONSIVE_PROPS,
        },
        "required": [
          "maxWidth"
        ]
      }
    },
    ...CARDS_SECTION_RESPONSIVE_PROPS,
    "autoscroll": {
      "type": "boolean",
      "format": "checkbox",
      "title": "Auto Scroll",
      "description": "Auto-scroll the section to the first active element (for example, an upcoming or live match). Auto-scroll must be supported by elements within this section."
    },
    border,
    background,
  },
  "required": [
  ]
};

const cardDataSourceRecords = getAllToken(ROOT, CardsDataSourceToken);
const cardDataSourceClassNamePairs: [string, string][] = [['', 'Select Type']];
for (let rec of cardDataSourceRecords) {
  const clazz = (<any>rec).clazz;
  const name = (_.isFunction(rec.getDisplayName))? rec.getDisplayName() : clazz;
  cardDataSourceClassNamePairs.push([clazz, name]);
}
cardDataSourceClassNamePairs.sort((a, b) => a[1].localeCompare(b[1]));
const cardDataSourceEnumValues = cardDataSourceClassNamePairs.map(p => p[0]);
const cardDataSourceEnumTitles = cardDataSourceClassNamePairs.map(p => p[1]);

//console.log("DATA SOURCE RECORDS", cardDataSourceClassNamePairs);

const CARDS_TAB_DATA_SOURCE_DEFAULT_SCHEMA = {
  "type": "object",
  "format": "normal",
  "title": 'Undefined Data Source',
  "headerTemplate": 'Undefined Data Source',
  "description": '',
  "properties": {
    "id": {
      "type": "string",
      "title": "Identifier",
      "description": "A unique identifier for this data source that is referenced by card elements."
    },
    "name": {
      "type": "string",
      "title": "Name",
      "description": "A human-readable name for the data source.",
      "default": "New Data Source"
    },
    "class": {
      "type": "string",
      "title": "Data Source Type",
      "description": "The type of the data source (must match a valid data source in the code).",
      "enum": cardDataSourceEnumValues,
      "options": {
        "enum_titles": cardDataSourceEnumTitles
      }
    },
    "reverse": {
      "type": "boolean",
      "title": "Reverse",
      "description": "Reverse this data source's items (if it is an array)."
    },
    "data": {
      "type": "object",
      "format": "normal",
      "title": "Configuration Options",
      "description": "Configuration data for the source based on the source's type.",
      "additionalProperties": true,
      "options": {
        "disable_properties": false
      }
    },
    "path": {
      "type": "string",
      "title": "Endpoint",
      "description": "The API endpoint of the data source. Note that the valid options here may change based on the data source's type and configuration."
    },
  },
  "required": [
    "id",
    "class",
    "name"
  ]
};

const CARDS_TAB_DATA_SOURCE_NEW_SCHEMA = (function() {
  const item = _.cloneDeep(CARDS_TAB_DATA_SOURCE_DEFAULT_SCHEMA);
  item.title = item.headerTemplate = "New Data Source";
  item.description = "Select a type to begin creating this data source.";
  delete item.properties.data;
  delete item.properties.path;
  return item;
})();

const MAX_DATA_SOURCES_PER_TAB = 20;

export async function generateTabDataSourcesSchema(sources: YinzCamCardsServiceSource[]) {
  if (!sources?.length) {
    sources = [];
  }
  const items = [];
  for (let i = 0; i < sources.length && i < MAX_DATA_SOURCES_PER_TAB; i++) {
    const spec = sources[i];
    // console.log('SOURCE', i, spec);
    let item;
    if (!spec?.class) {
      item = CARDS_TAB_DATA_SOURCE_NEW_SCHEMA;
    } else {
      item = _.cloneDeep(CARDS_TAB_DATA_SOURCE_DEFAULT_SCHEMA);
      const source = getNamed(ROOT, CardsDataSourceToken, spec.class);
      if (source) {
        item.title = item.headerTemplate = source.getDisplayName();
        const paths = await source.getDataSourcePaths(spec.data);
        if (paths) {
          const pathSchema = item.properties.path as any;
          pathSchema.enum = paths.map(p => p.path);
          pathSchema.options = {
            enum_titles: paths.map(p => p.name)
          };
          item.required.push("path");
        } else if (_.isNull(paths)) {
          // if null set the enum to no options available, path is required still though (so this is an error)
          item.properties.path.enum = [];
          item.properties.path.description = "The data source indicated that a path is required but none are available with the current data-source configuration.";
          item.required.push("path");
        } // otherwise undefined, leave as is (path is optional)
        const dataSchema = await source.getDataSourceConfigSpec(spec.path, spec.data);
        if (dataSchema) {
          // if the source provided a schema, use it and make it required
          delete item.properties.data.additionalProperties;
          delete item.properties.data.options;
          item.properties.data = { ...item.properties.data, ...dataSchema };
          item.required.push("data");
        } else if (_.isNull(dataSchema)) {
          // if null delete the property (the source doesn't care about extra data)
          delete item.properties.data;
        } // otherwise undefined, leave as is
      }
    }
    items.push(item);
  }
  if (sources.length < MAX_DATA_SOURCES_PER_TAB) {
    items.push(CARDS_TAB_DATA_SOURCE_NEW_SCHEMA);
  }
  
  const schema = {
    "title": "Data Sources",
    "type": "array",
    "description": "Define data sources for use by cards within this view.",
    "minItems": 0,
    "maxItems": MAX_DATA_SOURCES_PER_TAB,
    "items": items,
    "options": {
      "disable_array_reorder": true
    }
  };
  //console.log('SCHEMA', schema);
  return schema;
}

export const CARDS_TAB_SCHEMA = {
  "title": " ",
  "type": "object",
  "properties": {    
    "default": {
      "type": "boolean",
      "format": "checkbox",
      "title": "Is Default View?",
      "description": "If set to true, this view will be the first view shown on page load within this region.",
      "default": true
    },
    padding,
    margin,
    background,    
    /*sources: ...,*/
    "name": {
      "type": "string",
      "title": "Name",
      "description": "A name for this component that can be referenced by other components within a fragment URI."
    },
    "fragmentUri": {
      "type": "string",
      "title": "Fragment URI",
      "description": "If specified, this fragment URI will be used to display the contents of the view. Fragment URI must be of the form {page-slug}#{json.path}."
    }
  }
};

export const CARDS_PAGE_SCHEMA = {
  "title": " ",
  "type": "object",
  "properties": {
    padding,
    margin,
    background,
    /* font, */
    "analytics": {
      "type": "object",
      "title": "Analytics",
      "description": "Configuration of analytics tracking data for this page.",
      "properties": {
        "path": {
          "type": "string",
          "title": "Path",
          "description": "The page path to report for analytics tracking."
        },
        "name": {
          "type": "string",
          "title": "Name",
          "description": "The page name to report for analytics tracking."
        }
      },
      "required": [
        "path",
        "name"
      ]
    },
    "editorParameters": {
      "type": "object",
      "title": "Editor Parameters",
      "description": "Parameters to inject into the page when working in the editor (page builder).",
      "properties": {
        "path1": {
          "type": "string",
          "title": "path1",
          "description": "The primary path parameter. Use this for content slugs."
        },
        "path2": {
          "type": "string",
          "title": "path2",
          "description": "The secondary path parameter."
        },
        "query": {
          "type": "string",
          "title": "query",
          "description": "The query parameter for search results."
        }
      },
      "patternProperties": {
        "^.+$": { 
          "type": "string"
        }
      },
      "additionalProperties": true,
      "options": {
        "disable_properties": false
      }
    },
    "defaultParameters": {
      "type": "object",
      "title": "Default Parameters",
      "description": "Parameters to set by default if they are not provided from another source (e.g. the URL path or query string).",
      "patternProperties": {
        "^.+$": { 
          "type": "string"
        }
      },
      "additionalProperties": true,
      "options": {
        "disable_properties": false
      }
    }
  }
};