import _ from 'lodash';

export function afterUpdateDebounce(func: () => void) {
  return _.debounce(func, CONFIG.afterUpdateDebounceIntervalMilliseconds, {
    leading: true,
    trailing: true,
    maxWait: CONFIG.afterUpdateDebounceMaxWaitMilliseconds
  });
}

let _objectIdCounter: number = 1;
const idMap: WeakMap<WeakKey, number> = new WeakMap<WeakKey, number>();
export function getObjectId(object: WeakKey): number {
  const objectId: number | undefined = idMap.get(object);
  if (objectId === undefined) {
    _objectIdCounter += 1;
    idMap.set(object, _objectIdCounter);
    return _objectIdCounter;
  }
  return objectId;
}
