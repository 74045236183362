<Boundary>
  <div bind:this={wrapperEl} class="wrapper" style={internalStyle} on:mouseenter={handleMouseEnter} on:mouseleave={handleMouseLeave} {...parentAttributes}>
    {#if showEditorControls}
      <div class="controls" class:this-selected={thisSelected} class:clone-source-selected={cloneSourceSelected} class:clone={subtreeIsCloned} class:clone-with-selection={subtreeIsCloned && editorOptions.allowEditsToSelfIfCloned}>
        <CardsEditorComponentControls active={thisSelected} {buttons} />
      </div>
    {/if}
    <slot></slot>
  </div>
</Boundary>

<style>
  *, *::before, *::after {
    box-sizing: border-box;
  }

  .controls {
    position: absolute;
    top: 0px;
    left: 0px;
    /*height: 21px;*/
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    max-width: 100%;
    z-index: 1000;
  }

  .controls:not(.clone), .controls.clone.clone-with-selection {
    background-color: var(--cards-editor-component-unselected-background-color);
    color: var(--cards-editor-component-unselected-text-color);
  }

  .controls.clone {
    border-right: 2px dotted var(--cards-editor-component-unselected-background-color);
    border-bottom: 2px dotted var(--cards-editor-component-unselected-background-color);
    color: var(--cards-editor-component-unselected-background-color);
  }

  .controls.clone.clone-source-selected {
    border-right: 2px dotted var(--cards-editor-component-selected-background-color);
    border-bottom: 2px dotted var(--cards-editor-component-selected-background-color);
  }

  .controls.this-selected {
    background-color: var(--cards-editor-component-selected-background-color);
    color: var(--cards-editor-component-selected-text-color);
  }</style>

<script context="module" lang="ts">var _a, _b, _c;
import { derived, writable, get } from "svelte/store";
const LOCAL_COPY_PASTE_KEY = 'janus-local-copy-paste-item';
const restoredCopyPasteItem = JSON.parse(window.localStorage.getItem(LOCAL_COPY_PASTE_KEY) || '{}');
const currentCopyPasteItem = writable(restoredCopyPasteItem);
currentCopyPasteItem.subscribe((value) => {
    window.localStorage.setItem(LOCAL_COPY_PASTE_KEY, JSON.stringify(value));
});
window.addEventListener('storage', (event) => {
    if (event.storageArea === window.localStorage && (!event.key || event.key === LOCAL_COPY_PASTE_KEY)) {
        currentCopyPasteItem.set(JSON.parse(window.localStorage.getItem(LOCAL_COPY_PASTE_KEY) || '{}'));
    }
});
</script>

<script lang="ts">var _a, _b, _c;
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
import { CardsPageContextKey } from './context';
import { getContext, setContext, onDestroy } from "svelte";
import './CardsEditorInterfaces';
import CardsEditorComponentControls from './CardsEditorComponentControls.svelte';
import './CardsEditorComponentInterfaces';
import _ from 'lodash';
import 'svelte-dnd-action';
import { v4 as uuid4 } from "uuid";
import { CardsEditorSubtreeContextKey } from "./context";
import { f7 } from 'framework7-svelte';
import { pbt } from '../../../js/i18n';
export let style = "";
export let parentAttributes = {};
export let editorOptions;
export let wrapperEl = null;
const pageContext = getContext(CardsPageContextKey);
const emm = pageContext.editorModeManager;
const emmEnabledStore = emm.getEnabledStore();
const emmPreviewStore = emm.getPreviewStore();
const emmSelectedStore = emm.getSelectedComponentStore();
const editorOptionsStore = writable(editorOptions);
const thisPreviewStore = writable(false);
const parentSubtreeContextStore = getContext(CardsEditorSubtreeContextKey) || writable(null);
const thisSubtreeContextStore = derived([parentSubtreeContextStore, editorOptionsStore, thisPreviewStore], ([parentSubtreeContext, thisEditorOptions, thisPreview], set) => {
    var _a, _b;
    const thisCloned = (_.isNil(editorOptions.cloned)) ? !!((_b = (_a = editorOptions.component) === null || _a === void 0 ? void 0 : _a.__expandRepeats) === null || _b === void 0 ? void 0 : _b.cloned) : editorOptions.cloned;
    set({
        componentId: thisEditorOptions.componentId,
        component: thisEditorOptions.component,
        subtreeCloned: (parentSubtreeContext === null || parentSubtreeContext === void 0 ? void 0 : parentSubtreeContext.subtreeCloned) || thisCloned,
        subtreePreview: (parentSubtreeContext === null || parentSubtreeContext === void 0 ? void 0 : parentSubtreeContext.subtreePreview) || thisPreview,
        /* --- */
        getChildSubtree,
        moveUpChild,
        moveDownChild,
        cloneChild,
        removeChild,
        addChildBefore,
        addChildAfter
    });
});
setContext(CardsEditorSubtreeContextKey, thisSubtreeContextStore);
let internalStyle = style;
let emmEnabled;
let emmPreview;
let emmSelected;
let parentSelected = false;
let thisSelected = false;
let showEditorControls = false;
let buttons;
let editPopupOpened = false;
let editorSchema = {};
let thisMouseHover = false;
$: emmEnabled = $emmEnabledStore;
$: emmPreview = $emmPreviewStore;
$: emmSelected = $emmSelectedStore;
$: parentSelected = (emmSelected === null || emmSelected === void 0 ? void 0 : emmSelected.sequenceId) === editorOptions.parentSequenceId; // TODO: should this be based on component ID?
$: thisSelected = (emmSelected === null || emmSelected === void 0 ? void 0 : emmSelected.id) === editorOptions.componentId;
$: thisCloned = (_.isNil(editorOptions.cloned)) ? !!((_b = (_a = editorOptions.component) === null || _a === void 0 ? void 0 : _a.__expandRepeats) === null || _b === void 0 ? void 0 : _b.cloned) : editorOptions.cloned;
$: cloneSourceSelected = thisCloned && (emmSelected === null || emmSelected === void 0 ? void 0 : emmSelected.id) === editorOptions.sourceElementId;
$: outlineMode = emmEnabled && (!emmPreview || (emmPreview && editorOptions.allowPreviewHoverEdit && (thisMouseHover || thisSelected)));
$: showEditorControls = outlineMode && (!emmPreview || (emmPreview && editorOptions.allowPreviewHoverEdit && thisMouseHover));
$: hasChldren = getOrCreateChildrenArray(editorOptions).length > 0;
$: $editorOptionsStore = editorOptions;
//$: $thisIsClonedStore = (_.isNil(editorOptions.cloned))? !!editorOptions.component?.__expandRepeats?.cloned : editorOptions.cloned;
$: subtreeIsCloned = $thisSubtreeContextStore.subtreeCloned;
$: {
    let css = style;
    if (outlineMode) {
        css += `--cards-editor-component-primary-color: ${editorOptions.primaryColor};`;
        css += `--cards-editor-component-reverse-text-color: ${editorOptions.reverseTextColor};`;
        css += `--cards-editor-component-unselected-background-color: ${editorOptions.unselectedBackgroundColor};`;
        css += `--cards-editor-component-unselected-text-color: ${editorOptions.unselectedTextColor};`;
        css += `--cards-editor-component-selected-background-color: ${editorOptions.selectedBackgroundColor};`;
        css += `--cards-editor-component-selected-text-color: ${editorOptions.selectedTextColor};`;
        let borderOrOutline = (emmPreview) ? 'outline' : 'border';
        if (cloneSourceSelected) {
            css += `${borderOrOutline}: 2px dotted var(--cards-editor-component-selected-background-color);`;
        }
        else if (subtreeIsCloned && !(editorOptions.allowEditsToSelfIfCloned && thisSelected)) {
            css += `${borderOrOutline}: 2px dotted var(--cards-editor-component-unselected-background-color);`;
        }
        else if (parentSelected) {
            css += `${borderOrOutline}: 2px dashed lightpink;`;
        }
        else if (thisSelected) {
            css += `${borderOrOutline}: 2px solid var(--cards-editor-component-selected-background-color);`;
        }
        else {
            css += `${borderOrOutline}: 2px solid var(--cards-editor-component-unselected-background-color);`;
        }
        if (!emmPreview) {
            css += 'padding: 5px;';
            css += 'background: initial;';
            css += 'margin: initial;';
        }
        else /* in preview mode */ {
            css += 'min-height: 25px;'; // to allow room for the user to hover over and get editor controls
            css += 'position: relative; z-index: 9999;'; // make sure selected item is at the top
        }
    }
    else {
        // if not in outline mode, apply the styles coming from the parent
        // css += style;
    }
    if (showEditorControls) {
        css += 'position: relative;';
        if (!emmPreview) {
            css += 'padding-top: 28px;';
        }
    }
    /*
    if (editorOptions.forceWidth) {
      css += `width: ${editorOptions.forceWidth}; margin: auto; overflow: scroll;`;
    }
    */
    internalStyle = css;
}
$: if (subtreeIsCloned) {
    buttons = [
        Object.assign({ id: 'NAME', label: $pbt(editorOptions.componentTypeName) }, (editorOptions.allowEditsToSelfIfCloned && { onClick: onNameClick })),
    ];
}
else {
    buttons = [
        { id: 'NAME', label: $pbt(editorOptions.componentTypeName), onClick: onNameClick },
    ];
    /*
    if (editorSchema) {
      buttons.push({ id: 'EDIT', icon: 'square_pencil_fill', tooltip: $pbt('Edit this component.'), showOnlyWhenActive: true, onClick: onEdit });
    }
    */
    if ($parentSubtreeContextStore === null || $parentSubtreeContextStore === void 0 ? void 0 : $parentSubtreeContextStore.componentId) {
        buttons.push({ id: 'SELECT_PARENT', icon: 'arrow_up_left_square_fill', tooltip: $pbt('Select parent component.'), showOnlyWhenActive: true, onClick: onSelectParent });
    }
    /*
    if (editorOptions.allowMovement) {
      buttons.push({ id: 'MOVE_PREV', icon: 'arrowtriangle_up_fill', tooltip: $pbt('Move component up in order.'), showOnlyWhenActive: true, onClick: onMoveUp });
      buttons.push({ id: 'MOVE_NEXT', icon: 'arrowtriangle_down_fill', tooltip: $pbt('Move component down in order.'), showOnlyWhenActive: true, onClick: onMoveDown });
    }
    if (editorOptions.allowRepeats) {
      buttons.push({ id: 'ADD_REPEAT', icon: 'rectangle_stack_fill_badge_plus', tooltip: $pbt('Increase the number of times this component is repeated in order. Repeated components share the same configuration as the original component.'), showOnlyWhenActive: true, onClick: onAddRepeat });
      buttons.push({ id: 'REMOVE_REPEAT', icon: 'rectangle_stack_fill_badge_minus', tooltip: $pbt('Decrease the number of times this component is repeated in order. Repeated components share the same configuration as the original component.'), showOnlyWhenActive: true, onClick: onRemoveRepeat });
    }
    */
    if (editorOptions.allowDuplication) {
        buttons.push({ id: 'ADD_SIBLING_BEFORE', icon: 'arrow_up_doc_fill', tooltip: $pbt('Add a new component before this one.'), showOnlyWhenActive: true, onClick: onAddSiblingBefore });
        buttons.push({ id: 'ADD_SIBLING_AFTER', icon: 'arrow_down_doc_fill', tooltip: $pbt('Add a new component after this one.'), showOnlyWhenActive: true, onClick: onAddSiblingAfter });
        buttons.push({ id: 'CLONE', icon: 'doc_on_doc_fill', tooltip: $pbt('Create an independent copy of this component. Copied components do not share any configuration with the original component.'), showOnlyWhenActive: true, onClick: onClone });
    }
    if (editorOptions.allowChildren) {
        buttons.push({ id: 'ADD_CHILD', icon: 'plus_square_fill_on_square_fill', tooltip: $pbt('Add a new child to this component.'), showOnlyWhenActive: true, onClick: onAddChild });
    }
    if (editorOptions.allowDeletion && editorOptions.allowCopyPaste) {
        buttons.push({ id: 'CUT', icon: 'scissors', tooltip: $pbt('Cut this component. This deletes the component after copying it to the clipboard.'), showOnlyWhenActive: true, onClick: onCut });
    }
    if (editorOptions.allowCopyPaste) {
        buttons.push({ id: 'COPY', icon: 'doc_on_clipboard', tooltip: $pbt('Copy this component to the clipboard.'), showOnlyWhenActive: true, onClick: onCopy });
    }
    if (editorOptions.allowCopyPaste && editorOptions.allowChildren && ((_c = editorOptions.childTypeNames) === null || _c === void 0 ? void 0 : _c.includes($currentCopyPasteItem === null || $currentCopyPasteItem === void 0 ? void 0 : $currentCopyPasteItem.componentType))) {
        buttons.push({ id: 'PASTE_CHILD', icon: 'yc_icon_paste', tooltip: $pbt('Paste the last copied component as the last child of this one.'), showOnlyWhenActive: true, onClick: onPasteChild });
    }
    if (editorOptions.allowCopyPaste && editorOptions.componentTypeName === ($currentCopyPasteItem === null || $currentCopyPasteItem === void 0 ? void 0 : $currentCopyPasteItem.componentType)) {
        buttons.push({ id: 'PASTE_SIBLING', icon: 'yc_icon_paste', tooltip: $pbt('Paste the last copied component after this one.'), showOnlyWhenActive: true, onClick: onPasteSibling });
    }
    if (editorOptions.allowDeletion) {
        buttons.push({ id: 'DELETE', icon: 'trash_fill', tooltip: $pbt('Delete this component.'), showOnlyWhenActive: true, onClick: onDelete });
    }
}
function handleMouseEnter() {
    thisMouseHover = true;
}
function handleMouseLeave() {
    thisMouseHover = false;
}
function getOrCreateChildrenArray(editorOptions) {
    const c = editorOptions.component;
    const k = editorOptions.childrenKey;
    if (!_.isArray(c[k])) {
        c[k] = [];
        doUpdate();
    }
    return c[k];
}
function unselectMyself() {
    if (thisSelected) {
        emm.setSelectedComponentEditorOptions(null);
        emm.setSelectedComponentId(null);
    }
}
// Update selected component editor options any time anything within it changes and we're selected
$: if (thisSelected) {
    emm.setSelectedComponentEditorOptions({
        title: `${$pbt('Edit')} ${$pbt(editorOptions.componentTypeName)}`,
        barColor: editorOptions.selectedBackgroundColor,
        textColor: editorOptions.selectedTextColor,
        schema: editorSchema,
        data: editorOptions.component,
        onUpdate: mergeChangesAndUpdate,
        onFileUpload: emm.beginFileUpload.bind(emm),
        closePopup: onEditPopupClosed,
        componentId: editorOptions.componentId,
    });
}
async function openEditPopup() {
    f7.preloader.show('black');
    editorSchema = await generateSchema();
    //emm.disableSave();
    //editPopupOpened = true;
    f7.preloader.hide();
}
function onEditPopupClosed() {
    //editPopupOpened = false;
    //emm.enableSave();
    unselectMyself();
}
async function onEdit() {
    await openEditPopup();
}
async function onThisSelected(thisSelected) {
    if (!thisSelected) {
        return;
    }
    await onEdit();
}
$: onThisSelected(thisSelected);
async function onNameClick() {
    emm.setSelectedComponentId(editorOptions.componentId);
}
function onSelectParent() {
    if ($parentSubtreeContextStore) {
        emm.setSelectedComponentId($parentSubtreeContextStore.componentId);
    }
}
function onMoveUp() {
    $parentSubtreeContextStore === null || $parentSubtreeContextStore === void 0 ? void 0 : $parentSubtreeContextStore.moveUpChild(editorOptions.componentId);
}
function onMoveDown() {
    $parentSubtreeContextStore === null || $parentSubtreeContextStore === void 0 ? void 0 : $parentSubtreeContextStore.moveDownChild(editorOptions.componentId);
}
function onAddRepeat() {
    //console.log("ON ADD REPEAT", editorOptions.component);
    if (_.isFunction(editorOptions.addRepeat)) {
        editorOptions.addRepeat();
    }
    else {
        editorOptions.component.repeat = Math.max((editorOptions.component.repeat || 1) + 1, 1);
    }
    doUpdate();
}
function onRemoveRepeat() {
    //console.log("ON REMOVE REPEAT", editorOptions.component);
    if (_.isFunction(editorOptions.removeRepeat)) {
        editorOptions.removeRepeat();
    }
    else {
        editorOptions.component.repeat = (editorOptions.component.repeat || 1) - 1;
        if (editorOptions.component.repeat <= 1) {
            delete editorOptions.component.repeat;
        }
    }
    doUpdate();
}
function onAddSiblingBefore() {
    $parentSubtreeContextStore === null || $parentSubtreeContextStore === void 0 ? void 0 : $parentSubtreeContextStore.addChildBefore(editorOptions.componentId);
}
function onAddSiblingAfter() {
    $parentSubtreeContextStore === null || $parentSubtreeContextStore === void 0 ? void 0 : $parentSubtreeContextStore.addChildAfter(editorOptions.componentId);
}
function onClone() {
    $parentSubtreeContextStore === null || $parentSubtreeContextStore === void 0 ? void 0 : $parentSubtreeContextStore.cloneChild(editorOptions.componentId);
}
function onAddChild() {
    addChild();
}
function onCut() {
    console.log('oncut');
    onCopy();
    onDelete();
}
function onCopy() {
    console.log('oncopy');
    currentCopyPasteItem.set({
        componentType: editorOptions.componentTypeName,
        copyTime: new Date().toISOString(),
        data: $parentSubtreeContextStore === null || $parentSubtreeContextStore === void 0 ? void 0 : $parentSubtreeContextStore.getChildSubtree(editorOptions.componentId),
    });
}
function onPasteChild() {
    console.log('ON PASTE CHILD', get(currentCopyPasteItem));
    addChild(cloneItemWithNewIds(get(currentCopyPasteItem).data));
}
function onPasteSibling() {
    const newChild = cloneItemWithNewIds(get(currentCopyPasteItem).data);
    console.log('ON PASTE SIBLING', get(currentCopyPasteItem), newChild);
    $parentSubtreeContextStore === null || $parentSubtreeContextStore === void 0 ? void 0 : $parentSubtreeContextStore.addChildAfter(editorOptions.componentId, newChild);
}
function onDelete() {
    console.log('ondelete');
    unselectMyself();
    $parentSubtreeContextStore === null || $parentSubtreeContextStore === void 0 ? void 0 : $parentSubtreeContextStore.removeChild(editorOptions.componentId);
}
/* */
function doUpdate() {
    editorOptions.updater(editorOptions.component);
    emm.notifyPageDataUpdate();
}
function cloneItemWithNewIds(item) {
    return _.cloneDeepWith(item, (v, k) => {
        if (k === 'id' && _.isString(v)) {
            return uuid4();
        }
        if (_.isString(k) && k.startsWith('__')) {
            return null;
        }
    });
}
function getChildSubtree(id) {
    return getOrCreateChildrenArray(editorOptions).find((i) => i.id === id);
}
function createChild() {
    return (_.isFunction(editorOptions.createChild)) ?
        editorOptions.createChild() :
        { id: uuid4() };
}
function addChild(newChild = null) {
    if (!newChild) {
        newChild = createChild();
    }
    console.log('ADD CHILD', newChild);
    getOrCreateChildrenArray(editorOptions).push(newChild);
    doUpdate();
}
function moveUpChild(id) {
    const arr = getOrCreateChildrenArray(editorOptions);
    let idx = arr.findIndex((i) => i.id === id);
    if (idx > 0) {
        const temp = arr[idx - 1];
        arr[idx - 1] = arr[idx];
        arr[idx] = temp;
        doUpdate();
    }
}
function moveDownChild(id) {
    const arr = getOrCreateChildrenArray(editorOptions);
    let idx = arr.findIndex((i) => i.id === id);
    if (idx >= 0 && idx < arr.length - 1) {
        const temp = arr[idx + 1];
        arr[idx + 1] = arr[idx];
        arr[idx] = temp;
        doUpdate();
    }
}
function cloneChild(id) {
    const arr = getOrCreateChildrenArray(editorOptions);
    let idx = arr.findIndex((i) => i.id === id);
    if (idx >= 0) {
        arr.splice(idx + 1, 0, cloneItemWithNewIds(arr[idx]));
        doUpdate();
    }
}
function removeChild(id) {
    const arr = getOrCreateChildrenArray(editorOptions);
    let idx = arr.findIndex((i) => i.id === id);
    if (idx >= 0) {
        arr.splice(idx, 1);
        doUpdate();
    }
}
function addChildBefore(id, newChild) {
    const arr = getOrCreateChildrenArray(editorOptions);
    let idx = arr.findIndex((i) => i.id === id);
    if (idx >= 0) {
        if (!newChild) {
            newChild = createChild();
        }
        arr.splice(idx, 0, newChild);
        doUpdate();
    }
}
function addChildAfter(id, newChild) {
    const arr = getOrCreateChildrenArray(editorOptions);
    let idx = arr.findIndex((i) => i.id === id);
    if (idx >= 0) {
        if (!newChild) {
            newChild = createChild();
        }
        arr.splice(idx + 1, 0, newChild);
        doUpdate();
    }
}
function getChildren() {
    return getOrCreateChildrenArray(editorOptions).map((i) => {
        return { id: i.id };
    });
}
function getConfigValue(key) {
    var _a;
    return (_a = editorOptions === null || editorOptions === void 0 ? void 0 : editorOptions.component) === null || _a === void 0 ? void 0 : _a[key];
}
function setConfigValue(key, value) {
    if (editorOptions === null || editorOptions === void 0 ? void 0 : editorOptions.component) {
        editorOptions.component[key] = value;
        doUpdate();
    }
}
async function generateSchema() {
    const schema = (_.isFunction(editorOptions.configSpecGenerator)) ?
        // wrapping in Promise.resolve handles the case where configSpecGenerator doesn't return a promise
        await Promise.resolve(editorOptions.configSpecGenerator(editorOptions.component)) :
        editorOptions.configSpec;
    (function translateRecursive(s) {
        //console.log('translateRecursive', s);
        if (!_.isPlainObject(s)) {
            return;
        }
        Object.keys(s).forEach((k) => {
            var _a;
            if (k === 'title' || k === 'description') {
                s[k] = $pbt(s[k]);
            }
            else if (k === 'enum_titles' && ((_a = s[k]) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                for (let i = 0; i < s[k].length; i++) {
                    s[k][i] = $pbt(s[k][i]);
                }
            }
            else {
                translateRecursive(s[k]);
            }
        });
    })(schema);
    return schema;
}
async function mergeChangesAndUpdate(data) {
    f7.preloader.show('black');
    //console.log("mergeChangesAndUpdate before merge", JSON.stringify(editorOptions.component, null, 2), data);
    // FIXME: This should do a sweep of specified properties in the json schema
    // and remove properties that are defined in the schema but are not in the data object
    function sweepAndDelete(schema, compData, formData) {
        if (!(schema === null || schema === void 0 ? void 0 : schema.properties) || !compData || !formData) {
            return;
        }
        Object.keys(schema.properties).forEach((k) => {
            if (!formData.hasOwnProperty(k)) {
                delete compData[k];
            }
            sweepAndDelete(schema.properties[k], compData[k], formData[k]);
        });
    }
    sweepAndDelete(editorSchema, editorOptions.component, data);
    //console.log("mergeChangesAndUpdate after sweepAndDelete", JSON.stringify(data, null, 2));
    _.mergeWith(editorOptions.component, data, (objValue, srcValue) => {
        // Tweak the merge algorithm to not merge arrays key-wise. Just overwrite the whole array with the source.
        if (_.isArray(objValue) && !_.isNil(srcValue)) {
            return srcValue;
        }
    });
    //console.log("mergeChangesAndUpdate after merge", JSON.stringify(editorOptions.component, null, 2));
    doUpdate();
    // For performance reasons, this is only regenerated here.
    editorSchema = await generateSchema();
    f7.preloader.hide();
}
$: emm.registerComponent({
    id: editorOptions.componentId,
    sequenceId: editorOptions.sequenceId,
    configSpec: { options: [] },
    addChild,
    removeChild,
    getChildren,
    getConfigValue,
    setConfigValue
});
onDestroy(() => {
    emm.unregisterComponent(editorOptions.componentId);
});
</script>
