<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import "lodash";
import { BaseAtom } from "components/cards/atoms";
import { TextParticle, } from "components/cards/particles/YinzCam";
export let container;
export let playerStats = [];
export let statsListMax;
export let statsListProgress;
export let progressColor;
export let borderColor;
export let graphFillColor;
export let graphVariant;
let atomWidthRem;
const computePercent = (valProgress, valMax, variant) => {
    var _a, _b, _c;
    let total;
    let progress = parseInt((_a = valProgress === null || valProgress === void 0 ? void 0 : valProgress.homeValue) === null || _a === void 0 ? void 0 : _a.text);
    if (variant === "progress-vs-total") {
        total = progress + parseInt((_b = valMax === null || valMax === void 0 ? void 0 : valMax.homeValue) === null || _b === void 0 ? void 0 : _b.text);
    }
    else {
        total = parseInt((_c = valMax === null || valMax === void 0 ? void 0 : valMax.homeValue) === null || _c === void 0 ? void 0 : _c.text);
    }
    if (total === 0)
        return 0;
    return ((progress / total) * 100).toFixed(2);
};
$: valMax = playerStats === null || playerStats === void 0 ? void 0 : playerStats.find((playerStat) => { var _a; return statsListMax === ((_a = playerStat === null || playerStat === void 0 ? void 0 : playerStat.name) === null || _a === void 0 ? void 0 : _a.text); });
$: valProgress = playerStats === null || playerStats === void 0 ? void 0 : playerStats.find((playerStat) => { var _a; return statsListProgress === ((_a = playerStat === null || playerStat === void 0 ? void 0 : playerStat.name) === null || _a === void 0 ? void 0 : _a.text); });
$: progressPercent = computePercent(valProgress, valMax, graphVariant);
</script>

<BaseAtom
    bind:_clientWidthRem={atomWidthRem}
    _isotope="CircleGraphAtom"
    _cssVariablePrefix="circle-graph"
    {...container}
    {progressColor}
    {borderColor}
    {graphFillColor}
>
    <div class="graph-container">
        <div class="circle-card">
            <TextParticle
                {...valProgress?.name}
                text={valProgress?.name?.text}
            />
            <div
                class="circle-progress"
                style={`--progress: ${progressPercent}deg`}
            >
                <div class="percent-text-box">
                    <TextParticle
                        {...valProgress?.homeValue}
                        text={`${progressPercent}%`}
                    />
                    <TextParticle {...playerStats[0]?.successRate} />
                </div>
            </div>
        </div>
    </div>
</BaseAtom>

<style>
    .graph-container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 0.5rem;
        padding: 1rem;
    }
    .circle-card {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: 0.75rem 0.8125rem;
        border-radius: 0.75rem;
        align-items: center;
        position: relative;
        padding: 1.5rem;
        background-color: var(--theme-mode-background-color, unset);
        gap: 0.75rem;
    }
    .circle-progress {
        position: relative;
        width: 16rem;
        height: 16rem;
        border-radius: 50%;
        background: conic-gradient(
            var(--circle-graph-progress-color, --theme-mode-foreground-color)
                calc(var(--progress) * 3.6),
            var(--circle-graph-graph-fill-color, #d9d9d9) 0deg
        );
        font-size: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }
    .circle-progress::before {
        content: "";
        position: absolute;
        top: 1rem;
        right: 1rem;
        bottom: 1rem;
        left: 1rem;
        background-color: #fff;
        border-radius: 50%;
    }
    .percent-text-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0.5rem 0.75rem;
        border: 1px solid var(--circle-graph-border-color, #d9d9d9);
        border-radius: 0.75rem;
        z-index: 1;
    }</style>
