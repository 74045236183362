<YCPage query={pageQuery}>
  <CardsPage {listName} {pathParams} {pageQuery} {isModal} />
</YCPage>

<script lang='ts'>import YCPage from '../components/YCPage.svelte';
import CardsPage from '../components/cards/common/CardsPage.svelte';
export let f7route;
export let isModal = false;
const listName = f7route.params.listName;
const pathParams = (f7route.params.path1) ? [f7route.params.path1] : undefined;
const pageQuery = f7route.query;
</script>
