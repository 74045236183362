import { ManualPassthrough, ReactiveMicrocomponent } from 'yinzcam-rma';
import { injectable } from 'inversify';
import { injectToken, Token } from 'inversify-token';
import { ContentfulAPIConfigToken } from './ContentfulAPIConfig';
import type { ContentfulAPIConfig } from './ContentfulAPIConfig';
import { ContentfulCDNRequest } from './ContentfulCDNRequest';
import { ContentfulCDNResponse } from './ContentfulCDNResponse';
import { ContentfulCDNMetadataComponent } from './ContentfulCDNMetadataComponent';
import { ContentfulClientFactoryToken } from './ContentfulClient';
import type { ContentfulClient, ContentfulClientFactory } from './ContentfulClient';
import { ContentfulCDNQueryComponent } from './ContentfulCDNQueryComponent';
import { ContentfulCDNMetadata } from './ContentfulCDNMetadata';

export const ContentfulCDNToken = new Token<ContentfulCDN>(Symbol.for("ContentfulCDN"));

@injectable()
export class ContentfulCDN {
  private readonly apiConfig: ContentfulAPIConfig;
  //private readonly contentfulFactory: ContentfulClientFactory;
  private readonly contentful: ContentfulClient;
  private readonly metadataComp: ReactiveMicrocomponent<ContentfulCDNMetadata>;

  public constructor(
    @injectToken(ContentfulAPIConfigToken) config: ContentfulAPIConfig,
    @injectToken(ContentfulClientFactoryToken) contentfulFactory: ContentfulClientFactory) {
    this.apiConfig = config;
    //this.contentfulFactory = contentfulFactory;
    this.contentful = contentfulFactory.getDefaultContentfulClient();
    this.metadataComp = new ContentfulCDNMetadataComponent("ContentfulCDN__METADATA", this.apiConfig, this.contentful);
  }

  private getRequestHashKey(req: ContentfulCDNRequest): string {
    let hashKey: string = '';
    hashKey += `__${this.apiConfig.league}`;
    hashKey += `__${this.apiConfig.tricode}`;
    hashKey += `__${this.apiConfig.environment}`;
    hashKey += `__${req.path}`;
    if (req.params) {
      for (const e of Object.getOwnPropertyNames(req.params).sort()) {
        hashKey += `__${e}=${req.params[e]}`;
      }
    }
    return hashKey;
  }

  public getRequest(req: ContentfulCDNRequest): ReactiveMicrocomponent<ContentfulCDNResponse> {
    const hashKey = this.getRequestHashKey(req);
    const requestComp = new ManualPassthrough<ContentfulCDNRequest>({ name: `ContentfulCDN__REQUEST__${hashKey}`, saveToLocalStorage: false }, req);
    return new ContentfulCDNQueryComponent(`ContentfulCDN__QUERY__${hashKey}`, this.apiConfig, this.contentful, this.metadataComp, requestComp);
  }

  public getMetadata(): ContentfulCDNMetadata {
    return this.metadataComp.getOutput();
  }
}
