import { YinzCamAPIRequestComponent, YinzCamAPIResponse } from 'yinzcam-api';
import { AbstractReactiveMicrocomponent } from 'yinzcam-rma';
import { YinzCamCardsServiceMenu } from './YinzCamCardsServiceTypes';

export class YinzCamCardsServiceMenuComponent extends AbstractReactiveMicrocomponent<YinzCamCardsServiceMenu, [YinzCamAPIResponse]> {
  public constructor(name: string, srvInput: YinzCamAPIRequestComponent) {
    super({ name }, srvInput);
  }

  protected async update($control: unknown, $srvrsp: YinzCamAPIResponse): Promise<YinzCamCardsServiceMenu> {
    if (!$srvrsp?.data) {
      throw Error('YinzCamCardsServiceMenuComponent: menu data not available');
    }
    return $srvrsp.data as YinzCamCardsServiceMenu;
  }
}
