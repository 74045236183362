<Boundary>
  <!--
    align-items: stretch; /* ensures content fills the entire grid area. A bug noted in desktop news items. Original value of "center" */
    transform: translateZ(0); /* this fixes a bug in Safari rendering where it does not correctly apply overflow: hidden when using a border radius */
    /* see https://stackoverflow.com/questions/49066011/overflow-hidden-with-border-radius-not-working-on-safari */
    transform: translateZ(0);

    {(display)? `display: ${display} !important;` : ''}
  -->
  <CardsComponentWrapper style="
    display: flex;
    justify-content: center;
    align-items: stretch;
    height: {height};
    {cssDisplay}
    {cssVisibility}
    {buildCssBorder(element.border)}
    {(element.border && element.border.radius)? 'overflow: hidden;' : ''}
    {buildCssPadding(element.padding)}
    {buildCssMargin(element.margin)}
    {buildCssBackground(element.background)}
    {buildCssBoxShadow(element.boxShadow)}
    {buildCssFont(element.font)}
  " parentAttributes={{...parentAttributes, 'data-element-class': (element)? element.class : ''}} {editorOptions}>
  {#if showPlaceholder}
    <!--
      {element}

      element={expandedElement}
    -->
    <PlaceholderCard
      element={expandedElement}
      {sources}
      width="100%"
      height="100px"
      title={$pbt(cardComponentName)}
    />
  {:else}
    <!--
      {element}

      element={expandedElement}
      {elementStore}
      {sources}
      bind:sourceStore={childSourceStore}
      {height}
    -->
    <svelte:component
      this={cardComponent}

      element={expandedElement}
      {elementStore}
      {sources}
      bind:sourceStore={childSourceStore}
      {height}
      
      bind:contentAvailable={childContentAvailable}
      bind:contentActive={childContentActive}
      bind:parentAttributes={parentAttributes}
      bind:defaultSourceClasses={childDefaultSourceClasses}
      {...spreadProps}
    />
  {/if}
  </CardsComponentWrapper>
</Boundary>

<style>
  *, *::before, *::after {
    box-sizing: border-box;
  }</style>

<script lang="ts">var _a, _b, _c, _d, _e;
import { getContext, onMount } from "svelte";
import { writable } from "svelte/store";
import "yinzcam-cards";
import PlaceholderCard from "components/cards/library/YinzCam/Core/PlaceholderCard/PlaceholderCard.svelte";
import "./CardsDataSourceRegistration";
import { CardsTabContextKey } from "./context";
import { Boundary } from '@crownframework/svelte-error-boundary/src/index';
import { buildCssBorder, buildCssBackground, buildCssPadding, expandTemplateParams, buildCssMargin, getConditionsCheckStore, buildCssFont, buildCssBoxShadow, getFirstSourceStore } from "../utilities/index";
import _ from "lodash";
import { CardsPageContextKey } from './context';
import './CardsEditorInterfaces';
import CardsComponentWrapper from './CardsComponentWrapper.svelte';
import { CARDS_ELEMENT_SCHEMA, generateElementDataSourcesSchema } from "./CardsEditorComponentSchemata";
import { getNamed } from 'inversify-token';
import ROOT from '../../../inversify.config';
import { CardsLibraryToken } from "./CardsLibraryToken";
import { pbt } from '../../../js/i18n';
import "json-schema";
import '../../../js/utilities';
import "./CardsDataObjectTransform";
import "./CardsDataPrimitiveTransform";
import * as Upcast from "upcast";
import "./CardsDataArrayTransform";
import "../atoms/BaseAtomSpec";
import "../particles/YinzCam";
import { t } from "../../../js/i18n";
export let parentSequenceId = null;
export let sequenceId = null;
export let element;
export let height = 'initial';
export let contentAvailable = true; // exposed upstream for binding
export let contentActive = false; // exposed upstream for binding
export let updater;
const pageContext = getContext(CardsPageContextKey);
const tabContext = getContext(CardsTabContextKey);
const emmEnabled = pageContext.editorModeManager.getEnabledStore();
const emmPreview = pageContext.editorModeManager.getPreviewStore();
const elementStore = writable(element);
const upcast = Upcast.default;
const nullSources = writable([]);
const nullFirstSource = writable(null);
let expandedElement = null;
let mounted = false;
let childContentAvailable; // bound to card component
let childSourceStore; // bound to card component
let cssDisplay = ''; // display CSS on element (default to no change)
let cssVisibility = 'visibility: hidden;'; // visibility CSS on element (default to invisible until content available)
let childContentActive;
let parentAttributes;
let childDefaultSourceClasses;
let sources = nullSources;
//let sourceStore: Readable<any>;
//$: { sourceStore = getFirstSourceStore($sources) }
//$: console.log('SOURCESTORE CHANGED', element.id, $sourceStore);
let sourcesUnsubscribe = null;
let firstSource = nullFirstSource;
let firstSourceUnsubscribe = null;
let firstSourceData = writable(null);
let spreadProps = {};
let inlineStyle = '';
$: mergedParams = (_a = tabContext === null || tabContext === void 0 ? void 0 : tabContext.mergedParamsComp) === null || _a === void 0 ? void 0 : _a.store;
const elementConditionStore = writable(element === null || element === void 0 ? void 0 : element.conditions);
$: elementConditionStore.set(element === null || element === void 0 ? void 0 : element.conditions);
const conditionsCheckStore = getConditionsCheckStore(elementConditionStore);
function updateSources(newSources) {
    if (!newSources) {
        newSources = nullSources;
    }
    if (newSources === sources) {
        return;
    }
    //console.log('updateSources changing sources list', element.id, newSources);
    if (sourcesUnsubscribe) {
        if (firstSourceUnsubscribe) {
            firstSourceUnsubscribe();
            firstSourceUnsubscribe = null;
        }
        sourcesUnsubscribe();
        sourcesUnsubscribe = null;
    }
    sources = newSources;
    sourcesUnsubscribe = sources.subscribe((sourceList) => {
        //console.log('updateSources new source list', element.id, sourceList);
        let newFirstSource = getFirstSourceStore(sourceList);
        if (!newFirstSource) {
            newFirstSource = nullFirstSource;
        }
        //console.log(`updateSources first source is #${getObjectId(newFirstSource)}`, element.id);
        if (newFirstSource === firstSource) {
            return;
        }
        if (firstSourceUnsubscribe) {
            firstSourceUnsubscribe();
            firstSourceUnsubscribe = null;
        }
        firstSource = newFirstSource;
        //console.log(`updateSources changing first source to #${getObjectId(firstSource)}`, element.id);
        firstSourceUnsubscribe = firstSource.subscribe((sourceData) => {
            //console.log('updateSources changing first source data', element.id, sourceData);
            firstSourceData.set(sourceData);
        });
    });
}
function updateElementData($element, $sequenceId, $mergedParams, $firstSourceData, $conditionsCheckStore) {
    //console.log('updateElementData', $element, $mergedParams, $firstSourceData);
    if (!($element === null || $element === void 0 ? void 0 : $element.id) || !$mergedParams) {
        return;
    }
    let newSources;
    if ($sequenceId && $conditionsCheckStore && $element.sourceIds) {
        newSources = tabContext.dataSourceManager.register($element.id, $sequenceId, $element.sourceIds);
    }
    else {
        tabContext.dataSourceManager.unregister($element.id);
        newSources = writable([]);
    }
    updateSources(newSources);
    expandedElement = _.clone($element);
    if ($element.data) {
        const expandedData = {};
        for (const [key, value] of Object.entries($element.data)) {
            if (typeof value === 'string') {
                try {
                    expandedData[key] = expandTemplateParams(value, $mergedParams);
                }
                catch (err) {
                    expandedData[key] = value;
                }
            }
            else {
                expandedData[key] = value;
            }
        }
        expandedElement.data = expandedData;
    }
    //elementHash = hash.MD5(expandedElement);
    //console.log('updateElementData - expanded', elementHash, expandedElement);
    //expandedElement = expandedElement;
    elementStore.set(expandedElement);
    // If the card defines a config spec, create spread props for the card
    /*
    {
      "stringProp1": "...",
      "numberProp2": 12345,
      "booleanProp3": true,
      "dateProp3": new Date(...),
      "urlProp4": new URL(...),
      "arrayProp5": [
        { ... }
      ],
      "objectProp6": {
        "stringProp61": "...",
        ...
      }
    }
    */
    if (cardSpec) {
        const configSpec = cardSpec.getSchema(); //cardLibraryRecord.getElementConfigSpec();
        const transform = element.transform;
        if ((configSpec === null || configSpec === void 0 ? void 0 : configSpec.type) === "object" && _.isPlainObject(configSpec.properties)) {
            ;
            ;
            ;
            ;
            function runTransformTemporary(transformType, sourceData) {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36, _37, _38, _39, _40, _41, _42, _43, _44, _45, _46, _47, _48, _49, _50, _51, _52, _53, _54, _55, _56, _57, _58, _59, _60, _61, _62, _63, _64, _65, _66, _67, _68, _69, _70, _71, _72, _73, _74, _75, _76, _77, _78, _79, _80, _81, _82, _83, _84, _85, _86, _87, _88, _89, _90, _91, _92, _93, _94, _95, _96, _97, _98, _99, _100, _101, _102, _103, _104, _105, _106, _107, _108, _109, _110, _111, _112, _113, _114, _115, _116, _117, _118, _119, _120, _121, _122, _123, _124, _125, _126, _127, _128, _129, _130, _131, _132, _133, _134, _135, _136, _137, _138, _139, _140, _141, _142, _143, _144, _145, _146, _147, _148, _149, _150, _151;
                if (!sourceData || !transformType) {
                    return sourceData;
                }
                function convertContentfulRichTextToArticleBody(data) {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
                    const body = [];
                    if ((data === null || data === void 0 ? void 0 : data.nodeType) === 'document' && _.isArray(data === null || data === void 0 ? void 0 : data.content)) {
                        function getNodeForContainerNode(node) {
                            var _a;
                            const t = node === null || node === void 0 ? void 0 : node.nodeType;
                            if (t === 'ordered-list' || t === 'unordered-list') {
                                return {
                                    type: (t === 'ordered-list') ? 'ol' : 'ul',
                                    nodes: getNodesForListNode(node),
                                };
                            }
                            else if (t === 'table') {
                                return {
                                    type: 'table',
                                    nodes: getNodesForTableNode(node),
                                };
                            }
                            else if (t === 'hyperlink') {
                                return {
                                    type: 'link',
                                    uri: (_a = node === null || node === void 0 ? void 0 : node.data) === null || _a === void 0 ? void 0 : _a.uri,
                                    nodes: getNodesForTextContainerNode(node),
                                };
                            }
                            else if (t === 'paragraph' || t.startsWith('heading-')) {
                                return {
                                    type: 'text-block',
                                    nodes: getNodesForTextContainerNode(node),
                                };
                            }
                        }
                        function getNodesForTextContainerNode(node, allowHyperlink = true, fontSize = '1rem') {
                            var _a, _b, _c;
                            if (!((_a = node === null || node === void 0 ? void 0 : node.content) === null || _a === void 0 ? void 0 : _a.length)) {
                                return [];
                            }
                            const headingRx = /heading-(\d+)/g;
                            const headingMatch = headingRx.exec(node.nodeType);
                            if (headingMatch !== null) {
                                const headingLevel = _.clamp(parseInt(headingMatch[1]), 1, 6);
                                const fontSizeRem = 3.5 - (0.5 * (headingLevel - 1));
                                fontSize = `${fontSizeRem}rem`;
                            }
                            const nodes = [];
                            for (const textNode of node.content) {
                                if ((textNode === null || textNode === void 0 ? void 0 : textNode.nodeType) !== 'text' && (!allowHyperlink || (textNode === null || textNode === void 0 ? void 0 : textNode.nodeType) !== 'hyperlink')) {
                                    console.error(`expected text or hyperlink node under ${node.nodeType} node, got:`, textNode);
                                    continue;
                                }
                                if ((textNode === null || textNode === void 0 ? void 0 : textNode.nodeType) === 'hyperlink') {
                                    nodes.push({
                                        type: 'link',
                                        uri: (_b = textNode === null || textNode === void 0 ? void 0 : textNode.data) === null || _b === void 0 ? void 0 : _b.uri,
                                        nodes: getNodesForTextContainerNode(textNode, false, fontSize),
                                    });
                                }
                                else if (textNode.value) {
                                    // regular text node
                                    const props = {};
                                    props.text = textNode.value;
                                    props.size = fontSize;
                                    if (((_c = textNode.marks) === null || _c === void 0 ? void 0 : _c.length) > 0) {
                                        for (const mark of textNode.marks) {
                                            switch (mark === null || mark === void 0 ? void 0 : mark.type) {
                                                case 'bold':
                                                    props.weight = '700';
                                                    break;
                                                case 'italic':
                                                    props.style = 'italic';
                                                    break;
                                                case 'underline':
                                                    props.decoration = `${props.decoration || ''} underline`.trimStart();
                                                    break;
                                                case 'strikethrough':
                                                    props.decoration = `${props.decoration || ''} line-through`.trimStart();
                                                    break;
                                                case 'code':
                                                    props.family = 'monospace';
                                                    break;
                                                case 'superscript':
                                                    props.subSuper = 'super';
                                                    break;
                                                case 'subscript':
                                                    props.subSuper = 'sub';
                                                    break;
                                            }
                                        }
                                    }
                                    nodes.push({
                                        type: 'text',
                                        particleProps: props,
                                    });
                                }
                            }
                            return nodes;
                        }
                        function getNodesForListNode(node) {
                            var _a, _b;
                            if (!((_a = node === null || node === void 0 ? void 0 : node.content) === null || _a === void 0 ? void 0 : _a.length)) {
                                return [];
                            }
                            const nodes = [];
                            for (const itemNode of node.content) {
                                if ((itemNode === null || itemNode === void 0 ? void 0 : itemNode.nodeType) !== 'list-item') {
                                    console.error(`expected list item node under ${node.type} node, got:`, itemNode);
                                    continue;
                                }
                                if (!((_b = itemNode === null || itemNode === void 0 ? void 0 : itemNode.content) === null || _b === void 0 ? void 0 : _b.length)) {
                                    continue;
                                }
                                const subNodes = [];
                                for (const subNode of itemNode.content) {
                                    const outNode = getNodeForContainerNode(subNode);
                                    if (outNode) {
                                        subNodes.push(outNode);
                                    }
                                }
                                if (subNodes.length > 0) {
                                    nodes.push({
                                        type: 'li',
                                        nodes: subNodes,
                                    });
                                }
                            }
                            return nodes;
                        }
                        function getNodesForTableNode(node) {
                            var _a, _b, _c;
                            if (!((_a = node === null || node === void 0 ? void 0 : node.content) === null || _a === void 0 ? void 0 : _a.length)) {
                                return [];
                            }
                            const nodes = [];
                            for (const rowNode of node.content) {
                                if ((rowNode === null || rowNode === void 0 ? void 0 : rowNode.nodeType) !== 'table-row') {
                                    console.error(`expected table row node under ${node.nodeType} node, got:`, rowNode);
                                    continue;
                                }
                                if (!((_b = rowNode === null || rowNode === void 0 ? void 0 : rowNode.content) === null || _b === void 0 ? void 0 : _b.length)) {
                                    continue;
                                }
                                const subNodes = [];
                                let headerRow = false;
                                for (const cellNode of rowNode.content) {
                                    if ((cellNode === null || cellNode === void 0 ? void 0 : cellNode.nodeType) !== 'table-header-cell' && (cellNode === null || cellNode === void 0 ? void 0 : cellNode.nodeType) !== 'table-cell') {
                                        console.error(`expected table cell under ${rowNode.nodeType} node, got:`, cellNode);
                                        continue;
                                    }
                                    if (!((_c = cellNode === null || cellNode === void 0 ? void 0 : cellNode.content) === null || _c === void 0 ? void 0 : _c.length)) {
                                        continue;
                                    }
                                    headerRow || (headerRow = cellNode.nodeType === 'table-header-cell');
                                    const subSubNodes = [];
                                    for (const containerNode of cellNode.content) {
                                        const outNode = getNodeForContainerNode(containerNode);
                                        if (outNode) {
                                            subSubNodes.push(outNode);
                                        }
                                    }
                                    if (subSubNodes.length > 0) {
                                        subNodes.push({
                                            type: 'table-cell',
                                            nodes: subSubNodes,
                                        });
                                    }
                                }
                                if (subNodes.length > 0) {
                                    nodes.push({
                                        type: (headerRow) ? 'table-header-row' : 'table-row',
                                        nodes: subNodes,
                                    });
                                }
                            }
                            return nodes;
                        }
                        for (const node of data === null || data === void 0 ? void 0 : data.content) {
                            const t = node === null || node === void 0 ? void 0 : node.nodeType;
                            if (t === 'embedded-asset-block') {
                                const fields = (_b = (_a = node === null || node === void 0 ? void 0 : node.data) === null || _a === void 0 ? void 0 : _a.target) === null || _b === void 0 ? void 0 : _b.fields;
                                const file = fields === null || fields === void 0 ? void 0 : fields.file;
                                if (!file) {
                                    console.error(`expected data.target.fields.file node under ${t} node, got:`, node.content);
                                    continue;
                                }
                                let captionProps = undefined;
                                if (fields.description) {
                                    captionProps = {
                                        captionText: {
                                            text: fields.description,
                                        }
                                    };
                                }
                                if ((_c = file === null || file === void 0 ? void 0 : file.contentType) === null || _c === void 0 ? void 0 : _c.startsWith('image/')) {
                                    body.push({
                                        type: 'image',
                                        particleProps: {
                                            source: file.url,
                                            alt: fields.title || file.fileName || "",
                                        },
                                        captionProps,
                                    });
                                }
                                else if ((_d = file === null || file === void 0 ? void 0 : file.contentType) === null || _d === void 0 ? void 0 : _d.startsWith('video/')) {
                                    body.push({
                                        type: 'video',
                                        particleProps: {
                                            source: file.url,
                                        },
                                        captionProps,
                                    });
                                }
                                else if ((_e = file === null || file === void 0 ? void 0 : file.contentType) === null || _e === void 0 ? void 0 : _e.startsWith('audio/')) {
                                    body.push({
                                        type: 'audio',
                                        particleProps: {
                                            source: file.url,
                                        },
                                        captionProps,
                                    });
                                }
                            }
                            else if (t === 'embedded-entry-block') {
                                const contentType = (_k = (_j = (_h = (_g = (_f = node === null || node === void 0 ? void 0 : node.data) === null || _f === void 0 ? void 0 : _f.target) === null || _g === void 0 ? void 0 : _g.sys) === null || _h === void 0 ? void 0 : _h.contentType) === null || _j === void 0 ? void 0 : _j.sys) === null || _k === void 0 ? void 0 : _k.id;
                                const fields = (_m = (_l = node === null || node === void 0 ? void 0 : node.data) === null || _l === void 0 ? void 0 : _l.target) === null || _m === void 0 ? void 0 : _m.fields;
                                if (contentType === 'webEmbed') {
                                    body.push({
                                        type: 'web-embed',
                                        particleProps: {
                                            name: fields.name,
                                            source: fields.url,
                                        },
                                    });
                                }
                            }
                            else {
                                // hyperlink, list, paragraph, heading, ...
                                const outNode = getNodeForContainerNode(node);
                                if (outNode) {
                                    body.push(outNode);
                                }
                            }
                        }
                    }
                    return body;
                }
                let ret = sourceData;
                try {
                    if (transformType === 'contentful-navigation') {
                        //console.log('SOURCE DATA FOR CONTENTFUL NAVIGATION TRANSFORM', sourceData);
                    }
                    else if (transformType === 'contentful-search-metadata') {
                        const title = $t('Search Results for');
                        return {
                            richText: { html: `${title} "${$mergedParams['query']}"` },
                        };
                    }
                    else if (transformType === 'contentful-video-viewer') {
                        //console.log('SOURCE DATA FOR CONTENTFUL VIDEO VIEWER', sourceData);
                        return Object.assign(Object.assign({ slug: sourceData === null || sourceData === void 0 ? void 0 : sourceData.slug, categories: ((sourceData === null || sourceData === void 0 ? void 0 : sourceData.categories) || []).map((c) => ({
                                text: { text: c.name },
                            })), titleText: {
                                text: sourceData === null || sourceData === void 0 ? void 0 : sourceData.name,
                            }, descriptionText: {
                                text: sourceData === null || sourceData === void 0 ? void 0 : sourceData.description,
                            }, publishDateTime: sourceData === null || sourceData === void 0 ? void 0 : sourceData.publishDateTime }, (((_a = sourceData === null || sourceData === void 0 ? void 0 : sourceData.authors) === null || _a === void 0 ? void 0 : _a[0]) && { author: {
                                image: {
                                    source: (_e = (_d = (_c = (_b = sourceData === null || sourceData === void 0 ? void 0 : sourceData.authors) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.primaryImage) === null || _d === void 0 ? void 0 : _d.file) === null || _e === void 0 ? void 0 : _e.url,
                                },
                                nameText: {
                                    text: (_f = sourceData === null || sourceData === void 0 ? void 0 : sourceData.authors) === null || _f === void 0 ? void 0 : _f[0].displayName,
                                },
                                positionText: {
                                    text: (_g = sourceData === null || sourceData === void 0 ? void 0 : sourceData.authors) === null || _g === void 0 ? void 0 : _g[0].description,
                                },
                            } })), ((sourceData === null || sourceData === void 0 ? void 0 : sourceData.videoSourceType) && { video: Object.assign({ sourceType: sourceData === null || sourceData === void 0 ? void 0 : sourceData.videoSourceType, sourceKey: sourceData === null || sourceData === void 0 ? void 0 : sourceData.videoSourceKey, sourceUrl: (_j = (_h = sourceData === null || sourceData === void 0 ? void 0 : sourceData.videoSourceLink) === null || _h === void 0 ? void 0 : _h.file) === null || _j === void 0 ? void 0 : _j.url }, (((_k = sourceData === null || sourceData === void 0 ? void 0 : sourceData.featureImages) === null || _k === void 0 ? void 0 : _k[0]) && { poster: (_o = (_m = (_l = sourceData === null || sourceData === void 0 ? void 0 : sourceData.featureImages) === null || _l === void 0 ? void 0 : _l[0]) === null || _m === void 0 ? void 0 : _m.file) === null || _o === void 0 ? void 0 : _o.url })) }));
                    }
                    else if (transformType === 'contentful-page-metadata') {
                        return {
                            richText: { html: sourceData === null || sourceData === void 0 ? void 0 : sourceData.name },
                            image: { source: (_q = (_p = sourceData === null || sourceData === void 0 ? void 0 : sourceData.leadImage) === null || _p === void 0 ? void 0 : _p.file) === null || _q === void 0 ? void 0 : _q.url }
                        };
                    }
                    else if (transformType === 'contentful-page-cta') {
                        //console.log('SOURCE DATA FOR CONTENTFUL PAGE CTA TRANSFORM', sourceData);
                        return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ link: sourceData === null || sourceData === void 0 ? void 0 : sourceData.link }, ((sourceData === null || sourceData === void 0 ? void 0 : sourceData.featureImage) && { thumbnail: {
                                source: (_s = (_r = sourceData === null || sourceData === void 0 ? void 0 : sourceData.featureImage) === null || _r === void 0 ? void 0 : _r.file) === null || _s === void 0 ? void 0 : _s.url,
                            } })), { headerText: {
                                text: (sourceData === null || sourceData === void 0 ? void 0 : sourceData.title) || '',
                            }, bodyText: {
                                text: (sourceData === null || sourceData === void 0 ? void 0 : sourceData.description) || '',
                            } }), (((_t = sourceData === null || sourceData === void 0 ? void 0 : sourceData.buttons) === null || _t === void 0 ? void 0 : _t[0]) && { ctaButton: Object.assign(Object.assign({}, (((_v = (_u = sourceData === null || sourceData === void 0 ? void 0 : sourceData.buttons) === null || _u === void 0 ? void 0 : _u[0]) === null || _v === void 0 ? void 0 : _v.label) && { text: { text: (_x = (_w = sourceData === null || sourceData === void 0 ? void 0 : sourceData.buttons) === null || _w === void 0 ? void 0 : _w[0]) === null || _x === void 0 ? void 0 : _x.label } })), (((_z = (_y = sourceData === null || sourceData === void 0 ? void 0 : sourceData.buttons) === null || _y === void 0 ? void 0 : _y[0]) === null || _z === void 0 ? void 0 : _z.linkUrl) && { link: (_1 = (_0 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.buttons) === null || _0 === void 0 ? void 0 : _0[0]) === null || _1 === void 0 ? void 0 : _1.linkUrl })) })), (!_.isNil(sourceData === null || sourceData === void 0 ? void 0 : sourceData.applyFeatureImageGradient) && { imageGradient: sourceData === null || sourceData === void 0 ? void 0 : sourceData.applyFeatureImageGradient })), (!_.isNil(sourceData === null || sourceData === void 0 ? void 0 : sourceData.reverseTextAndImage) && { reverseOrientation: sourceData === null || sourceData === void 0 ? void 0 : sourceData.reverseTextAndImage }));
                    }
                    else if (transformType === 'contentful-page-accordion') {
                        return {
                            accordionTabs: ((sourceData === null || sourceData === void 0 ? void 0 : sourceData.items) || []).map((i) => {
                                var _a, _b;
                                return ({
                                    heading: { text: i === null || i === void 0 ? void 0 : i.name },
                                    articleBody: convertContentfulRichTextToArticleBody((_a = i === null || i === void 0 ? void 0 : i.content) === null || _a === void 0 ? void 0 : _a.body),
                                    linkUrl: (_b = i === null || i === void 0 ? void 0 : i.content) === null || _b === void 0 ? void 0 : _b.linkUrl,
                                });
                            }),
                        };
                    }
                    else if (transformType === 'contentful-pages-as-buttons') {
                        console.log('SOURCE DATA FOR CONTENTFUL PAGES AS BUTTONS', sourceData);
                        return {
                            button: {
                                text: { text: sourceData === null || sourceData === void 0 ? void 0 : sourceData.name },
                                link: (sourceData === null || sourceData === void 0 ? void 0 : sourceData.linkUrl) || (sourceData === null || sourceData === void 0 ? void 0 : sourceData.slug),
                            },
                        };
                    }
                    else if (transformType === 'yinzcam-stats-standings') {
                        //console.log('SOURCE DATA FOR YINZCAM STANDINGS TRANSFORM', sourceData);
                        const standingsRoot = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Standings;
                        const tableColumns = [];
                        const statsGroup = ((_3 = (_2 = standingsRoot === null || standingsRoot === void 0 ? void 0 : standingsRoot.Division) === null || _2 === void 0 ? void 0 : _2.StatsGroup) === null || _3 === void 0 ? void 0 : _3._attributes) || {};
                        for (let i = 3; statsGroup["Stat" + i]; i += 1) {
                            tableColumns.push(statsGroup["Stat" + i]);
                        }
                        const standings = [].concat((_4 = standingsRoot === null || standingsRoot === void 0 ? void 0 : standingsRoot.Division) === null || _4 === void 0 ? void 0 : _4.Standing).filter(e => e);
                        const tableData = standings === null || standings === void 0 ? void 0 : standings.map((teamStanding, index) => {
                            var _a, _b, _c, _d, _e;
                            const teamTriCode = (_a = teamStanding._attributes) === null || _a === void 0 ? void 0 : _a.TriCode;
                            const chevronCode = (_b = teamStanding._attributes) === null || _b === void 0 ? void 0 : _b.Change;
                            let chevronStatus = 'NONE';
                            if (chevronCode === 'U') {
                                chevronStatus = 'UP';
                            }
                            else if (chevronCode === 'D') {
                                chevronStatus = 'DOWN';
                            }
                            let q = NaN;
                            try {
                                q = parseInt((_c = teamStanding._attributes) === null || _c === void 0 ? void 0 : _c.Qualification);
                            }
                            catch (e) {
                                console.log('unable to parse qualification from standings response', e);
                            }
                            let championStatus = '';
                            if (isNaN(q)) {
                                if (index < 4) {
                                    championStatus = 'GREEN';
                                }
                                else if (index < 6) {
                                    championStatus = 'YELLOW';
                                }
                                else if (index > 16) {
                                    championStatus = 'RED';
                                }
                            }
                            else {
                                if (q === 1) {
                                    championStatus = 'GREEN';
                                }
                                else if (q === 4) {
                                    championStatus = 'YELLOW';
                                }
                                else if (q === 8) {
                                    championStatus = 'RED';
                                }
                            }
                            let retObj = {
                                position: (_d = teamStanding._attributes) === null || _d === void 0 ? void 0 : _d.LeagueRank,
                                teamLogo: `https://resources-uk.yinzcam.com/soccer/shared/logos/fa_${teamTriCode === null || teamTriCode === void 0 ? void 0 : teamTriCode.toLowerCase()}_light.png`,
                                teamTriCode,
                                teamName: (_e = teamStanding._attributes) === null || _e === void 0 ? void 0 : _e.Team,
                                upDown: chevronStatus,
                                recordType: championStatus,
                            };
                            tableColumns.forEach((e, i) => {
                                var _a, _b;
                                retObj[e] = ((_b = (_a = teamStanding.StatsGroup) === null || _a === void 0 ? void 0 : _a._attributes) === null || _b === void 0 ? void 0 : _b["Stat" + (i + 3)]) || "";
                            });
                            return retObj;
                        });
                        return {
                            tableColumns,
                            tableData
                        };
                    }
                    else if (transformType === 'yinzcam-game-list') {
                        //console.log('SOURCE DATA FOR YC GAME LIST TRANSFORM', sourceData);
                        return {
                            matchStatus: ('C' === (sourceData === null || sourceData === void 0 ? void 0 : sourceData.State)) ? 'live' : ('F' === (sourceData === null || sourceData === void 0 ? void 0 : sourceData.State)) ? 'post' : 'pre',
                            imageHomeTeam: { source: (_5 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam) === null || _5 === void 0 ? void 0 : _5.ImageUrl },
                            textHomeTeam: { text: (_6 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam) === null || _6 === void 0 ? void 0 : _6.TriCode },
                            imageAwayTeam: { source: (_7 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayTeam) === null || _7 === void 0 ? void 0 : _7.ImageUrl },
                            textAwayTeam: { text: (_8 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayTeam) === null || _8 === void 0 ? void 0 : _8.TriCode },
                            btnCupName: Object.assign(Object.assign({}, (((_9 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Competition) === null || _9 === void 0 ? void 0 : _9.LogoUrl) && { image: { source: (_10 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Competition) === null || _10 === void 0 ? void 0 : _10.LogoUrl } })), (((_11 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Competition) === null || _11 === void 0 ? void 0 : _11.Name) && !((_12 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Competition) === null || _12 === void 0 ? void 0 : _12.LogoUrl) && { text: { text: (_13 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Competition) === null || _13 === void 0 ? void 0 : _13.Name } })),
                            matchDateTime: sourceData === null || sourceData === void 0 ? void 0 : sourceData.Timestamp,
                            textStadiumName: { text: sourceData === null || sourceData === void 0 ? void 0 : sourceData.Venue },
                            btnBroadcasters: ((sourceData === null || sourceData === void 0 ? void 0 : sourceData.BroadcasterUrls) || [])
                                .map((b) => {
                                return Object.assign(Object.assign({}, ((b === null || b === void 0 ? void 0 : b.ImageUrl) && { image: { source: b === null || b === void 0 ? void 0 : b.ImageUrl } })), ((b === null || b === void 0 ? void 0 : b.LinkUrl) && { link: b === null || b === void 0 ? void 0 : b.LinkUrl }));
                            }),
                            btnActions: ((sourceData === null || sourceData === void 0 ? void 0 : sourceData.Buttons) || [])
                                //.filter((b) => !b?.WebActionUrl?.includes('GAME_CENTER'))
                                .filter((b) => { var _a; return !((_a = b === null || b === void 0 ? void 0 : b.WebActionUrl) === null || _a === void 0 ? void 0 : _a.includes('GAME_HIGHLIGHTS')); })
                                .map((b) => {
                                return Object.assign(Object.assign(Object.assign({}, ((b === null || b === void 0 ? void 0 : b.ImageUrl) && { image: { source: b === null || b === void 0 ? void 0 : b.ImageUrl } })), ((b === null || b === void 0 ? void 0 : b.Title) && { text: { text: b === null || b === void 0 ? void 0 : b.Title } })), (((b === null || b === void 0 ? void 0 : b.WebActionUrl) || (b === null || b === void 0 ? void 0 : b.LinkUrl)) && { link: (b === null || b === void 0 ? void 0 : b.WebActionUrl) || (b === null || b === void 0 ? void 0 : b.LinkUrl) }));
                            }),
                            textHomeScore: { text: sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeScore },
                            textAwayScore: { text: sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayScore },
                            textMatchMinutes: { text: sourceData === null || sourceData === void 0 ? void 0 : sourceData.Clock },
                            // extras for fixtures card, needs to be aligned with above
                            imgLeaguelogo: { source: (_14 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Competition) === null || _14 === void 0 ? void 0 : _14.LogoUrlSquare },
                            txtStadiumName: { text: sourceData === null || sourceData === void 0 ? void 0 : sourceData.Venue },
                            txtHomeTeam: { text: ((_15 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam) === null || _15 === void 0 ? void 0 : _15.ShortName) || ((_16 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam) === null || _16 === void 0 ? void 0 : _16.Name) },
                            txtAwayTeam: { text: ((_17 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayTeam) === null || _17 === void 0 ? void 0 : _17.ShortName) || ((_18 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayTeam) === null || _18 === void 0 ? void 0 : _18.Name) },
                            txtHomeTeamAbbr: { text: (_19 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam) === null || _19 === void 0 ? void 0 : _19.TriCode },
                            txtAwayTeamAbbr: { text: (_20 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayTeam) === null || _20 === void 0 ? void 0 : _20.TriCode },
                            imgHomeTeam: { source: (_21 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam) === null || _21 === void 0 ? void 0 : _21.ImageUrl },
                            imgAwayTeam: { source: (_22 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayTeam) === null || _22 === void 0 ? void 0 : _22.ImageUrl },
                            homeTeamScore: { text: sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeScore },
                            awayTeamScore: { text: sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayScore },
                            matchMinutes: { text: sourceData === null || sourceData === void 0 ? void 0 : sourceData.Clock },
                            btnCTA: ((sourceData === null || sourceData === void 0 ? void 0 : sourceData.Buttons) || [])
                                //.filter((b) => !b?.WebActionUrl?.includes('GAME_CENTER'))
                                .filter((b) => { var _a; return !((_a = b === null || b === void 0 ? void 0 : b.WebActionUrl) === null || _a === void 0 ? void 0 : _a.includes('GAME_HIGHLIGHTS')); })
                                .map((b) => {
                                return Object.assign(Object.assign(Object.assign({}, ((b === null || b === void 0 ? void 0 : b.ImageUrl) && { image: { source: b === null || b === void 0 ? void 0 : b.ImageUrl } })), ((b === null || b === void 0 ? void 0 : b.Title) && { text: { text: b === null || b === void 0 ? void 0 : b.Title } })), (((b === null || b === void 0 ? void 0 : b.WebActionUrl) || (b === null || b === void 0 ? void 0 : b.LinkUrl)) && { link: (b === null || b === void 0 ? void 0 : b.WebActionUrl) || (b === null || b === void 0 ? void 0 : b.LinkUrl) }));
                            }),
                        };
                    }
                    else if (transformType === 'yinzcam-stats-player') {
                        //console.log('SOURCE DATA FOR YINZCAM STATS PLAYER', sourceData);
                        // name parsing
                        const shortName = (_24 = (_23 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.PlayerCard) === null || _23 === void 0 ? void 0 : _23.ShortName) === null || _24 === void 0 ? void 0 : _24._text;
                        const longName = (_26 = (_25 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.PlayerCard) === null || _25 === void 0 ? void 0 : _25.Name) === null || _26 === void 0 ? void 0 : _26._text;
                        let firstName = "";
                        let lastName = "";
                        if (shortName && longName) {
                            const toks = longName.split(shortName, 2);
                            if ((toks === null || toks === void 0 ? void 0 : toks.length) === 2) {
                                firstName = toks[0];
                                lastName = shortName;
                            }
                            else {
                                const toks2 = shortName.split(" ", 2);
                                if ((toks2 === null || toks2 === void 0 ? void 0 : toks2.length) === 2) {
                                    firstName = toks2[0];
                                    lastName = toks2[1];
                                }
                                else {
                                    lastName = shortName;
                                }
                            }
                        }
                        else if (shortName) {
                            const toks2 = shortName.split(" ", 2);
                            if ((toks2 === null || toks2 === void 0 ? void 0 : toks2.length) === 2) {
                                firstName = toks2[0];
                                lastName = toks2[1];
                            }
                            else {
                                lastName = shortName;
                            }
                        }
                        else if (longName) {
                            const toks2 = longName.split(" ", 2);
                            if ((toks2 === null || toks2 === void 0 ? void 0 : toks2.length) === 2) {
                                firstName = toks2[0];
                                lastName = toks2[1];
                            }
                            else {
                                lastName = longName;
                            }
                        }
                        return {
                            txtFirstName: { text: firstName },
                            txtLastName: { text: lastName },
                            txtPlayerName: { text: (_28 = (_27 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.PlayerCard) === null || _27 === void 0 ? void 0 : _27.ShortName) === null || _28 === void 0 ? void 0 : _28._text },
                            txtDOB: { text: (_30 = (_29 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.PlayerCard) === null || _29 === void 0 ? void 0 : _29.BirthDate) === null || _30 === void 0 ? void 0 : _30._text },
                            txtPOB: { text: (_32 = (_31 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.PlayerCard) === null || _31 === void 0 ? void 0 : _31.BirthPlace) === null || _32 === void 0 ? void 0 : _32._text },
                            txtHeight: { text: (_34 = (_33 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.PlayerCard) === null || _33 === void 0 ? void 0 : _33.Height) === null || _34 === void 0 ? void 0 : _34._text },
                            txtWeight: { text: (_36 = (_35 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.PlayerCard) === null || _35 === void 0 ? void 0 : _35.Weight) === null || _36 === void 0 ? void 0 : _36._text },
                            imgPlayerImage: { source: ((_38 = (_37 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.PlayerCard) === null || _37 === void 0 ? void 0 : _37.HeadlineImageUrl) === null || _38 === void 0 ? void 0 : _38._text) || ((_40 = (_39 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.PlayerCard) === null || _39 === void 0 ? void 0 : _39.ImageUrl) === null || _40 === void 0 ? void 0 : _40._text) },
                            txtPlayerNumber: { text: (_42 = (_41 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.PlayerCard) === null || _41 === void 0 ? void 0 : _41.Number) === null || _42 === void 0 ? void 0 : _42._text },
                            txtPlayerPosition: { text: (_44 = (_43 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.PlayerCard) === null || _43 === void 0 ? void 0 : _43.Position) === null || _44 === void 0 ? void 0 : _44._text },
                            playerStats: ((_45 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Stats) === null || _45 === void 0 ? void 0 : _45.Stat).map(stat => {
                                var _a, _b, _c, _d, _e, _f, _g;
                                return Object.assign(Object.assign(Object.assign({}, (((_a = stat === null || stat === void 0 ? void 0 : stat._attributes) === null || _a === void 0 ? void 0 : _a.Name) && { name: { text: (_b = stat === null || stat === void 0 ? void 0 : stat._attributes) === null || _b === void 0 ? void 0 : _b.Name } })), (((_c = stat === null || stat === void 0 ? void 0 : stat._attributes) === null || _c === void 0 ? void 0 : _c.HomeValue) && { homeValue: { text: (_d = stat === null || stat === void 0 ? void 0 : stat._attributes) === null || _d === void 0 ? void 0 : _d.HomeValue } })), (((_e = stat === null || stat === void 0 ? void 0 : stat._attributes) === null || _e === void 0 ? void 0 : _e.Name) && { highlightColor: ((_f = stat === null || stat === void 0 ? void 0 : stat._attributes) === null || _f === void 0 ? void 0 : _f.Name.toLowerCase()) === 'yellow cards' ? "#F6BE00" : ((_g = stat === null || stat === void 0 ? void 0 : stat._attributes) === null || _g === void 0 ? void 0 : _g.Name.toLowerCase()) === 'red cards' ? "#D20028" : "" }));
                            }),
                            txtSummary: { text: (_47 = (_46 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.PlayerCard) === null || _46 === void 0 ? void 0 : _46.HtmlBio) === null || _47 === void 0 ? void 0 : _47._text },
                        };
                    }
                    else if (transformType === 'yinzcam-game-scores') {
                        //console.log('SOURCE DATA FOR YINZCAM GAME SCORES', sourceData);
                        return {
                            matchStatus: ('C' === ((_48 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.State) === null || _48 === void 0 ? void 0 : _48._text)) ? 'live' : ('F' === ((_49 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.State) === null || _49 === void 0 ? void 0 : _49._text)) ? 'post' : 'pre',
                            imageHomeTeam: { source: (_51 = (_50 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam) === null || _50 === void 0 ? void 0 : _50.ImageUrl) === null || _51 === void 0 ? void 0 : _51._text },
                            textHomeTeam: { text: (_53 = (_52 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam) === null || _52 === void 0 ? void 0 : _52._attributes) === null || _53 === void 0 ? void 0 : _53.TriCode },
                            imageAwayTeam: { source: (_55 = (_54 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayTeam) === null || _54 === void 0 ? void 0 : _54.ImageUrl) === null || _55 === void 0 ? void 0 : _55._text },
                            textAwayTeam: { text: (_57 = (_56 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayTeam) === null || _56 === void 0 ? void 0 : _56._attributes) === null || _57 === void 0 ? void 0 : _57.TriCode },
                            btnCupName: Object.assign(Object.assign({}, (((_58 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Competition) === null || _58 === void 0 ? void 0 : _58.LogoUrl) && { image: { source: (_60 = (_59 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Competition) === null || _59 === void 0 ? void 0 : _59.LogoUrl) === null || _60 === void 0 ? void 0 : _60._text } })), (((_62 = (_61 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Competition) === null || _61 === void 0 ? void 0 : _61._attributes) === null || _62 === void 0 ? void 0 : _62.Name) && !((_63 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Competition) === null || _63 === void 0 ? void 0 : _63.LogoUrl) && { text: { text: (_65 = (_64 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Competition) === null || _64 === void 0 ? void 0 : _64._attributes) === null || _65 === void 0 ? void 0 : _65.Name } })),
                            matchDateTime: (_66 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Timestamp) === null || _66 === void 0 ? void 0 : _66._text,
                            textStadiumName: { text: (_67 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Venue) === null || _67 === void 0 ? void 0 : _67._text },
                            btnBroadcasters: (_69 = [].concat((_68 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.BroadcasterUrls) === null || _68 === void 0 ? void 0 : _68.Url)) === null || _69 === void 0 ? void 0 : _69.map((b) => {
                                var _a, _b, _c;
                                return Object.assign(Object.assign({}, (((_a = b === null || b === void 0 ? void 0 : b.ImageUrl) === null || _a === void 0 ? void 0 : _a._text) && { image: { source: b === null || b === void 0 ? void 0 : b.ImageUrl._text } })), (((_b = b === null || b === void 0 ? void 0 : b._attributes) === null || _b === void 0 ? void 0 : _b.ClickthroughUrl) && { link: (_c = b === null || b === void 0 ? void 0 : b._attributes) === null || _c === void 0 ? void 0 : _c.ClickthroughUrl }));
                            }),
                            btnActions: (_72 = (_71 = ([].concat((_70 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Buttons) === null || _70 === void 0 ? void 0 : _70.Button) || [])) === null || _71 === void 0 ? void 0 : _71.filter((b) => { var _a, _b; return !((_b = (_a = b === null || b === void 0 ? void 0 : b.WebActionURL) === null || _a === void 0 ? void 0 : _a._text) === null || _b === void 0 ? void 0 : _b.includes('GAME_HIGHLIGHTS')); })) === null || _72 === void 0 ? void 0 : _72.map((b) => {
                                var _a, _b, _c, _d, _e, _f;
                                return Object.assign(Object.assign(Object.assign({}, ((b === null || b === void 0 ? void 0 : b.ImageUrl) && { image: { source: (_a = b === null || b === void 0 ? void 0 : b.ImageUrl) === null || _a === void 0 ? void 0 : _a._text } })), ((b === null || b === void 0 ? void 0 : b.Title) && { text: { text: (_b = b === null || b === void 0 ? void 0 : b.Title) === null || _b === void 0 ? void 0 : _b._text } })), ((((_c = b === null || b === void 0 ? void 0 : b.WebActionURL) === null || _c === void 0 ? void 0 : _c._text) || ((_d = b === null || b === void 0 ? void 0 : b.URL) === null || _d === void 0 ? void 0 : _d._text)) && { link: ((_e = b === null || b === void 0 ? void 0 : b.WebActionURL) === null || _e === void 0 ? void 0 : _e._text) || ((_f = b === null || b === void 0 ? void 0 : b.URL) === null || _f === void 0 ? void 0 : _f._text) }));
                            }),
                            textHomeScore: { text: (_74 = (_73 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam) === null || _73 === void 0 ? void 0 : _73._attributes) === null || _74 === void 0 ? void 0 : _74.Score },
                            textAwayScore: { text: (_76 = (_75 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayTeam) === null || _75 === void 0 ? void 0 : _75._attributes) === null || _76 === void 0 ? void 0 : _76.Score },
                            textMatchMinutes: { text: (_77 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Clock) === null || _77 === void 0 ? void 0 : _77._text },
                            // extras for fixtures card, needs to be aligned with above
                            imgLeaguelogo: { source: (_79 = (_78 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Competition) === null || _78 === void 0 ? void 0 : _78.LogoUrlSquare) === null || _79 === void 0 ? void 0 : _79._text },
                            txtStadiumName: { text: (_80 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Venue) === null || _80 === void 0 ? void 0 : _80._text },
                            txtHomeTeam: { text: ((_82 = (_81 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam) === null || _81 === void 0 ? void 0 : _81._attributes) === null || _82 === void 0 ? void 0 : _82.ShortName) || ((_84 = (_83 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam) === null || _83 === void 0 ? void 0 : _83._attributes) === null || _84 === void 0 ? void 0 : _84.Name) },
                            txtAwayTeam: { text: ((_86 = (_85 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayTeam) === null || _85 === void 0 ? void 0 : _85._attributes) === null || _86 === void 0 ? void 0 : _86.ShortName) || ((_88 = (_87 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayTeam) === null || _87 === void 0 ? void 0 : _87._attributes) === null || _88 === void 0 ? void 0 : _88.Name) },
                            txtHomeTeamAbbr: { text: (_90 = (_89 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam) === null || _89 === void 0 ? void 0 : _89._attributes) === null || _90 === void 0 ? void 0 : _90.TriCode },
                            txtAwayTeamAbbr: { text: (_92 = (_91 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayTeam) === null || _91 === void 0 ? void 0 : _91._attributes) === null || _92 === void 0 ? void 0 : _92.TriCode },
                            imgHomeTeam: { source: (_94 = (_93 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam) === null || _93 === void 0 ? void 0 : _93.ImageUrl) === null || _94 === void 0 ? void 0 : _94._text },
                            imgAwayTeam: { source: (_96 = (_95 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayTeam) === null || _95 === void 0 ? void 0 : _95.ImageUrl) === null || _96 === void 0 ? void 0 : _96._text },
                            homeTeamScore: { text: (_98 = (_97 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.HomeTeam) === null || _97 === void 0 ? void 0 : _97._attributes) === null || _98 === void 0 ? void 0 : _98.Score },
                            awayTeamScore: { text: (_100 = (_99 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.AwayTeam) === null || _99 === void 0 ? void 0 : _99._attributes) === null || _100 === void 0 ? void 0 : _100.Score },
                            matchMinutes: { text: (_101 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Clock) === null || _101 === void 0 ? void 0 : _101._text },
                            btnCTA: ([].concat((_102 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Buttons) === null || _102 === void 0 ? void 0 : _102.Button) || [])
                                //.filter((b) => !b?.WebActionURL?._text?.includes('GAME_CENTER'))
                                .filter((b) => { var _a, _b; return !((_b = (_a = b === null || b === void 0 ? void 0 : b.WebActionURL) === null || _a === void 0 ? void 0 : _a._text) === null || _b === void 0 ? void 0 : _b.includes('GAME_HIGHLIGHTS')); })
                                .map((b) => {
                                var _a, _b, _c, _d, _e, _f;
                                return Object.assign(Object.assign(Object.assign({}, ((b === null || b === void 0 ? void 0 : b.ImageURL) && { image: { source: (_a = b === null || b === void 0 ? void 0 : b.ImageURL) === null || _a === void 0 ? void 0 : _a._text } })), ((b === null || b === void 0 ? void 0 : b.Title) && { text: { text: (_b = b === null || b === void 0 ? void 0 : b.Title) === null || _b === void 0 ? void 0 : _b._text } })), ((((_c = b === null || b === void 0 ? void 0 : b.WebActionURL) === null || _c === void 0 ? void 0 : _c._text) || ((_d = b === null || b === void 0 ? void 0 : b.URL) === null || _d === void 0 ? void 0 : _d._text)) && { link: ((_e = b === null || b === void 0 ? void 0 : b.WebActionURL) === null || _e === void 0 ? void 0 : _e._text) || ((_f = b === null || b === void 0 ? void 0 : b.URL) === null || _f === void 0 ? void 0 : _f._text) }));
                            }),
                        };
                    }
                    else if (transformType === 'contentful-media') {
                        //console.log('SOURCE DATA FOR CONTENTFUL MEDIA TRANSFORM', sourceData);
                        return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ slug: sourceData === null || sourceData === void 0 ? void 0 : sourceData.slug, publishDateTime: sourceData === null || sourceData === void 0 ? void 0 : sourceData.publishDateTime }, (((_103 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.featureImages) === null || _103 === void 0 ? void 0 : _103[0]) && { thumbnail: {
                                source: (_106 = (_105 = (_104 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.featureImages) === null || _104 === void 0 ? void 0 : _104[0]) === null || _105 === void 0 ? void 0 : _105.file) === null || _106 === void 0 ? void 0 : _106.url,
                            } })), (((_108 = (_107 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.featureImages) === null || _107 === void 0 ? void 0 : _107[0]) === null || _108 === void 0 ? void 0 : _108.description) && { caption: {
                                captionText: {
                                    text: (_110 = (_109 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.featureImages) === null || _109 === void 0 ? void 0 : _109[0]) === null || _110 === void 0 ? void 0 : _110.description,
                                }
                            } })), { categories: ((sourceData === null || sourceData === void 0 ? void 0 : sourceData.categories) || []).map((c) => ({
                                text: { text: c.name },
                            })), titleText: {
                                text: sourceData === null || sourceData === void 0 ? void 0 : sourceData.name,
                            }, descriptionText: {
                                text: sourceData === null || sourceData === void 0 ? void 0 : sourceData.description,
                            } }), (((_111 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.authors) === null || _111 === void 0 ? void 0 : _111[0]) && { author: {
                                image: {
                                    source: (_115 = (_114 = (_113 = (_112 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.authors) === null || _112 === void 0 ? void 0 : _112[0]) === null || _113 === void 0 ? void 0 : _113.primaryImage) === null || _114 === void 0 ? void 0 : _114.file) === null || _115 === void 0 ? void 0 : _115.url,
                                },
                                nameText: {
                                    text: (_116 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.authors) === null || _116 === void 0 ? void 0 : _116[0].displayName,
                                },
                                positionText: {
                                    text: (_117 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.authors) === null || _117 === void 0 ? void 0 : _117[0].description,
                                },
                            } })), ((sourceData === null || sourceData === void 0 ? void 0 : sourceData.videoSourceType) && { video: Object.assign({ sourceType: sourceData === null || sourceData === void 0 ? void 0 : sourceData.videoSourceType, sourceKey: sourceData === null || sourceData === void 0 ? void 0 : sourceData.videoSourceKey, sourceUrl: (_119 = (_118 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.videoSourceLink) === null || _118 === void 0 ? void 0 : _118.file) === null || _119 === void 0 ? void 0 : _119.url }, (((_120 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.featureImages) === null || _120 === void 0 ? void 0 : _120[0]) && { poster: (_123 = (_122 = (_121 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.featureImages) === null || _121 === void 0 ? void 0 : _121[0]) === null || _122 === void 0 ? void 0 : _122.file) === null || _123 === void 0 ? void 0 : _123.url })) })), ((sourceData === null || sourceData === void 0 ? void 0 : sourceData.mediaType) && { mediaType: sourceData === null || sourceData === void 0 ? void 0 : sourceData.mediaType.toLowerCase() }));
                    }
                    else if (transformType === 'contentful-article-header' || transformType === 'contentful-media-header') {
                        //console.log('SOURCE DATA FOR CONTENTFUL ARTICLE HEADER TRANSFORM', sourceData);
                        return Object.assign(Object.assign(Object.assign({ slug: sourceData === null || sourceData === void 0 ? void 0 : sourceData.slug, publishDateTime: sourceData === null || sourceData === void 0 ? void 0 : sourceData.publishDateTime }, ((sourceData === null || sourceData === void 0 ? void 0 : sourceData.duration) > 0 && { viewTimeDuration: `PT${Math.round((sourceData === null || sourceData === void 0 ? void 0 : sourceData.duration) * 60)}S` })), { titleText: {
                                text: sourceData === null || sourceData === void 0 ? void 0 : sourceData.name,
                            }, descriptionText: {
                                text: sourceData === null || sourceData === void 0 ? void 0 : sourceData.description,
                            } }), (((_124 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.authors) === null || _124 === void 0 ? void 0 : _124[0]) && { author: {
                                image: {
                                    source: (_127 = (_126 = (_125 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.authors) === null || _125 === void 0 ? void 0 : _125[0].primaryImage) === null || _126 === void 0 ? void 0 : _126.file) === null || _127 === void 0 ? void 0 : _127.url,
                                },
                                nameText: {
                                    text: (_128 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.authors) === null || _128 === void 0 ? void 0 : _128[0].displayName,
                                },
                                positionText: {
                                    text: (_129 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.authors) === null || _129 === void 0 ? void 0 : _129[0].description,
                                },
                            } }));
                    }
                    else if (transformType === 'contentful-article-body' || transformType === 'contentful-page-article') {
                        //console.log('SOURCE DATA FOR CONTENTFUL ARTICLE BODY TRANSFORM', sourceData);
                        ret = _.cloneDeep(sourceData);
                        ret.body = convertContentfulRichTextToArticleBody(((_130 = ret.articleBody) === null || _130 === void 0 ? void 0 : _130.body) || ret.body);
                    }
                    else if (transformType === 'contentful-gallery-viewer') {
                        return Object.assign(Object.assign({ slug: sourceData === null || sourceData === void 0 ? void 0 : sourceData.slug }, (((_131 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.authors) === null || _131 === void 0 ? void 0 : _131[0]) && { photoAuthor: {
                                image: {
                                    source: (_134 = (_133 = (_132 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.authors) === null || _132 === void 0 ? void 0 : _132[0].primaryImage) === null || _133 === void 0 ? void 0 : _133.file) === null || _134 === void 0 ? void 0 : _134.url,
                                },
                                nameText: {
                                    text: (_135 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.authors) === null || _135 === void 0 ? void 0 : _135[0].displayName,
                                },
                                positionText: {
                                    text: (_136 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.authors) === null || _136 === void 0 ? void 0 : _136[0].description,
                                },
                            } })), (((_137 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.photos) === null || _137 === void 0 ? void 0 : _137[0]) && { photos: (_138 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.photos) === null || _138 === void 0 ? void 0 : _138.map((item) => {
                                var _a;
                                return {
                                    source: (_a = item === null || item === void 0 ? void 0 : item.file) === null || _a === void 0 ? void 0 : _a.url,
                                    title: item === null || item === void 0 ? void 0 : item.title,
                                    description: item === null || item === void 0 ? void 0 : item.description,
                                };
                            }) }));
                    }
                    else if (transformType === 'contentful-site-navigation') {
                        return {
                            slug: sourceData === null || sourceData === void 0 ? void 0 : sourceData.slug,
                            headingText: { text: sourceData === null || sourceData === void 0 ? void 0 : sourceData.title },
                            pages: ((_139 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.pages) === null || _139 === void 0 ? void 0 : _139.map(page => {
                                return Object.assign(Object.assign({}, ((page === null || page === void 0 ? void 0 : page.title) && { pageName: { text: page === null || page === void 0 ? void 0 : page.title } })), ((page === null || page === void 0 ? void 0 : page.linkUrl) && { linkUrl: page === null || page === void 0 ? void 0 : page.linkUrl }));
                            }))
                        };
                    }
                    else if (transformType === 'yinzcam-team-roster') {
                        //console.log('YINZCAM TEAM ROSTER TRANSFORM', sourceData);
                        if (!sourceData) {
                            return;
                        }
                        return {
                            imgPlayerImage: { source: ((_140 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.CardHeadshotImageUrl) === null || _140 === void 0 ? void 0 : _140._text) || ((_141 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.ImageUrl) === null || _141 === void 0 ? void 0 : _141._text) },
                            txtFirstName: { text: (_143 = (_142 = sourceData === null || sourceData === void 0 ? void 0 : sourceData._attributes) === null || _142 === void 0 ? void 0 : _142.Name) === null || _143 === void 0 ? void 0 : _143.split(" ")[0] },
                            txtLastName: { text: (_145 = (_144 = sourceData === null || sourceData === void 0 ? void 0 : sourceData._attributes) === null || _144 === void 0 ? void 0 : _144.Name) === null || _145 === void 0 ? void 0 : _145.split(" ")[1] },
                            txtPlayerNumber: { text: (_146 = sourceData === null || sourceData === void 0 ? void 0 : sourceData._attributes) === null || _146 === void 0 ? void 0 : _146.Number },
                            txtPlayerPosition: { text: (_147 = sourceData === null || sourceData === void 0 ? void 0 : sourceData._attributes) === null || _147 === void 0 ? void 0 : _147.PositionFull },
                            playerStats: (_149 = ((_148 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.Stats) === null || _148 === void 0 ? void 0 : _148.Stat)) === null || _149 === void 0 ? void 0 : _149.map(stat => {
                                var _a, _b, _c, _d, _e, _f, _g;
                                return Object.assign(Object.assign(Object.assign({}, (((_a = stat === null || stat === void 0 ? void 0 : stat._attributes) === null || _a === void 0 ? void 0 : _a.Name) && { name: { text: (_b = stat === null || stat === void 0 ? void 0 : stat._attributes) === null || _b === void 0 ? void 0 : _b.Name } })), (((_c = stat === null || stat === void 0 ? void 0 : stat._attributes) === null || _c === void 0 ? void 0 : _c.HomeValue) && { value: { text: (_d = stat === null || stat === void 0 ? void 0 : stat._attributes) === null || _d === void 0 ? void 0 : _d.HomeValue } })), (((_e = stat === null || stat === void 0 ? void 0 : stat._attributes) === null || _e === void 0 ? void 0 : _e.Name) && { highlightColor: ((_f = stat === null || stat === void 0 ? void 0 : stat._attributes) === null || _f === void 0 ? void 0 : _f.Name.toLowerCase()) === 'yellow cards' ? "#F6BE00" : ((_g = stat === null || stat === void 0 ? void 0 : stat._attributes) === null || _g === void 0 ? void 0 : _g.Name.toLowerCase()) === 'red cards' ? "#D20028" : "" }));
                            }),
                            btnPlayerBio: { link: (_151 = (_150 = sourceData === null || sourceData === void 0 ? void 0 : sourceData.CardData) === null || _150 === void 0 ? void 0 : _150.WebActionURL) === null || _151 === void 0 ? void 0 : _151._text },
                        };
                    }
                    else {
                        console.log('unrecognized transform', transform);
                    }
                }
                catch (e) {
                    console.log('transform threw exception', transform, e);
                }
                return ret;
            }
            function mapSourcesToProps(specData, transformData, sourceData, elementData) {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
                if (specData.schema.type !== "object") {
                    throw new Error('mapSourceToProps spec must be for an object');
                }
                if (_.isString(transformData.transform)) {
                    sourceData.data = runTransformTemporary(transformData.transform, sourceData.data);
                    transformData = {};
                }
                const ret = {};
                for (const [key, propSpec] of Object.entries(specData.schema.properties)) {
                    if (!propSpec) {
                        continue;
                    }
                    try {
                        let isArray = false;
                        let propType = propSpec.type;
                        if (propSpec.type === 'array') {
                            if (_.isArray(propSpec.items)) {
                                throw new Error('mixed-type arrays are not supported');
                            }
                            isArray = true;
                            propType = propSpec.items.type;
                        }
                        if (_.isArray(propType)) {
                            throw new Error('union-typed properties are not supported');
                        }
                        // NOTE: This version has handling for paths with arrays, but not using for now due to complexity.
                        /*
                        function resolvePathToValue(specData: SpecData, sourceData: SourceData, index: number, currentValue: unknown, path: string): unknown {
                          path = path.trim();
                          if (path === '') {
                            return currentValue;
                          }
                          if (specData?.schema?.type !== 'object') {
                            throw new Error('specData argument is not an object spec');
                          }
                          if (!_.isPlainObject(sourceData.data)) {
                            throw new Error('sourceData argument is not an object');
                          }
                          const pathParts = path.split('/');
                          let first: boolean = true;
                          let indexUsed: boolean = false;
                          let valueFound: boolean = false;
                          for (const part of pathParts) {
                            if (valueFound) {
                              throw new Error('cannot have path parts after value part');
                            }
                            switch (part) {
                              case '':
                                if (first) {
                                  // find the root
                                  while (sourceData.parent) {
                                    sourceData = sourceData.parent;
                                    if (!_.isPlainObject(sourceData)) {
                                      throw new Error('source parent is not an object');
                                    }
                                    specData = specData.parent;
                                    if (specData?.schema?.type !== 'object') {
                                      throw new Error('spec parent is not an object spec');
                                    }
                                  }
                                  if (specData.parent) {
                                    throw new Error('root of source data is not root of spec data');
                                  }
                                }
                                break;
                              case '.':
                                break;
                              case '..':
                                if (sourceData.parent) {
                                  sourceData = sourceData.parent;
                                  if (!_.isPlainObject(sourceData)) {
                                    throw new Error('source parent is not an object');
                                  }
                                  specData = specData.parent;
                                  if (specData?.schema?.type === 'object') {
                                    throw new Error('spec parent is not an object spec');
                                  }
                                } else if (specData.parent) {
                                  throw new Error('at root of source data but not root of spec data');
                                }
                                break;
                              default:
                                if (part.endsWith('[]')) {
                                  if (indexUsed) {
                                    throw new Error('cannot use array index more than once within transform path');
                                  }
                                  const prop = specData.schema.properties[part];
                                  if (!prop) {
                                    throw new Error('spec object prop does not exist');
                                  }
                                  if (prop.type !== 'array') {
                                    throw new Error('only array props can be accessed through [] suffix');
                                  }
                                  const arrayData = sourceData.data[part];
                                  if (_.isUndefined(arrayData)) {
                                    currentValue = undefined;
                                    valueFound = true;
                                    break;
                                  }
                                  if (!_.isArray(arrayData)) {
                                    throw new Error('source data is not an array');
                                  }
                                  if (!_.isInteger(index)) {
                                    throw new Error('array index not specified or not an integer');
                                  }
                                  if (_.isArray(prop.items)) {
                                    throw new Error('mixed-type arrays are not supported');
                                  }
                                  if (prop.items.type === 'object') {
                                    const objectData = (index < arrayData.length)? arrayData[index] : undefined;
                                    if (_.isUndefined(objectData)) {
                                      currentValue = undefined;
                                      valueFound = true;
                                      break;
                                    }
                                    if (!_.isPlainObject(objectData)) {
                                      throw new Error('source data is not an object');
                                    }
                                    specData = { parent: specData, key: part, schema: prop };
                                    sourceData = { parent: sourceData, key: part, data: objectData as { [k: string]: unknown } };
                                  } else {
                                    currentValue = (index < arrayData.length)? arrayData[index] : undefined;
                                    valueFound = true;
                                  }
                                } else {
                                  const prop = specData.schema.properties[part];
                                  if (!prop) {
                                    throw new Error('spec object prop does not exist');
                                  }
                                  if (prop.type === 'array') {
                                    throw new Error('array props can only be accessed through [] suffix');
                                  }
                                  if (prop.type === 'object') {
                                    const objectData = sourceData.data[part];
                                    if (_.isUndefined(objectData)) {
                                      currentValue = undefined;
                                      valueFound = true;
                                      break;
                                    }
                                    if (!_.isPlainObject(objectData)) {
                                      throw new Error('source data is not an object');
                                    }
                                    specData = { parent: specData, key: part, schema: prop };
                                    sourceData = { parent: sourceData, key: part, data: objectData as { [k: string]: unknown } };
                                  } else {
                                    currentValue = sourceData.data[part];
                                    valueFound = true;
                                  }
                                }
                                break;
                            }
                            first = false;
                          }
                          if (!valueFound) {
                            throw new Error('path did not resolve to a value');
                          }
                          return currentValue;
                        }
                        */
                        // Finds the data object at a given path in the source data.
                        function resolvePath(specData, sourceData, currentValue, path) {
                            var _a, _b, _c;
                            if (((_a = specData === null || specData === void 0 ? void 0 : specData.schema) === null || _a === void 0 ? void 0 : _a.type) !== 'object') {
                                throw new Error('specData argument is not an object spec');
                            }
                            if (!_.isPlainObject(sourceData.data)) {
                                throw new Error('sourceData argument is not an object');
                            }
                            path = path.trim();
                            if (path === '') {
                                return currentValue;
                            }
                            const pathParts = path.split('/');
                            let first = true;
                            let valueFound = false;
                            currentValue = sourceData.data;
                            for (const part of pathParts) {
                                if (valueFound) {
                                    throw new Error('cannot have path parts after value part');
                                }
                                switch (part) {
                                    case '':
                                        if (first) {
                                            // find the root
                                            while (sourceData.parent) {
                                                sourceData = sourceData.parent;
                                                if (!_.isPlainObject(sourceData)) {
                                                    throw new Error('source parent is not an object');
                                                }
                                                specData = specData.parent;
                                                if (((_b = specData === null || specData === void 0 ? void 0 : specData.schema) === null || _b === void 0 ? void 0 : _b.type) !== 'object') {
                                                    throw new Error('spec parent is not an object spec');
                                                }
                                            }
                                            if (specData.parent) {
                                                throw new Error('root of source data is not root of spec data');
                                            }
                                            currentValue = sourceData.data;
                                        }
                                        break;
                                    case '.':
                                        break;
                                    case '..':
                                        if (sourceData.parent) {
                                            sourceData = sourceData.parent;
                                            if (!_.isPlainObject(sourceData)) {
                                                throw new Error('source parent is not an object');
                                            }
                                            specData = specData.parent;
                                            if (((_c = specData === null || specData === void 0 ? void 0 : specData.schema) === null || _c === void 0 ? void 0 : _c.type) === 'object') {
                                                throw new Error('spec parent is not an object spec');
                                            }
                                            currentValue = sourceData.data;
                                        }
                                        else if (specData.parent) {
                                            throw new Error('at root of source data but not root of spec data');
                                        }
                                        break;
                                    default:
                                        const prop = specData.schema.properties[part];
                                        if (!prop) {
                                            throw new Error('spec object prop does not exist');
                                        }
                                        if (prop.type === 'object') {
                                            const objectData = sourceData.data[part];
                                            if (_.isUndefined(objectData)) {
                                                currentValue = undefined;
                                                valueFound = true;
                                                break;
                                            }
                                            if (!_.isPlainObject(objectData)) {
                                                throw new Error('source data is not an object');
                                            }
                                            specData = { parent: specData, key: part, schema: prop };
                                            sourceData = { parent: sourceData, key: part, data: objectData };
                                            currentValue = objectData;
                                        }
                                        else {
                                            // NOTE: arrays are considered value (leaf) objects at the moment
                                            currentValue = sourceData.data[part];
                                            valueFound = true;
                                        }
                                        break;
                                }
                                first = false;
                            }
                            return currentValue;
                        }
                        function mapPrimitiveValue(type, propTransform, parentSpecData, propSpecData, parentSourceData, sourceValue, elementValue) {
                            var _a;
                            if (((_a = propTransform === null || propTransform === void 0 ? void 0 : propTransform.operators) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                                for (const operator of propTransform.operators) {
                                    const operands = operator.operandPaths.map(_.partial(resolvePath, parentSpecData, parentSourceData, sourceValue));
                                    // TODO: check that all of the operands are primitives?
                                    sourceValue = operator.func(...operands);
                                }
                            }
                            //console.log('mapPrimitiveValue', { type, transform, parentSpecData, propSpecData, parentSourceData, sourceValue, elementValue });
                            if (!_.isUndefined(sourceValue)) {
                                return upcast.to(sourceValue, type);
                            }
                            else if (!_.isUndefined(elementValue)) {
                                return upcast.to(elementValue, type);
                            }
                            else if (!_.isUndefined(propSpecData.schema.default)) {
                                return upcast.to(propSpecData.schema.default, type);
                            }
                            else {
                                return undefined;
                            }
                        }
                        const propTransform = (_b = (_a = transformData.transform) === null || _a === void 0 ? void 0 : _a.properties) === null || _b === void 0 ? void 0 : _b[key];
                        if (propType === 'object') {
                            if (isArray) {
                                const propSpecData = {
                                    parent: specData,
                                    key,
                                    schema: propSpec.items
                                };
                                //console.log('PROP SPEC DATA FOR ARRAY', propSpecData);
                                if (propTransform && (propTransform === null || propTransform === void 0 ? void 0 : propTransform.type) !== 'ARRAY') {
                                    throw new Error('prop transform for array is not ARRAY type');
                                }
                                const propArrayTransform = propTransform;
                                if ((propArrayTransform === null || propArrayTransform === void 0 ? void 0 : propArrayTransform.itemTransform) && propArrayTransform.itemTransform.type !== 'OBJECT') {
                                    throw new Error('prop transform for items in array of objects is not OBJECT type');
                                }
                                const propTransformData = {
                                    parent: transformData,
                                    key,
                                    transform: propArrayTransform === null || propArrayTransform === void 0 ? void 0 : propArrayTransform.itemTransform
                                };
                                const sourceArr = (_c = sourceData.data) === null || _c === void 0 ? void 0 : _c[key];
                                if (!_.isUndefined(sourceArr) && !_.isArray(sourceArr)) {
                                    throw new Error('source array is defined but not an array');
                                }
                                const transformArr = (propArrayTransform) ? resolvePath(specData, sourceData, sourceArr, propArrayTransform.sourceArrayPath) : undefined;
                                if (!_.isUndefined(transformArr) && !_.isArray(transformArr)) {
                                    throw new Error('transform array is defined but not an array');
                                }
                                const sourceOrTransformArr = (!_.isUndefined(transformArr)) ? transformArr : sourceArr;
                                const elementArr = (_d = elementData.data) === null || _d === void 0 ? void 0 : _d[key];
                                if (!_.isUndefined(elementArr) && !_.isArray(elementArr)) {
                                    console.error(elementData, elementArr);
                                    throw new Error('element array is defined but not an array');
                                }
                                let arr = [];
                                switch ((propArrayTransform === null || propArrayTransform === void 0 ? void 0 : propArrayTransform.arrayMergeStrategy) || 'ITEMWISE_SOURCE_LENGTH_FIRST_ELEMENT_DEFAULT') {
                                    case 'ARRAYWISE':
                                        // if we have a transform or a matching source array exists, use that
                                        if (propArrayTransform) {
                                            for (let i = 0; i < sourceOrTransformArr.length; i++) {
                                                const sourceObject = sourceOrTransformArr[i];
                                                if (!_.isUndefined(sourceObject) && !_.isPlainObject(sourceObject)) {
                                                    throw new Error('source array items should be objects but this one is not');
                                                }
                                                const propSourceData = {
                                                    parent: sourceData,
                                                    key,
                                                    index: i,
                                                    data: sourceObject
                                                };
                                                const propElementData = {
                                                    parent: elementData,
                                                    key,
                                                    index: i,
                                                    data: undefined
                                                };
                                                const val = mapSourcesToProps(propSpecData, propTransformData, propSourceData, propElementData);
                                                arr.push(val);
                                            }
                                        }
                                        else if (_.isArray(sourceArr)) {
                                            for (let i = 0; i < sourceArr.length; i++) {
                                                const sourceObject = sourceArr[i];
                                                if (_.isUndefined(sourceObject)) {
                                                    continue;
                                                }
                                                if (!_.isPlainObject(sourceObject)) {
                                                    throw new Error('source array items should be objects but this one is not');
                                                }
                                                const propSourceData = {
                                                    parent: sourceData,
                                                    key,
                                                    index: i,
                                                    data: sourceObject
                                                };
                                                const propElementData = {
                                                    parent: elementData,
                                                    key,
                                                    index: i,
                                                    data: undefined
                                                };
                                                const val = mapSourcesToProps(propSpecData, propTransformData, propSourceData, propElementData);
                                                arr.push(val);
                                            }
                                        }
                                        else if (_.isArray(elementArr)) {
                                            for (let i = 0; i < elementArr.length; i++) {
                                                const elementObject = elementArr[i];
                                                if (_.isUndefined(elementObject)) {
                                                    continue;
                                                }
                                                if (!_.isPlainObject(elementObject)) {
                                                    throw new Error('element array items should be objects but this one is not');
                                                }
                                                const propSourceData = {
                                                    parent: sourceData,
                                                    key,
                                                    index: i,
                                                    data: undefined
                                                };
                                                const propElementData = {
                                                    parent: elementData,
                                                    key,
                                                    index: i,
                                                    data: elementObject
                                                };
                                                const val = mapSourcesToProps(propSpecData, propTransformData, propSourceData, propElementData);
                                                arr.push(val);
                                            }
                                        }
                                        else {
                                            arr = undefined;
                                        }
                                        break;
                                    case "ITEMWISE_SOURCE_LENGTH":
                                        if (!_.isArray(sourceOrTransformArr)) {
                                            arr = elementArr;
                                        }
                                        else {
                                            for (let i = 0; i < sourceOrTransformArr.length; i++) {
                                                const sourceObject = sourceOrTransformArr[i];
                                                if (!_.isUndefined(sourceObject) && !_.isPlainObject(sourceObject)) {
                                                    throw new Error('source array items should be objects but this one is not');
                                                }
                                                const elementObject = (!_.isUndefined(elementArr) && i < elementArr.length) ? elementArr[i] : undefined;
                                                if (!_.isUndefined(elementObject) && !_.isPlainObject(elementObject)) {
                                                    throw new Error('element array items should be objects but this one is not');
                                                }
                                                const propSourceData = {
                                                    parent: sourceData,
                                                    key,
                                                    index: i,
                                                    data: sourceObject
                                                };
                                                const propElementData = {
                                                    parent: elementData,
                                                    key,
                                                    index: i,
                                                    data: elementObject
                                                };
                                                const val = mapSourcesToProps(propSpecData, propTransformData, propSourceData, propElementData);
                                                arr.push(val);
                                                /*
                                                if (propArrayTransform) {
                                                  
                                                } else if (!_.isUndefined(sourceObject)) {
                                                  arr.push(sourceObject);
                                                } else {
                                                  arr.push(elementObject);
                                                }
                                                */
                                            }
                                        }
                                        break;
                                    case "ITEMWISE_SOURCE_LENGTH_FIRST_ELEMENT_DEFAULT":
                                        const elementObject = (!_.isUndefined(elementArr) && elementArr.length > 0) ? elementArr[0] : undefined;
                                        if (!_.isUndefined(elementObject) && !_.isPlainObject(elementObject)) {
                                            throw new Error('element array items should be objects but this one is not');
                                        }
                                        if (_.isArray(sourceOrTransformArr)) {
                                            for (let i = 0; i < sourceOrTransformArr.length; i++) {
                                                const sourceObject = sourceOrTransformArr[i];
                                                if (!_.isUndefined(sourceObject) && !_.isPlainObject(sourceObject)) {
                                                    throw new Error('source array items should be objects but this one is not');
                                                }
                                                const propSourceData = {
                                                    parent: sourceData,
                                                    key,
                                                    index: i,
                                                    data: sourceObject
                                                };
                                                const propElementData = {
                                                    parent: elementData,
                                                    key,
                                                    index: i,
                                                    data: elementObject
                                                };
                                                const val = mapSourcesToProps(propSpecData, propTransformData, propSourceData, propElementData);
                                                arr.push(val);
                                                /*
                                                if (propArrayTransform) {
                                                  
                                                } else if (!_.isUndefined(sourceObject)) {
                                                  arr.push(sourceObject);
                                                } else {
                                                  arr.push(elementObject);
                                                }
                                                */
                                            }
                                        }
                                        else if (_.isArray(elementArr)) {
                                            for (let i = 0; i < elementArr.length; i++) {
                                                const elementObject = elementArr[i];
                                                if (_.isUndefined(elementObject)) {
                                                    continue;
                                                }
                                                if (!_.isPlainObject(elementObject)) {
                                                    throw new Error('element array items should be objects but this one is not');
                                                }
                                                const propSourceData = {
                                                    parent: sourceData,
                                                    key,
                                                    index: i,
                                                    data: undefined
                                                };
                                                const propElementData = {
                                                    parent: elementData,
                                                    key,
                                                    index: i,
                                                    data: elementObject
                                                };
                                                const val = mapSourcesToProps(propSpecData, propTransformData, propSourceData, propElementData);
                                                arr.push(val);
                                            }
                                        }
                                        else {
                                            arr = undefined;
                                        }
                                        break;
                                    case "ITEMWISE_ELEMENT_LENGTH":
                                        if (!_.isArray(elementArr)) {
                                            arr = undefined;
                                        }
                                        else {
                                            for (let i = 0; i < elementArr.length; i++) {
                                                const sourceObject = (!_.isUndefined(sourceOrTransformArr) && i < sourceOrTransformArr.length) ? sourceOrTransformArr[i] : undefined;
                                                if (!_.isUndefined(sourceObject) && !_.isPlainObject(sourceObject)) {
                                                    throw new Error('source array items should be objects but this one is not');
                                                }
                                                const elementObject = elementArr[i];
                                                if (!_.isUndefined(elementObject) && !_.isPlainObject(elementObject)) {
                                                    throw new Error('element array items should be objects but this one is not');
                                                }
                                                const propSourceData = {
                                                    parent: sourceData,
                                                    key,
                                                    index: i,
                                                    data: sourceObject
                                                };
                                                const propElementData = {
                                                    parent: elementData,
                                                    key,
                                                    index: i,
                                                    data: elementObject
                                                };
                                                const val = mapSourcesToProps(propSpecData, propTransformData, propSourceData, propElementData);
                                                arr.push(val);
                                                /*
                                                if (propArrayTransform) {
                      
                                                } else if (!_.isUndefined(sourceObject)) {
                                                  arr.push(sourceObject);
                                                } else {
                                                  arr.push(elementObject);
                                                }
                                                */
                                            }
                                        }
                                        break;
                                }
                                if (!_.isUndefined(arr)) {
                                    ret[key] = arr;
                                }
                            }
                            else {
                                const propSpecData = {
                                    parent: specData,
                                    key,
                                    schema: propSpec
                                };
                                if (propTransform && propTransform.type !== 'OBJECT') {
                                    throw new Error('prop transform for object is not OBJECT type');
                                }
                                const propTransformData = {
                                    parent: transformData,
                                    key,
                                    transform: propTransform
                                };
                                const sourceObject = (_e = sourceData.data) === null || _e === void 0 ? void 0 : _e[key];
                                if (!_.isUndefined(sourceObject) && !_.isPlainObject(sourceObject)) {
                                    console.log('ERROR???', propSpec, sourceObject);
                                    throw new Error('source data item should be object but this one is not');
                                }
                                const elementObject = (_f = elementData.data) === null || _f === void 0 ? void 0 : _f[key];
                                if (!_.isUndefined(elementObject) && !_.isPlainObject(elementObject)) {
                                    //console.error('oh no', key, elementObject);
                                    throw new Error('element data item should be object but this one is not');
                                }
                                const propSourceData = {
                                    parent: sourceData,
                                    key,
                                    data: sourceObject
                                };
                                const propElementData = {
                                    parent: elementData,
                                    key,
                                    data: elementObject
                                };
                                ret[key] = mapSourcesToProps(propSpecData, propTransformData, propSourceData, propElementData);
                            }
                        }
                        else {
                            // not object
                            if (isArray) {
                                const propSpecData = {
                                    parent: specData,
                                    key,
                                    schema: propSpec.items
                                };
                                if (propTransform && (propTransform === null || propTransform === void 0 ? void 0 : propTransform.type) !== 'ARRAY') {
                                    throw new Error('prop transform for array is not ARRAY type');
                                }
                                const propArrayTransform = propTransform;
                                if ((propArrayTransform === null || propArrayTransform === void 0 ? void 0 : propArrayTransform.itemTransform) && propArrayTransform.itemTransform.type !== 'PRIMITIVE') {
                                    throw new Error('prop transform for items in array of primitives is not PRIMITIVE type');
                                }
                                const propArrayItemTransform = propArrayTransform === null || propArrayTransform === void 0 ? void 0 : propArrayTransform.itemTransform;
                                const sourceArr = (_g = sourceData.data) === null || _g === void 0 ? void 0 : _g[key];
                                if (!_.isUndefined(sourceArr) && !_.isArray(sourceArr)) {
                                    throw new Error('source array is defined but not an array');
                                }
                                const transformArr = (propArrayTransform) ? resolvePath(specData, sourceData, sourceArr, propArrayTransform.sourceArrayPath) : undefined;
                                if (!_.isUndefined(transformArr) && !_.isArray(transformArr)) {
                                    throw new Error('transform array is defined but not an array');
                                }
                                const sourceOrTransformArr = (!_.isUndefined(transformArr)) ? transformArr : sourceArr;
                                const elementArr = (_h = elementData.data) === null || _h === void 0 ? void 0 : _h[key];
                                if (!_.isUndefined(elementArr) && !_.isArray(elementArr)) {
                                    throw new Error('element array is defined but not an array');
                                }
                                let arr = [];
                                switch ((propArrayTransform === null || propArrayTransform === void 0 ? void 0 : propArrayTransform.arrayMergeStrategy) || 'ITEMWISE_SOURCE_LENGTH_FIRST_ELEMENT_DEFAULT') {
                                    case 'ARRAYWISE':
                                        // if we have a transform or a matching source array exists, use that
                                        if (propArrayTransform) {
                                            for (let i = 0; i < sourceOrTransformArr.length; i++) {
                                                const sourceValue = sourceOrTransformArr[i];
                                                if (!_.isUndefined(sourceValue) && _.isObject(sourceValue)) {
                                                    throw new Error('array items should be primitives but this one is not');
                                                }
                                                const val = mapPrimitiveValue(propType, propArrayItemTransform, specData, propSpecData, sourceData, sourceValue, undefined);
                                                arr.push(val);
                                            }
                                        }
                                        else if (_.isArray(sourceArr)) {
                                            for (let i = 0; i < sourceArr.length; i++) {
                                                const sourceValue = sourceArr[i];
                                                if (_.isUndefined(sourceValue)) {
                                                    continue;
                                                }
                                                if (_.isObject(sourceValue)) {
                                                    throw new Error('array items should be primitives but this one is not');
                                                }
                                                const val = mapPrimitiveValue(propType, propArrayItemTransform, specData, propSpecData, sourceData, sourceValue, undefined);
                                                arr.push(val);
                                            }
                                        }
                                        else if (_.isArray(elementArr)) {
                                            for (let i = 0; i < elementArr.length; i++) {
                                                const elementValue = elementArr[i];
                                                if (_.isUndefined(elementValue)) {
                                                    continue;
                                                }
                                                if (_.isObject(elementValue)) {
                                                    throw new Error('array items should be primitives but this one is not');
                                                }
                                                const val = mapPrimitiveValue(propType, propArrayItemTransform, specData, propSpecData, sourceData, undefined, elementValue);
                                                arr.push(val);
                                            }
                                        }
                                        else {
                                            arr = undefined;
                                        }
                                        break;
                                    case "ITEMWISE_SOURCE_LENGTH":
                                        if (!_.isArray(sourceOrTransformArr)) {
                                            arr = elementArr;
                                        }
                                        else {
                                            for (let i = 0; i < sourceOrTransformArr.length; i++) {
                                                const sourceValue = sourceOrTransformArr[i];
                                                if (!_.isUndefined(sourceValue) && _.isObject(sourceValue)) {
                                                    throw new Error('source array items should be primitives but this one is not');
                                                }
                                                const elementValue = (!_.isUndefined(elementArr) && i < elementArr.length) ? elementArr[i] : undefined;
                                                if (!_.isUndefined(elementValue) && _.isObject(elementValue)) {
                                                    throw new Error('element array items should be primitives but this one is not');
                                                }
                                                if (propArrayTransform) {
                                                    const val = mapPrimitiveValue(propType, propArrayItemTransform, specData, propSpecData, sourceData, sourceValue, elementValue);
                                                    arr.push(val);
                                                }
                                                else if (!_.isUndefined(sourceValue)) {
                                                    arr.push(sourceValue);
                                                }
                                                else {
                                                    arr.push(elementValue);
                                                }
                                            }
                                        }
                                        break;
                                    case "ITEMWISE_SOURCE_LENGTH_FIRST_ELEMENT_DEFAULT":
                                        const elementValue = (!_.isUndefined(elementArr) && elementArr.length > 0) ? elementArr[0] : undefined;
                                        if (!_.isUndefined(elementValue) && _.isObject(elementValue)) {
                                            throw new Error('element array items should be primitives but this one is not');
                                        }
                                        if (_.isArray(sourceOrTransformArr)) {
                                            for (let i = 0; i < sourceOrTransformArr.length; i++) {
                                                const sourceValue = sourceOrTransformArr[i];
                                                if (!_.isUndefined(sourceValue) && _.isObject(sourceValue)) {
                                                    throw new Error('source array items should be primitives but this one is not');
                                                }
                                                const val = mapPrimitiveValue(propType, propArrayItemTransform, specData, propSpecData, sourceData, sourceValue, elementValue);
                                                arr.push(val);
                                                /*
                                                if (propArrayTransform) {
                      
                                                } else if (!_.isUndefined(sourceValue)) {
                                                  arr.push(sourceValue);
                                                } else {
                                                  arr.push(elementValue);
                                                }
                                                */
                                            }
                                        }
                                        else if (_.isArray(elementArr)) {
                                            for (let i = 0; i < elementArr.length; i++) {
                                                const elementValue = elementArr[i];
                                                if (_.isUndefined(elementValue)) {
                                                    continue;
                                                }
                                                if (_.isObject(elementValue)) {
                                                    throw new Error('array items should be primitives but this one is not');
                                                }
                                                const val = mapPrimitiveValue(propType, propArrayItemTransform, specData, propSpecData, sourceData, undefined, elementValue);
                                                arr.push(val);
                                            }
                                        }
                                        else {
                                            arr = undefined;
                                        }
                                        break;
                                    case "ITEMWISE_ELEMENT_LENGTH":
                                        if (_.isUndefined(elementArr)) {
                                            arr = undefined;
                                        }
                                        else {
                                            for (let i = 0; i < elementArr.length; i++) {
                                                const sourceValue = (!_.isUndefined(sourceOrTransformArr) && i < sourceOrTransformArr.length) ? sourceOrTransformArr[i] : undefined;
                                                if (!_.isUndefined(sourceValue) && _.isObject(sourceValue)) {
                                                    throw new Error('source array items should be primitives but this one is not');
                                                }
                                                const elementValue = elementArr[i];
                                                if (!_.isUndefined(elementValue) && _.isObject(elementValue)) {
                                                    throw new Error('element array items should be primitives but this one is not');
                                                }
                                                const val = mapPrimitiveValue(propType, propArrayItemTransform, specData, propSpecData, sourceData, sourceValue, elementValue);
                                                arr.push(val);
                                                /*
                                                if (propArrayTransform) {
                                                  
                                                } else if (!_.isUndefined(sourceValue)) {
                                                  arr.push(sourceValue);
                                                } else {
                                                  arr.push(elementValue);
                                                }
                                                */
                                            }
                                        }
                                        break;
                                }
                                if (!_.isUndefined(arr)) {
                                    ret[key] = arr;
                                }
                            }
                            else {
                                const propSpecData = {
                                    parent: specData,
                                    key,
                                    schema: propSpec
                                };
                                if (!_.isUndefined(propTransform) && (propTransform === null || propTransform === void 0 ? void 0 : propTransform.type) !== 'PRIMITIVE') {
                                    throw new Error('prop transform for primitive is not PRIMITIVE type');
                                }
                                const val = mapPrimitiveValue(propType, propTransform, specData, propSpecData, sourceData, (_j = sourceData.data) === null || _j === void 0 ? void 0 : _j[key], (_k = elementData.data) === null || _k === void 0 ? void 0 : _k[key]);
                                if (!_.isUndefined(val)) {
                                    ret[key] = val;
                                }
                            }
                        }
                    }
                    catch (e) {
                        console.error(`unable to map data for prop ${key}`, e);
                    }
                }
                if (specData.schema.additionalProperties) {
                    if (sourceData === null || sourceData === void 0 ? void 0 : sourceData.data) {
                        for (const [key, val] of Object.entries(sourceData.data)) {
                            if (!(key in ret)) {
                                ret[key] = val;
                            }
                        }
                    }
                    if (elementData === null || elementData === void 0 ? void 0 : elementData.data) {
                        for (const [key, val] of Object.entries(elementData.data)) {
                            if (!(key in ret)) {
                                ret[key] = val;
                            }
                        }
                    }
                }
                return ret;
            }
            spreadProps = mapSourcesToProps({ schema: configSpec }, { transform }, { data: $firstSourceData }, { data: element.data });
            //console.log('SPREAD PROPS', element.class, spreadProps);
            /*
            for (const [key, propSpec] of Object.entries(configSpec.properties)) {
              if (propSpec?.type !== "string") {
                console.error(`element ${element.class} dynamic property ${key} is not of type string and only string props are currently supported`);
                continue;
              }
              newSpreadProps[key] = getTemplatedElementDataFromSource(expandedElement, key, $firstSourceData)?.toString();
            }
            spreadProps = newSpreadProps;
            */
            //inlineStyle = buildInlineStyle(...Object.entries(newStyleProps).flatMap((v) => { v[0] = _.kebabCase(v[0]); return v; }));
            //console.log('NEW SPREAD PROPS', spreadProps);
            //console.log('NEW INLINE STYLE', inlineStyle);
        }
        else {
            console.error(`element type ${element.class} defines getElementConfigSpec but its value is not the spec for a single object with a simple properties object`);
        }
    }
    else {
        //console.warn(`element type ${element.class} does not define getElementConfigSpec as a function, spread props and styles are not available`);
    }
}
$: updateElementData(element, sequenceId, $mergedParams, $firstSourceData, $conditionsCheckStore);
//$: console.log('UPDATE ELEMENT', element);
/*
$: if (sequenceId) {
  // register and unregister the card dynamically from data sources depending on whether conditions are met
  if ($conditionsCheckStore) {
    sources = tabContext.dataSourceManager.register(element.id, sequenceId, element.sourceIds);
  } else {
    tabContext.dataSourceManager.unregister(element.id);
    sources = writable([]);
  }
  element = element;
}s
*/
$: cardLibraryRecord = getNamed(ROOT, CardsLibraryToken, element.class) || getNamed(ROOT, CardsLibraryToken, 'YinzCam.Core.UnknownCard');
$: cardComponent = cardLibraryRecord["default"];
$: cardSpec = cardLibraryRecord["specification"];
//$: console.log('CARD LIBRARY RECORD', cardLibraryRecord);
$: cardComponentName = (cardSpec) ? cardSpec.getDisplayName() : (_.isFunction(cardLibraryRecord.getDisplayName) ? cardLibraryRecord.getDisplayName() : element.class);
$: showPlaceholder = $emmEnabled && !$emmPreview;
$: if ($conditionsCheckStore) {
    if (showPlaceholder) {
        // If we're showing the placeholder card, content is always available
        contentAvailable = true;
    }
    else if (!_.isNil(childContentAvailable)) {
        // If contentAvailable is defined by the card component, that means it wants to explicitly control whether it has content available.
        contentAvailable = childContentAvailable;
    }
    else if (cardSpec && $sources.length > 0) {
        // If the card is a new-style Atom and the card has data sources defined, show the card if source data is provided.
        //console.log('ATOM CONTENT AVAILALBE', cardSpec.getDisplayName(), !!$firstSourceData);
        contentAvailable = !!$firstSourceData;
    }
    else if ($sources.length > 0 && !_.isUndefined(childSourceStore)) {
        // If card provides access to its sourceStore, use the value of the store to determine whether it has content available.
        // Note that null here means that the sourceStore is defined by the card, it's just not available yet.
        contentAvailable = (!!$childSourceStore || ((_b = element === null || element === void 0 ? void 0 : element.data) === null || _b === void 0 ? void 0 : _b.forceShow)) || false;
    }
    else {
        // Otherwise, assume the card doesn't load dynamic content and just display as soon as we're mounted.
        contentAvailable = (mounted || ((_c = element === null || element === void 0 ? void 0 : element.data) === null || _c === void 0 ? void 0 : _c.forceShow)) || false;
    }
}
else {
    contentAvailable = false;
}
let updateDisplay = _.debounce((ca) => {
    cssDisplay = (ca) ? '' : 'display: none;';
}, 100);
$: updateDisplay(contentAvailable);
$: cssVisibility = (contentAvailable) ? '' : 'visibility: hidden;';
$: contentActive = childContentActive;
function generateSchema(component) {
    const schema = _.cloneDeep(CARDS_ELEMENT_SCHEMA);
    schema.description = `Element ID: ${component.id}`;
    const rec = getNamed(ROOT, CardsLibraryToken, component.class);
    if (rec) {
        const spec = cardLibraryRecord["specification"];
        if (spec) {
            // new way
            schema.title = spec.getDisplayName();
            function removeUneditableProperties(schema) {
                if (schema.uneditable === true) {
                    return undefined;
                }
                if (schema.type === 'object') {
                    const newProperties = {};
                    for (const [k, v] of Object.entries(schema.properties || {})) {
                        const newProp = removeUneditableProperties(v);
                        if (!_.isUndefined(newProp)) {
                            newProperties[k] = newProp;
                        }
                    }
                    if (Object.keys(newProperties).length > 0) {
                        return Object.assign(Object.assign({}, schema), { properties: newProperties });
                    }
                    else {
                        return undefined;
                    }
                }
                else {
                    return schema;
                }
            }
            schema.properties.data = removeUneditableProperties(spec.getSchema());
            if (schema.properties.data) {
                schema.properties.data.title = schema.title;
            }
            const curSources = tabContext.dataSourceManager.getDataSources() || [];
            schema.properties['sourceIds'] = generateElementDataSourcesSchema(spec.getDataSourceSpec(), curSources);
        }
        else {
            // old way
            if (_.isFunction(rec.getDisplayName)) {
                schema.title = rec.getDisplayName();
            }
            else {
                schema.title = rec.clazz;
            }
            if (_.isFunction(rec.getElementConfigSpec)) {
                schema.properties.data = rec.getElementConfigSpec();
                schema.properties.data.title = schema.title;
            }
            else {
                delete schema.properties.data;
            }
            if (_.isFunction(rec.getElementDataSourceSpec)) {
                const dsSpec = rec.getElementDataSourceSpec();
                if (dsSpec) {
                    const curSources = tabContext.dataSourceManager.getDataSources() || [];
                    schema.properties['sourceIds'] = generateElementDataSourcesSchema(dsSpec, curSources);
                }
            }
        }
    }
    return schema;
}
let editorOptions;
$: editorOptions = {
    pageContext,
    parentSequenceId,
    sequenceId,
    componentId: element.id,
    component: element,
    componentTypeName: 'Card',
    childTypeNames: [],
    primaryColor: '#0DDE4F',
    reverseTextColor: 'black',
    unselectedBackgroundColor: '#8C959E',
    unselectedTextColor: '#FFFFFF',
    selectedBackgroundColor: '#FFDA53',
    selectedTextColor: '#202020',
    childrenKey: null,
    configSpecGenerator: generateSchema,
    allowDeletion: true,
    allowDuplication: true,
    allowMovement: true,
    allowRepeats: true,
    allowCopyPaste: true,
    updater,
    allowPreviewHoverEdit: true,
    cloned: !!((_d = element === null || element === void 0 ? void 0 : element.__expandRepeats) === null || _d === void 0 ? void 0 : _d.cloned),
    sourceElementId: (_e = element === null || element === void 0 ? void 0 : element.__expandRepeats) === null || _e === void 0 ? void 0 : _e.sourceId,
};
// TODO: Fold this mechanism into the cssDisplay variable
/*
let display = null;
$: visibleKey = element.visibleKey;
  $: visible = (visibleKey)? ($mergedParams || {})[visibleKey] : null;
$: if (visibleKey) {
  display = (visible === 'true') ? 'block' : 'none';
} else {
  display = null;
}
*/
onMount(() => {
    mounted = true;
    return () => {
        tabContext.dataSourceManager.unregister(element.id);
    };
});
</script>

