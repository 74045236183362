import { YinzCamAPIRequestComponent, YinzCamAPIResponse } from "yinzcam-api";
import { ControlBase, ReactiveMicrocomponent } from "yinzcam-rma";
import { CardsDataSourceOutput } from "./CardsDataSourceOutput";
import { CardsDataSource } from "./CardsDataSource";
import { CardsDataSourceComponent } from "./CardsDataSourceComponent";
import type { CardsDataSourceConfiguration } from "./CardsDataSourceConfiguration";
import { injectable } from "inversify";
import { expandTemplateParamsRecursive } from "../utilities";
import { JSONSchema4 } from "json-schema";

@injectable()
export abstract class CardsDataSourceBase<T = YinzCamAPIResponse> implements CardsDataSource {

  protected constructor(protected readonly config: CardsDataSourceConfiguration<T>) {
  }
  
  public async getResponseSchema(path?: string, specData?: Record<string, any>): Promise<JSONSchema4> {
    return undefined;
  }

  public getDisplayName(): string {
    // the clazz variable is injected by module.ts
    return (<any>this).clazz;
  }

  // undefined = "No config data is defined, so allow client to enter free form data"
  // null = "No config data is needed, so don't allow client to enter anything"
  public getDataSourceConfigSpec(path?: string, specData?: { [key: string]: any }): Promise<JSONSchema4> {
    return Promise.resolve(undefined);
  }

  // undefined = "No paths are defined, so allow client to enter free form data"
  // null or empty array = "No paths are allowed"
  public getDataSourcePaths(specData?: { [key: string]: any }): Promise<{ name: string; path: string; }[]> {
    return Promise.resolve(undefined);
  }

  // allows data sources to derive extra URL parameters from the spec data for a more user-friendly configuration experience
  protected getExtraParams(specData?: { [key: string]: any }): { [key: string]: string } {
    return {};
  }

  public getDataSourceComponent(path?: string, specData?: { [key: string]: any }, contextParams?: { [key: string]: string }): CardsDataSourceComponent<T> {
    path = path || this.config.defaultPath;
    if (!path) {
      throw new Error('no path provided for data source and default path not defined');
    }
    let url = new URL(path, 'http://www.example.org/'); // we just need any url to resolve against since we only care about path and params
    let params = { ...(this.config.defaultParams || {}), ...(this.getExtraParams(specData) || {}) };
    url.searchParams.forEach((val, key) => params[key] = val);
    // TODO: is this necessary?
    params = expandTemplateParamsRecursive(params, contextParams);
    let self = this;
    let cardComponentClass = class extends CardsDataSourceComponent<T> {
      public constructor(name: string, private readonly reqComp: ReactiveMicrocomponent<T>) {
        super(name, reqComp);
      }

      public refresh(): void {
        if (this.reqComp.refreshSupported()) {
          this.reqComp.refresh();
        }
      }

      protected async update($control: ControlBase, $response: T): Promise<CardsDataSourceOutput> {
        return { data: self.processResponse($response, specData) };
      }
    };
    let apiComponent = this.config.server.getRequest({ path: url.pathname, params });
    // it's okay to use the URL as a dummy name since not saving to local storage
    return new cardComponentClass(url.toString(), apiComponent);
  }

  protected abstract processResponse(response: T, specData?: { [key: string]: any }): object | any[];

}