import { ContainerProps, RecursiveBooleanified, RecursivePartial, YinzCamCardsComponentProps, YinzCamCardsComponentSchema, applyAnnotationsToSchema, applyDefaultsToPropsRecursive, generateContainerSchema, generateNamedObjectSchema } from "components/cards/common/schema";
import { Narrow } from "json-schema-to-ts/lib/types/type-utils";
import _ from "lodash";

export class BaseParticleSpec<T extends YinzCamCardsComponentSchema, U = YinzCamCardsComponentProps<T>, V = RecursivePartial<U>, W = RecursiveBooleanified<V>> {
  
  public constructor(private readonly displayName: string, private readonly schema: Narrow<T>) {}

  public getDisplayName(): string {
    return this.displayName;
  }

  public generateSchema<A extends string, B extends string>(title: Narrow<A>, description: Narrow<B>, options: { defaultValues?: V, uneditableFields?: W } = {}) {
    const { defaultValues, uneditableFields } = options;
    return generateNamedObjectSchema(title, description, applyAnnotationsToSchema(this.schema, defaultValues, uneditableFields));
  }
  
  public propsWithDefaults(props: V) {
    return applyDefaultsToPropsRecursive(this.schema, props);
  }
}

export type GetParticleSchemaType<S> = S extends BaseParticleSpec<infer T, infer _> ? T : never;

export type GetParticlePropsType<S> = S extends BaseParticleSpec<infer _, infer U> ? U : never;

export function generateParticleContainerSchema<V = RecursivePartial<ContainerProps>, W = RecursiveBooleanified<V>>(options: { defaultValues?: V, uneditableFields?: W } = {}) {
  const { defaultValues, uneditableFields } = options;
  return applyAnnotationsToSchema(generateContainerSchema("Container", "The container properties for this particle."), defaultValues, uneditableFields);
}

export type ParticleContainerSchema = ReturnType<typeof generateParticleContainerSchema>;

export type ParticleContainerProps = YinzCamCardsComponentProps<ParticleContainerSchema>;
