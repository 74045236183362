import * as YinzCam__Core__Article from "./Article/ArticleAtom.svelte";
import * as YinzCam__Core__Button from "./Button/ButtonAtom.svelte";
import * as YinzCam__Core__EmbeddedPage from "./EmbeddedPage/EmbeddedPageAtom.svelte";
import * as YinzCam__Core__Image from "./Image/ImageAtom.svelte";
import * as YinzCam__Core__NavigationMenu from "./NavigationMenu/NavigationMenuVariantBAtom.svelte";
import * as YinzCam__Core__NavigationMenuC from "./NavigationMenuC/NavigationMenuCAtom.svelte";
import * as YinzCam__Core__NavigationSubenu from "./NavigationSubmenu/NavigationSubmenuAtom.svelte";
import * as YinzCam__Core__Search from "./Search/SearchAtom.svelte";
import * as YinzCam__Core__ViewMoreButton from "./ViewMoreButton/ViewMoreButtonAtom.svelte";

export const library = {
  YinzCam__Core__Article,
  YinzCam__Core__Button,
  YinzCam__Core__EmbeddedPage,
  YinzCam__Core__Image,
  YinzCam__Core__NavigationMenu,
  YinzCam__Core__NavigationMenuC,
  YinzCam__Core__NavigationSubenu,
  YinzCam__Core__Search,
  YinzCam__Core__ViewMoreButton,
};
