<script context="module" lang="ts">var _a, _b;
export { specification } from "./spec";
</script>

<script lang="ts">var _a, _b;
import _, { get, merge } from "lodash";
import { BaseAtom, getContentManager, } from "components/cards/atoms";
import { ImageParticle, TextParticle, ButtonParticle, } from "components/cards/particles/YinzCam";
import { formatDateTimeLocal, setStoreOnHoverAction, } from "components/cards/utilities";
import { writable } from "svelte/store";
import { DateTime } from "luxon";
export let container;
export let mediaType;
export let slug;
export let link;
export let titleText;
export let descriptionText;
export let thumbnail;
export let imageClockIcon;
export let publishDateTime;
export let imageShareIcon;
export let textDateTime;
export let bodyPadding;
export let mediaIconColor;
export let imgMediaType;
export let separateHeaderDate;
export let contentAvailable;
$: contentAvailable = !!slug;
$: descriptionVisible =
    (descriptionText === null || descriptionText === void 0 ? void 0 : descriptionText.text) && !((_a = descriptionText === null || descriptionText === void 0 ? void 0 : descriptionText.container) === null || _a === void 0 ? void 0 : _a.hidden);
const hovering = writable(false);
let atomWidthRem;
$: mtc = getContentManager().getMediaTypeConfigurationStore(mediaType, slug, link);
$: resolvedLink = (_b = $mtc === null || $mtc === void 0 ? void 0 : $mtc.linkUrl) === null || _b === void 0 ? void 0 : _b.href;
$: publishDateTimeDisplay = formatDateTimeLocal(publishDateTime, DateTime.DATE_HUGE);
$: mediaTypeIcon = $mtc === null || $mtc === void 0 ? void 0 : $mtc.icon;
$: mediaIconMultiplier = atomWidthRem <= 30 ? "0.6" : "1.00";
</script>

<BaseAtom
  _isotope="VerticalMediaAtom"
  _link={resolvedLink}
  _cssVariablePrefix="vertical-media"
  {...container}
  {bodyPadding}
>
  <div class="main-container" use:setStoreOnHoverAction={hovering}>
    <div class="image-container">
      <ImageParticle _block={true} _hovering={$hovering} {...thumbnail} />
    </div>
    <div class="body-container">
      <div class="inner-container">
        <div class="text-clock-share" class:separate-header-date={separateHeaderDate}>
          <div class="text-container">
            <TextParticle _block={true} {...titleText} />
          </div>
          {#if descriptionVisible}
            <div class="description-container">
              <TextParticle {...descriptionText} />
            </div>
          {/if}
          <div class="icon-container">
            <div class="left-side-content">
              <ImageParticle _block={true} {...imageClockIcon} />
              <TextParticle
                _block={true}
                {...textDateTime}
                text={publishDateTimeDisplay || ""}
              />
            </div>
          </div>
        </div>
        <div class="media-type-icon">
          <div class="media-icon">
            {#if !_.isNil(mediaTypeIcon)}
              <ImageParticle
                _block={true}
                {...merge({}, imgMediaType, {
                  container: {
                    width: `calc(${get(imgMediaType, "container.width")}*${mediaIconMultiplier})`,
                    height: `calc(${get(imgMediaType, "container.height")}*${mediaIconMultiplier})`,
                  },
                  icon: mediaTypeIcon,
                  iconColor: mediaIconColor,
                })}
              />
            {/if}
          </div>
          <div class="right-side-content">
            <ButtonParticle {...imageShareIcon} />
          </div>
        </div>
      </div>
    </div>
  </div>
</BaseAtom>

<style>
  .main-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-size: cover;
    background-position: top center;
    cursor: pointer;
    position: relative;
    /*aspect-ratio: var(--aspect-ratio, 21/4);*/
    height: 100%;
    width: 100%;
  }

  .main-container > .image-container {
    position: relative;
    width: 100%;
    overflow: hidden;
  }

  .media-type-icon {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    align-items: flex-end;
  }

  .body-container {
    flex: 1 0 auto;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: var(--vertical-media-body-padding, unset);
    gap: 0.5rem;
  }

  .text-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: top;
    justify-content: space-between;
  }

  .text-container > * {
    flex: 0 1 max-content;
  }

  .text-clock-share {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .text-clock-share.separate-header-date{
    height: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .inner-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }

  .description-container {
    width: 100%;
  }

  .icon-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .left-side-content {
    display: flex;
    gap: 0.25rem;
    align-items: center;
    justify-content: flex-start;
  }</style>
