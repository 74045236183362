import { Readable, derived } from "svelte/store";
import { ContentfulCDN } from "./ContentfulCDN";
import { ContentfulClient } from "./ContentfulClient";
import { CardsDynamicConfiguration } from "components/cards/common/CardsDynamicConfiguration";
import { stripContentfulMetadata } from "./ContentfulUtilities";

export class ContentfulCardsDynamicConfiguration implements CardsDynamicConfiguration {
  public constructor(private readonly cdn: ContentfulCDN) {}

  public getObjectStore(type: string, slug: string): Readable<object> {
    const req = {
      path: type,
      params: {
        'fields.slug': slug
      }
    };
    //console.log('GOT REQ', req);
    return derived([this.cdn.getRequest(req).store], ([rsp]) => {
      //console.log('GOT RSP', rsp);
      if (rsp?.items?.length > 0) {
        return { ...(stripContentfulMetadata(rsp.items[0]) as object), _success: true };
      } else if (rsp?.items?.length === 0) {
        return { _success: true };
      } else {
        return { _success: false };
      }
    });
  }
}
