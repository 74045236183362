import { generateBooleanSchema, generateColorSchema, generateRootObjectSchema, generateStringSchema, generateUrlSchema } from "components/cards/common/schema";
import { BaseParticleSpec, GetParticlePropsType, GetParticleSchemaType, generateParticleContainerSchema } from "components/cards/particles";

export const AudioParticleSpec = new BaseParticleSpec("Audio", generateRootObjectSchema({
  container: generateParticleContainerSchema(),
  source: generateUrlSchema("Audio Source", "The source of the audio as a URL."),
  controls: generateBooleanSchema("Controls", "Whether the audio should show on-screen controls.", {
    defaultValue: true
  }),
  autoplay: generateBooleanSchema("Autoplay", "Whether the audio should autoplay once it loads.", {
    defaultValue: false
  }),
  muted: generateBooleanSchema("Muted", "Whether the audio should start playback muted.", {
    defaultValue: false
  }),
  loop: generateBooleanSchema("Loop", "Whether the audio should loop back to the start once it reaches the end of playback.", {
    defaultValue: false
  }),
}));

export const specification = AudioParticleSpec;

export type AudioParticleSchema = GetParticleSchemaType<typeof AudioParticleSpec>;

export type AudioParticleProps = GetParticlePropsType<typeof AudioParticleSpec>;
