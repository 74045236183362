<script context="module" lang="ts">export { specification } from "./spec";
</script>

<script lang="ts">import "lodash";
import { DateTime } from "luxon";
import "components/cards/utilities";
import { BaseAtom } from "components/cards/atoms";
import { TextParticle, ImageParticle, ButtonParticle, } from "components/cards/particles/YinzCam";
import { writable } from "svelte/store";
import "js/url";
const hovering = writable(false);
const formatDateTime = (dateString) => {
    var _a;
    const dt = DateTime.fromISO(dateString);
    const convertedDateTime = (_a = dt
        .toLocaleString(Object.assign(Object.assign(Object.assign({}, DateTime.DATE_FULL), { weekday: "short" }), DateTime.TIME_24_SIMPLE))) === null || _a === void 0 ? void 0 : _a.split(" ");
    console.log({ convertedDateTime });
    return convertedDateTime === null || convertedDateTime === void 0 ? void 0 : convertedDateTime.map((string) => string.trim().replace(",", ""));
};
export let container;
export let overlayPadding;
export let backgroundColor;
export let imgLeaguelogo;
export let matchDateTime;
export let txtMatchDate;
export let txtMatchTime;
export let txtStadiumName;
export let imgBroadcastPartner;
export let txtHomeTeam;
export let txtAwayTeam;
export let imgHomeTeam;
export let imgAwayTeam;
export let btnCTA;
export let matchStatus;
export let homeTeamScore;
export let awayTeamScore;
export let matchMinutes;
export let isChannelImageRequired;
export let isPenalty;
export let txtLive;
export let txtLiveSmall;
export let colorLiveIconBackground;
export let colorLiveIconBorder;
export let txtPenalties;
export let txtAwayTeamAbbr;
export let txtHomeTeamAbbr;
export let colorFirstButton;
export let colorLiveIconCircle;
let atomWidthRem;
$: isMobile = atomWidthRem <= 40;
$: isTablet = atomWidthRem <= 64 && atomWidthRem > 40;
$: leagueIconMultiplier = isMobile ? "0.71" : "1.00";
$: dateTextMultiplier = isMobile ? "0.66" : "1.00";
$: timeTextMultiplier = isMobile ? "0.56" : "1.00";
$: timeZoneTextMultiplier = isMobile ? "0.34" : "0.44";
$: stadiumNameTextMultiplier = isMobile ? "0.75" : "1.00";
$: teamTextMultiplier = isMobile ? "0.66" : "1.00";
$: teamIconMultiplier = isMobile ? "0.73" : isTablet ? "1.18" : "1.00";
$: btnTextMultiplier = isMobile ? "0.70" : "1.00";
$: firstButtonProps = (btnCTA === null || btnCTA === void 0 ? void 0 : btnCTA.length) && btnCTA[0];
$: remainingButtons = (btnCTA === null || btnCTA === void 0 ? void 0 : btnCTA.length) && (btnCTA === null || btnCTA === void 0 ? void 0 : btnCTA.slice(1));
$: scoreTextMultiplier = isMobile ? "0.58" : "1.00";
$: matchMinsTextMultiplier = isMobile ? "0.56" : "1.00";
$: themeModeValue = matchStatus === "live" ? "inverted" : "primary";
$: txtPenaltiesMultiplier = isMobile ? "0.5" : "1.00";
$: ctaSizeMultiplier = isMobile ? "0.6" : "1.00";
$: headerText =
    matchStatus === "live"
        ? "LIVE"
        : matchStatus === "post"
            ? "RESULT"
            : "NEXT UP";
$: [day, month, date, year, , time] =
    formatDateTime(matchDateTime);
</script>

<BaseAtom
    _isotope="FixturesStackedAtom"
    {...container}
    _cssVariablePrefix="fix-stack"
    {overlayPadding}
    {backgroundColor}
    {colorLiveIconBackground}
    {colorLiveIconBorder}
    {colorFirstButton}
    {colorLiveIconCircle}
    bind:_clientWidthRem={atomWidthRem}
>
    <div
        class="main-container"
        class:layout-mobile-width={isMobile}
        class:layout-tablet-width={isTablet}
        class:live-match={matchStatus === "live"}
    >
        <div class="header-text">
            <TextParticle {...txtLive} text={headerText} />
        </div>
        <div class="league-icon">
            <ImageParticle {...imgLeaguelogo} />
        </div>
        <div class="match-date">
            <TextParticle
                {...txtMatchDate}
                text={`${day} ${month.slice(0, 3)} ${date}, ${year}`}
            />
        </div>
        <div class="match-details">
            <ImageParticle {...imgHomeTeam} />
            <div
                class="center-text"
                class:apply-shade={["pre", "post"].includes(matchStatus)}
            >
                {#if matchStatus === "pre"}
                    <div class="pre-status-content">
                        <TextParticle {...txtMatchTime} text={time}/>
                    </div>
                {/if}
                {#if matchStatus === "post"}
                    <div class="post-status-content" class:mobile={isMobile}>
                        <div class="home-goals">
                            <TextParticle {...homeTeamScore} />
                        </div>
                        <span>-</span>
                        <div class="away-goals">
                            <TextParticle {...awayTeamScore} />
                        </div>
                    </div>
                {/if}
                {#if matchStatus === "live"}
                    <div class="live-status-content">
                        <div class="home-goals">
                            <TextParticle {...homeTeamScore} />
                        </div>
                        <div class="live-minutes">
                            <div class="minutes">
                                <TextParticle {...matchMinutes} />
                            </div>
                            <div class="live-text">
                                <TextParticle {...txtLiveSmall} text={"LIVE"} />
                            </div>
                        </div>
                        <div class="away-goals">
                            <TextParticle {...awayTeamScore} />
                        </div>
                    </div>
                {/if}
            </div>
            <ImageParticle {...imgAwayTeam} />
        </div>
        <div class="stadium-name">
            <TextParticle {...txtStadiumName} />
        </div>
        <div class="cta-buttons">
            {#each btnCTA || [] as button}
                <div class="button-alone">
                    <ButtonParticle {...button} />
                </div>
            {/each}
        </div>
    </div>
</BaseAtom>

<style>
    .main-container {
        width: 100%;
        padding: var(--fix-stacked-overlay-padding);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
    }
    .header-text {
        width: 100%;
        background-color: var(
            --fix-stack-color-live-icon-background,
            var(--theme-mode-background-color, #164194)
        );
        text-align: center;
        padding: 0.5rem;
    }
    .match-details {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .match-details .center-text {
            width: 10rem;
        }
    .match-details .center-text.apply-shade {
            background-color: var(--fix-stack-background-color, #d0d0d0);
            border-radius: 0.25rem;
        }
    .cta-buttons {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .cta-buttons > * {
        flex-grow: 1;
    }
    .live-status-content {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }
    .live-status-content .live-minutes {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    .post-status-content {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0.75rem 1.25rem 0.25rem 1.25rem;
    }
    .post-status-content span {
            font-size: 2.25rem;
            font-weight: 800;
            color: #164194;
        }
    .post-status-content.mobile span {
            font-size: 1.5rem;
        }
    .pre-status-content{
        padding: 0.75rem 1.25rem 0.25rem 1.25rem;
        text-align: center;
    }</style>
