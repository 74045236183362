import { injectable } from 'inversify';
import { injectToken, Token } from 'inversify-token';
import { YinzCamAPI, YinzCamAPIRequest, YinzCamAPIRequestComponent, YinzCamAPIResponse, YinzCamAPIToken, YinzCamServer } from 'yinzcam-api';
import { YinzCamDropsServiceChatLogItem } from './YinzCamDropsServiceTypes';
export const YinzCamDropsServerToken = new Token<YinzCamDropsServer>(Symbol.for("YinzCamDropsServer"));

@injectable()
export class YinzCamDropsServer {
  private readonly server: YinzCamServer;
  
  public constructor(@injectToken(YinzCamAPIToken) private readonly api: YinzCamAPI) {
    this.server = api.getServer('app', '');
  }

  public getRequest(request: YinzCamAPIRequest) {
    return this.server.getRequest(request);
  }

  public async postDropMessage(dropUuid: string, item:  Partial<YinzCamDropsServiceChatLogItem>) {
    return await this.server.singleRequest({
      path: `/drops/${dropUuid}/messages`,
      method: 'POST',
      data: { 'ChatLogItem': item },
      requiredHeaders: [ 'X-YinzCam-Ticket' ]
    });
  }

  public async getDropTicket(data: any) {
    let ticket = await this.server.singleRequest({
      path: `/ticket`,
      method: 'POST',
      data
    });
    return ticket;
  }

  public async getChatRooms() {
    return await this.server.singleRequest({
      path: `/chat`,
      method: 'GET',
      requiredHeaders: [ 'X-YinzCam-Ticket' ]
    }).then(res => {
      return res.data;
    });
  }

  public async saveProfile(data: any) {
    return await this.server.singleRequest({
      path: `/chat/user`,
      method: 'POST',
      data,
      requiredHeaders: [ 'X-YinzCam-Ticket' ]
    }).then (res => res.data);
  }

  public async getProfile() {
    return await this.server.singleRequest({
      path: `/chat/user`,
      method: 'GET',
      requiredHeaders: ['X-YinzCam-Ticket']
    }).then(res => res.data);
  }

  public async postNewDrop(data:  any) {
    return await this.server.singleRequest({
      path: `/chat`,
      method: 'POST',
      data,
      requiredHeaders: [ 'X-YinzCam-Ticket' ]
    });
  }

  public async getQuestions() {
    return await this.server.singleRequest({
      path: `/picks`,
      method: 'GET',
      requiredHeaders: [ 'X-YinzCam-Ticket' ]
    });
  }

  public getGroupResponses(seasonId: string, roundId: string, yinzIds: string[]): Promise<YinzCamAPIResponse> {
    return this.server.singleRequest({ 
      path: `picks/seasons/${seasonId}/rounds/${roundId}/responses`,
      params: {
        yinzIds: yinzIds.join()
      },
      requiredHeaders: [ 'X-YinzCam-Ticket' ]
    });
  }

  public async getStats(source: string, scope: { type: string, id: string }, entities: { type: string, id: string }[], categories: string[]): Promise<YinzCamAPIResponse> {
    return this.server.singleRequest({ 
      path: `stats/${source}/${scope.type}/${scope.id}`,
      params: {
        entities: entities.map((e) => `${e.type}:${e.id}`).join(),
        categories: categories.join()
      }
    });
  }

  public async postAnswer(seasonId, roundId, questionId, answer:  string) {
    return await this.server.singleRequest({
      path: `/picks/seasons/${seasonId}/rounds/${roundId}/questions/${questionId}/responses`,
      method: 'POST',
      data: {
        Response: answer
      },
      requiredHeaders: [ 'X-YinzCam-Ticket' ]
    });
  }

  public async getChatRoomUsers(roomId: string): Promise<YinzCamAPIResponse> {
    return this.server.singleRequest({ 
      path: `/chat/${roomId}/members`,
      method: 'GET',
      requiredHeaders: [ 'X-YinzCam-Ticket' ]
    });
  }

  public async getChatRoomData(roomId: string): Promise<YinzCamAPIResponse> {
    return this.server.singleRequest({ 
      path: `/chat/${roomId}`,
      method: 'GET',
      requiredHeaders: [ 'X-YinzCam-Ticket' ]
    });
  }

  // public async postEditDropMembers(data:  any, roomId: string) {
  //   return await this.server.singleRequest({
  //     path: `/drops/${dropUuid}/signups`,
  //     method: 'POST',
  //     data,
  //     requiredHeaders: [ 'X-YinzCam-Ticket' ]
  //   });
  // }

  public async postDropSignup(dropUuid: string, email: string) {
    return await this.server.singleRequest({
      path: `/drops/${dropUuid}/signups`,
      method: 'POST',
      data: { email }
    });
  }

  public async getCurrentUserStatus(): Promise<YinzCamAPIResponse> {
    let data = await this.server.singleRequest({ 
      path: `/chat`,
      method: 'GET',
      requiredHeaders: [ 'X-YinzCam-Ticket' ]
    });
    return data;
  }

  public async putEditChatRoom(data:  any, roomId: string) {
    return await this.server.singleRequest({
      path: `/chat/${roomId}`,
      method: 'PUT',
      data,
      requiredHeaders: [ 'X-YinzCam-Ticket' ]
    });
  }

  public async deleteChatRoom(roomId: string) {
    return await this.server.singleRequest({
      path: `/chat/${roomId}`,
      method: 'DELETE',
      requiredHeaders: [ 'X-YinzCam-Ticket' ]
    });
  }
  
}
