<script context="module" lang="ts">var _a;
export { specification } from "./spec";
</script>

<script lang="ts">var _a;
import "lodash";
import { BaseAtom, getContentManager } from "components/cards/atoms";
import { ButtonParticle, ShareStripParticle, TextParticle } from "components/cards/particles/YinzCam";
import AuthorParticle from "components/cards/particles/YinzCam/Media/Author/AuthorParticle.svelte";
import "js/i18n";
export let container = undefined;
export let slug = undefined;
export let link = undefined;
export let titleText = undefined;
export let categories;
export let promptText = undefined;
export let author = undefined;
export let socialLinks = undefined;
$: mtc = getContentManager().getMediaTypeConfigurationStore('article', slug, link);
$: shareLink = (_a = $mtc === null || $mtc === void 0 ? void 0 : $mtc.shareLinkUrl) === null || _a === void 0 ? void 0 : _a.href;
</script>

<style>
  .article-footer-atom {
    display: flex;
    flex-direction: column;
    justify-content: left;
    width: 100%;
    height: 100%;
    gap: 3rem;
  }

  .article-footer-atom > .article-footer-atom-title {
    flex: 1 0 auto;
    width: 100%;
  }

  .article-footer-atom > .article-footer-atom-bottom-row {
    flex: 1 0 auto;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 0.5rem;
  }

  .article-footer-atom-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    align-self: stretch;
  }

  .article-footer-atom-divider {
    flex: 0 0 0.0625rem;
    height: 0.0625rem;;
    background-color: black;
    display: none;
  }

  .article-footer-atom-top-right {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }

  .article-footer-atom-top-left{
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
  }</style>

<BaseAtom _isotope="ArticleFooterAtom" {...container}>
  <div class="article-footer-atom">
    <div class="article-footer-atom-bottom-row">
      <TextParticle _block={false} {...promptText} text={'Author'} />
      <div class="article-footer-atom-author">
        <AuthorParticle {...author} />
      </div>
    </div>
    <div class="article-footer-atom-divider"></div>
    <div class="article-footer-atom-top">
      <div class="article-footer-atom-top-left">
        <TextParticle _block={false} {...promptText} />
        <ShareStripParticle {...socialLinks} shareText={(titleText && titleText.text) || ""} shareLink={shareLink || '/'} />
      </div>
      <div class="article-footer-atom-top-right">
      {#each (categories || []) as category}
        <ButtonParticle {...category} />
      {/each}
      </div>
    </div>
  </div>
</BaseAtom>
